/* eslint-disable */
// Remove above line when API is ready
import httpClient from './httpClient'
// import httpClient from './httpClientDebug'

const END_POINT = '/customerresources'
// const END_POINT = ''

const BankInfoAPI = {
  getBankInfo(accountId) {
    return httpClient.get(`${END_POINT}/${accountId}/billing/ach`)
  },

  getUnmaskedBankInfo(accountId) {
    return httpClient.get(`${END_POINT}/${accountId}/billing/ach/unmasked`)
  },

  saveBankInfo(accountId, bankInfo) {
    return httpClient.post(`${END_POINT}/${accountId}/billing/ach`, bankInfo)
  },

  updateBankInfo(accountId, bankInfo) {
    return httpClient.put(`${END_POINT}/${accountId}/billing/ach`, bankInfo)
  }
}

export default BankInfoAPI