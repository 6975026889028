<template>
  <b-card
    class="card-stats"
    body-class="card-stats__body"
  >
    <div class="row no-gutters align-items-center">
      <div class="col-auto pr-3 pl-1 mb-2">
        <font-awesome-icon
          :icon="icon"
          size="lg"
          class="card-stats__icon"
          :style="customIconStyle"
        />
      </div>
              
      <div class="col">
        <h6 class="card-title text-uppercase text-muted mb-1">
          {{ statsTitle }}
        </h6>
        <span class="h5 font-weight-bold mb-0">
          {{ isStatsNumberCurrency ? formatCurrency(statsNumber) : formatNumber(statsNumber) }}
        </span>
      </div>
    </div>
  </b-card>
</template>
<script>
import formatCurrency from '@/utils/formatCurrency'
import formatNumber from '@/utils/formatNumber'

export default {
  name: 'ReportingCardStats',

  props: {
    statsTitle: {
      type: String,
      default: ''
    },
    
    statsNumber: {
      type: [String, Number],
      default: 0
    },

    isStatsNumberCurrency: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Array,
      default() {
        return []
      }
    },

    iconColor: {
      type: String,
      default: ''
    }
  },

  computed: {
    customIconStyle() {
      return this.iconColor ? { color: this.iconColor } : {} 
    }
  },

  methods: {
    formatCurrency,

    formatNumber,
  },
}
</script>
<style lang="scss" scoped>
.card-stats {
  border: none;
  background-color: var(--component-panel-bg);
}

.card-stats__body {
  padding: 0.8rem;
}

.card-stats__icon {
  font-size: 2rem;
}
</style>