<template>
  <div class="d-flex flex-column mr-3">
    <b-table
      :fields="fields"
      :items="items"
      thead-class="text-nowrap"
      tbody-tr-class="text-nowrap"
    >
      <template #cell(createdDate)="row">
        {{ `${dateFormat(row.item.creationDate, 'MMM DD hh:mm:ss A')}` }}
      </template>

      <template #cell(acceptedDate)="row">
        {{ `${row.item.acceptedDate ? dateFormat(row.item.acceptedDate, 'MMM DD hh:mm:ss A') : 'N/A'}` }}
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-button 
            v-if="userId != row.item.id"
            variant="info"
            size="sm"
            @click="showConfirmRemoveUser(row.item)"
          >
            Remove
          </b-button>
        </div>
      </template>

      <template #cell(status)="row">
        <b-badge
          tag="div"
          class="w-100 py-1"
          :variant="getBadgeVariant(row.item.status)"
        >
          {{ startCase(row.item.status) }}
        </b-badge>
      </template>
    </b-table>

    <b-modal
      id="confirm-remove-user"
      ref="confirm-remove-user"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Remove"
      button-size="sm"
    >
      <p class="mb-2">
        Are you sure you want to remove user <strong>{{ selectedUser.email }}</strong>?
      </p>

      <p class="mb-0">
        <span class="text-muted">The user <strong>{{ selectedUser.email }}</strong> will no longer have access to your account, including data and settings associated with this account.</span>
      </p>

      <template #modal-footer>        
        <ButtonLoader
          type="button"
          variant="danger"
          size="sm"
          :loading="isLoading"
          @click="onRemoveUser"
        >
          Remove
        </ButtonLoader>
        
        <b-button
          type="button"
          variant="secondary"
          size="sm"          
          @click="$bvModal.hide('confirm-remove-user')"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import dateFormatMixin from '@/mixins/dateFormatMixin'

export default {
  name: 'AccountUsers',
  components: {},
  mixins: [dateFormatMixin],

  props: {
    items: {
      type: Array,
      default: () => {}
    }
  },

  data() {
    return {
      isLoading: false,
      selectedUser: {},
      fields: [
        { key: 'createdDate', label: 'Date Added', thClass: 'fixed-width-130' },
        { key: 'email', label: 'Email' },
        { key: 'firstName', label: 'FirstName' },
        { key: 'lastName', label: 'LastName' },
        { key: 'phoneNumber', label: 'Phone' },
        { key: 'skype', label: 'Skype' },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['userId']),
  },

  methods: {

    ...mapActions('account', [
      'removeAccountUser'
    ]),

    getBadgeVariant(status) {
      const statusToVariantMap = {
        Pending: 'secondary',
        Accepted: 'success',
        Deleted: 'danger'
      }
      return statusToVariantMap[status]
    },

    async showConfirmRemoveUser(user){
      this.selectedUser = user
      this.$bvModal.show('confirm-remove-user')
    },

    async onRemoveUser() {
      this.isLoading = true
      this.$bvModal.hide('confirm-remove-user')

      try {
        await this.removeAccountUser(this.selectedUser.id)
        showSuccessMessage(`Successfully removed the user ${this.selectedUser.email} from this account`)
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.isLoading = false
        this.selectedUser = {}
      }
    },

  }
}
</script>

<style lang="scss" scoped></style>
