import TestAPI from '@/api/test.api.js'

const state = {
  campaigns: [],
  categories: [],
  countries: [],
  trafficSources: [],
  buyerDashboardGraphData: []
}

const getters = {}

const actions = {
  async createCampaign(context, { details }) {
    const response = await TestAPI.createBuyerCampaign({
      details
    })
    return response.data
  },
  async fetchCampaigns({ commit }, { accountId }) {
    const response = await TestAPI.listBuyerCampaigns({
      accountId
    })
    commit('SET_CAMPAIGNS', response.data)
    return response.data
  },
  async getCountries({ commit }) {
    await Promise.resolve()
    commit('SET_COUNTRIES', countries)
  },
  async getCategories({ commit }) {
    await Promise.resolve()
    commit('SET_CATEGORIES', categories)
  },
  async getTrafficSources({ commit }) {
    await Promise.resolve()
    commit('SET_TRAFFIC_SOURCES', trafficSources)
  },
  async getGraphData({ commit }, { startTimestamp, endTimestamp }) {
    const graphData = getBuyerDashboardGraphData({
      from: startTimestamp,
      to: endTimestamp
    })
    commit('SET_BUYER_DASHBOARD_GRAPH_DATA', graphData)
    await Promise.resolve()
  }
}

const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_TRAFFIC_SOURCES(state, trafficSources) {
    state.trafficSources = trafficSources
  },
  SET_BUYER_DASHBOARD_GRAPH_DATA(state, graphData) {
    state.buyerDashboardGraphData = graphData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const countries = [
  {
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'IN',
    name: 'India',
    subId: 'india'
  },
  {
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'US',
    name: 'United States',
    subId: 'united-states'
  },
  {
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'CA',
    name: 'Canada',
    subId: 'canada'
  },
  {
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'UK',
    name: 'United Kingdom',
    subId: 'united-kingdom'
  },
  {
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'TN',
    name: 'Tunisia',
    subId: 'tunisia'
  }
]

const trafficSources = [
  { name: 'Chatbots', type: 'inbound' },
  { name: 'Digital Classifieds', type: 'inbound' },
  { name: 'Digital Radio', type: 'inbound' },
  { name: 'Digital Video', type: 'inbound' },
  { name: 'Direct Mail', type: 'inbound' },
  { name: 'Directory', type: 'inbound' },
  { name: 'Display', type: 'inbound' },
  { name: 'Email', type: 'inbound' },
  { name: 'Google My Business', type: 'inbound' },
  { name: 'Misdials', type: 'inbound' },
  { name: 'Native', type: 'inbound' },
  { name: 'Offline / Out of Home', type: 'inbound' },
  { name: 'Organic (SEO)', type: 'inbound' },
  { name: 'Paid Search', type: 'inbound' },
  { name: 'Paid Search - Call Only', type: 'inbound' },
  { name: 'Print', type: 'inbound' },
  { name: 'Push Notifications', type: 'inbound' },
  { name: 'Radio', type: 'inbound' },
  { name: 'Social', type: 'inbound' },
  { name: 'Television', type: 'inbound' },
  { name: 'Warm Transfer - Offshore - Inbound', type: 'inbound' },
  { name: 'Warm Transfer - Onshore - Inbound', type: 'inbound' },

  { name: 'Misdials', type: 'outbound' },
  { name: 'Ringless Voicemail', type: 'outbound' },
  { name: 'SMS', type: 'outbound' },
  { name: 'Voice Broadcasting', type: 'outbound' },
  { name: 'Warm Transfer - Offshore - Outbound', type: 'outbound' },
  { name: 'Warm Transfer - Onshore - Outbound', type: 'outbound' }
]

const categories = [
  {
    parentCategory: null,
    tags: null,
    createdBy: null,
    languages: null,
    sources: null,
    allowThirdParty: false,
    countries: null,
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'CAT5d9dc8744db54ec5a7115ffba62bbc7a',
    name: 'Digital Marketing',
    subId: 'digital-marketing'
  },
  {
    parentCategory: null,
    tags: null,
    createdBy: null,
    languages: null,
    sources: null,
    allowThirdParty: false,
    countries: null,
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'CAT7e601106d84d401bb8fb76f192575007',
    name: 'Auto Sales',
    subId: 'auto-sales'
  },
  {
    parentCategory: null,
    tags: null,
    createdBy: null,
    languages: null,
    sources: null,
    allowThirdParty: false,
    countries: null,
    statusCode: 0,
    status: 'Active',
    liveDate: '0001-01-01T00:00:00+00:00',
    creationDate: '0001-01-01T00:00:00+00:00',
    expirationDate: null,
    pausedDate: null,
    isExpired: false,
    isPaused: false,
    description: null,
    id: 'CAT3fc4697f40e34a8883e90384913b498f',
    name: 'Auto Insurancee',
    subId: 'auto-insurancee'
  }
]

function getBuyerDashboardGraphData({ from, to }) {
  const startTimeEpoch = from.valueOf()
  const endTimeEpoch = to.valueOf()
  const numberOfCampaigns = 5
  const campaignNames = [
    'Auto Sales Inbound',
    'Auto Sales Outbound',
    'Auto Insurance Inbound',
    'Auto Insurance Outbound',
    'Legal'
  ]
  const dataPoints = 10
  const campaigns = []
  for (let i = 0; i < numberOfCampaigns; i++) {
    const campaignData = generateCampaignData({
      startTimeEpoch,
      endTimeEpoch,
      dataPoints
    })
    campaigns.push({
      name: campaignNames.pop(),
      data: campaignData
    })
  }
  return campaigns
}

function generateCampaignData({ startTimeEpoch, endTimeEpoch, dataPoints }) {
  const campaignData = []
  for (let i = 0; i < dataPoints; i++) {
    const entry = {
      timestamp: new Date(randomInRange(startTimeEpoch, endTimeEpoch)),
      value: randomInRange(0, 1000)
    }
    campaignData.push(entry)
  }
  return campaignData.sort((a, b) => {
    return a.timestamp < b.timestamp ? -1 : 1
  })
}

function randomInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
