import httpClient from './httpClient'

const END_POINT = '/esign'

const ESignAPI = {
  createOnboardingDocsForSeller(accountId, successRedirectURL) {
    return httpClient.post(`${END_POINT}/${accountId}/seller/onboarding`, {
      successRedirectURL
    })
  },

  getPendingDocumentBundleStatusForSeller(accountId) {
    return httpClient.get(`${END_POINT}/${accountId}/seller/pending-bundle-status`)
  },

  createOnboardingDocsForBuyer(accountId, successRedirectURL) {
    return httpClient.post(`${END_POINT}/${accountId}/buyer/onboarding`, {
      successRedirectURL
    })
  },

  getPendingDocumentBundleStatusForBuyer(accountId) {
    return httpClient.get(`${END_POINT}/${accountId}/buyer/pending-bundle-status`)
  }
}

export default ESignAPI