<template>
  <div>
    <BaseFormSelect
      id="buyer-create-campaign__destination__timezone"
      v-model="timeZoneId"
      :horizontal="true"
      internal-platform-input
      :small="true"
      data-cy="select-timezone"
      name="Time Zone"
      label="Time Zone"
      text-field="display"
      value-field="id"
      :options="timeZonesList"
      required
      width="6"
      width-lg="3"
      has-tooltip
    />

    <BaseFormToggle
      id="buyer-create-campaign__destination__hours-of-operation"
      v-model="useHoursOfOperation"
      internal-platform-input
      label="Hours of Operation"
      data-cy="toggle-hours-of-operation"
      :checked-value="true"
      :unchecked-value="false"
      has-tooltip
    >
      <template
        v-if="useHoursOfOperation"
        v-slot:extra
      >
        <b-button
          variant="success"
          size="sm"
          class="custom-button--flat ml-2 hours-advanced-button"
          @click="onViewChangeForHoursOfOperation"
        >
          {{ !showAdvancedConfig ? 'Show Advanced' : 'Show Basic' }}
        </b-button>
      </template>
    </BaseFormToggle>

    <b-form-row v-if="useHoursOfOperation">
      <b-col
        offset-sm="3"
        sm="8"
        xl="5"
      >
        <TargetHoursOfOperation
          ref="hoursOfOperation"
          :hours-of-operation="targetSchedule.hoursOfOperation"
          @change-view="changeHoursOfOperationView"
          @update="updateHoursOfOperation"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { getDefaultHoursOfOperation } from '@/components/buyer/Target/hoursOfOperationsUtils'
import TargetHoursOfOperation from './TargetHoursOfOperation.vue'

export default {
  name: 'TargetScheduling',

  components: {
    TargetHoursOfOperation,
  },

  props: {
    targetSchedule: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      showAdvancedConfig: false
    }
  },

  computed: {
    ...mapState('common-entities', [
      'timeZones'
    ]),

    ...mapGetters('auth', [
      'accountTimeZoneId'
    ]),

    timeZonesList() {
      const timeZonesList = [...this.timeZones]
      timeZonesList.unshift({ display: 'Please select', value: null })
      return timeZonesList
    },

    useHoursOfOperation: {
      get() {
        return this.targetSchedule.hoursOfOperation != null
      },
      set(value) {
        if (value && this.targetSchedule.hoursOfOperation == null) {
          this.updateHoursOfOperation(getDefaultHoursOfOperation())
        } else if (!value) {
          this.$emit('update', {
            ...this.targetSchedule,
            hoursOfOperation: null,
            timeZoneId: null
          })
        }
        
      }
    },

    timeZoneId: {
      get() {
        return this.targetSchedule.timeZoneId
      },
      set(value) {
        this.$emit('update', {
          ...this.targetSchedule,
          timeZoneId: value
        })
      }
    },
  },


  watch: {
    targetSchedule: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue.timeZoneId && !oldValue?.timeZoneId) {
          this.timeZoneId = this.accountTimeZoneId
        }
      }
    }
  },

  methods: {
    changeHoursOfOperationView(basic) {
      this.showAdvancedConfig = !basic
    },

    onViewChangeForHoursOfOperation() {
      this.showAdvancedConfig = !this.showAdvancedConfig
      this.$refs['hoursOfOperation'].changeView(this.showAdvancedConfig)
    },

    updateHoursOfOperation(hoursOfOperation) {
      this.$emit('update', {
        ...this.targetSchedule,
        hoursOfOperation
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.hours-advanced-button {
  width: 200px;
}
</style>