<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
      tag="div"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(completeMFASetup)"
      >
        <canvas
          id="canvas"
          class="my-2"
        />

        <div class="mb-2">
          <div
            class="custom-form-input-label mr-2"
          >
            Secret Key
          </div>
          <div>{{ MFASecretKey }}</div>
        </div>

        <BaseFormInput
          id="software-token"
          v-model="details.softwareToken"
          name="Software Token"
          placeholder="Enter the 6 digit MFA code"
          class="mt-4 d-block"
          label="Code"
          type="text"
          required
          width="7"
          width-lg="5"
          autocomplete="off"
        >
          <template v-slot:helpText>
            <b-form-text>
              <div>
                Enter the code from the authenticator app and click 'Verify Code' button to complete the MFA setup.
              </div>
            </b-form-text>
          </template>
        </BaseFormInput>

        <BaseButtonLoader
          id="button-complete-mfa"
          data-cy="button-complete-mfa"
          :loading="verifyButtonLoading"
          button-class="custom-button--white-hover-grad pulse mt-2"
          type="submit"
        >
          Verify Code
        </BaseButtonLoader>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import QRCode from 'qrcode'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

function qrCodeToCanvas(canvas, value) {
  return new Promise((resolve, reject) => {
    try {
      QRCode.toCanvas(canvas, value)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}


export default {
  name: 'SoftwareMFA',

  title: 'Setup MFA',

  data() {
    return {
      loading: false,
      verifyButtonLoading: false,
      details: {
        softwareToken: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['MFASecretKey', 'authenticatedUserDetails'])
  },
  async created() {
    this.resetMFAKey()

    await this.initiateMFASetup()
  },
  methods: {
    ...mapActions('auth', [
      'associateSoftwareMFA',
      'verifySoftwareMFA',
      'resetMFAKey',
      'getLoggedInUserDetails'
    ]),

    async initiateMFASetup() {
      try {
        this.loading = true
        await this.associateSoftwareMFA()
        const canvas = document.getElementById('canvas')
        const email = this.authenticatedUserDetails.email
        const value = `otpauth://totp/RingbaX:${email}?secret=${this.MFASecretKey}&issuer=RingbaX&algorithm=SHA1`
        return qrCodeToCanvas(canvas, value)
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loading = false
      }
    },

    async completeMFASetup() {
      try {
        this.verifyButtonLoading = true
        const softwareToken = this.details.softwareToken
        await this.verifySoftwareMFA(softwareToken)
        await this.getLoggedInUserDetails()
        showSuccessMessage('Software MFA Setup Completed')

        this.$emit('success')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.verifyButtonLoading = false
      }
    }
  }
}
</script>
<style lang="">
  
</style>