<template>
  <div
    class="d-flex flex-column justify-content-center flex-grow-1 py-5 onboarding"
  >
    <div class="py-2">
      <b-container
        class="registration"
      >
        <h4 class="mb-3">
          Setup Multi-Factor Authentication
        </h4>

        <p>
          Steps to setup MFA:
          <ol>
            <li>Download authenticator app like Google Authenticator, Authy or Microsoft Authenticator on your mobile phone.</li>
            <li>Scan the QR code or enter the secret key in the authenticator app to save it.</li>
            <li>Enter the 6 digit code provided by the app below and click 'Verify Code' to complete the setup.</li>
          </ol>
        </p>

        <SoftwareMFA @success="onSuccess" />
      </b-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import SoftwareMFA from '@/components/account/SoftwareMFA.vue'

export default {
  name: 'OnboardingSoftwareMFA',

  components: {
    SoftwareMFA,
  },

  computed: {
    ...mapGetters('auth', [
      'accountOnboardingStatus'
    ]),
  },

  methods: {
    onSuccess() {
      this.$router.push({
        name: 'Dashboard'
      })
    }
  },
}
</script>
<style lang="">
  
</style>