<template>
  <div>
    <TheHeader
      show-logout
      hide-summary
      hide-hamburger
    />
    <slot />
  </div>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue'

export default {
  name: 'LayoutOnboarding',

  components: {
    TheHeader,
  },
  data() {
    return {
      onboarding: true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
