import httpClient from './httpClient'

const END_POINT = '/customerresources/user-account/seller'

const SellerAccountAPI = {

  /**
   * Get seller payouts
   * @param {string} accountId 
   * @returns void
   */
  async getSellerPayouts(accountId) {
    return await httpClient.get(`${END_POINT}/${accountId}/payouts`)
  },
}

export default SellerAccountAPI