<template>
  <b-dropdown 
    variant="outline-info"
    size="sm"
  >
    <template #button-content>
      <font-awesome-icon
        class="mr-1"
        :icon="['fas', 'info-circle']"
        :style="{ cursor: 'pointer' }"
      /> 
      View
    </template>

    <b-dropdown-item-button 
      variant="info"
      @click="$emit('showApplicationRequestDetails', offerRegistration)"
    >
      <font-awesome-icon
        class="mr-1"
        :icon="['fas', 'wrench']"
        :style="{ cursor: 'pointer' }"
      /> Offer Details
    </b-dropdown-item-button>

    <b-dropdown-divider v-if="showBiddingUrlOption" />

    <b-dropdown-item-button
      v-if="showBiddingUrlOption"
      variant="info"
      @click="$emit('showBiddingUrl', offerRegistration)"
    >
      <font-awesome-icon
        class="mr-1"
        :icon="['fas', 'wrench']"
        :style="{ cursor: 'pointer' }"
      /> Bidding URL
    </b-dropdown-item-button>

    <b-dropdown-divider />

    <b-dropdown-item-button 
      v-if="offerRegistration.status === 'Pending' || offerRegistration.status === 'Approved'"
      variant="info"
      @click="$emit('showWithdrawModal', offerRegistration)"
    >
      <font-awesome-icon
        class="mr-1"
        :icon="['fas', 'pause']"
        :style="{ cursor: 'pointer' }"
      />
      Withdraw
    </b-dropdown-item-button>
              
    <b-dropdown-item-button 
      v-if="offerRegistration.status === 'Rejected'"
      variant="info"
      @click="$emit('showRejectionModal', offerRegistration)"
    >
      <font-awesome-icon
        class="mr-1"
        :icon="['fas', 'ban']"
        :style="{ cursor: 'pointer' }"
      />
      Rejection Reason
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  name: 'SellerAppliedOfferActions',

  props: {
    offerRegistration: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  computed: {
    showBiddingUrlOption() {
      return this.offerRegistration.status !== 'Pending' 
      && this.offerRegistration.status !== 'Withdrawn' 
      && this.offerRegistration.status !== 'Rejected'
    }
  },
}
</script>
<style lang="">
  
</style>