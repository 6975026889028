<template>
  <div>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th>Days</b-th>
          <b-th v-if="showAdvancedConfig">
            Open
          </b-th>
          <b-th>Open</b-th>
          <b-th>Close</b-th>
          <b-th class="text-center">
            Breaks
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <!-- Basic Hours Setup - START -->
        <b-tr v-if="!showAdvancedConfig">
          <b-td>
            Monday - Sunday
          </b-td>

          <b-td :class="{'show-time-warning pl-4': isOpenTimeAfterCloseTimeForAllDays}">
            <i
              v-b-tooltip.hover.html
              class="text-danger mr-2 time-warning-icon"
              :title="openTimeAfterClosingTimeTooltip"
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
              />
            </i>

            <b-form-timepicker
              v-model="allOpenTime"
              locale="en"
              hide-header
              size="sm"
              no-close-button
            />
          </b-td>

          <b-td :class="{'show-time-warning pl-4': isOpenTimeAfterCloseTimeForAllDays}">
            <i
              v-b-tooltip.hover.html
              class="text-danger mr-2 time-warning-icon"
              :title="openTimeAfterClosingTimeTooltip"
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
              />
            </i>

            <b-form-timepicker
              v-model="allCloseTime"
              locale="en"
              hide-header
              size="sm"
              no-close-button
            />
          </b-td>

          <b-td class="text-center">
            <TargetBreaks
              v-if="breakForAllDays && breakForAllDays.length"
              class="mb-2"
              :breaks="breakForAllDays"
              @remove="removeBreakForAllDays"
            />

            <b-button
              variant="primary"
              size="sm"
              @click="showAllAddBreakForm = true"
            >
              <font-awesome-icon
                :icon="['fas', 'plus']"
              />
              Add New Break
            </b-button>
          </b-td>
        </b-tr>

        <b-tr v-if="showAllAddBreakForm && !showAdvancedConfig">
          <b-td colspan="10">
            <TargetBreakForm
              @cancel="showAllAddBreakForm = false"
              @add-break="updateBreaksForAllDays"
            />
          </b-td>
        </b-tr>
        <!-- Basic Hours Setup - END -->

        <!-- Advanced Hours Setup - START -->
        <template
          v-for="(day, $idx) in daysOfWeek"
        >
          <b-tr
            v-if="showAdvancedConfig"
            :key="day"
          >
            <b-td>
              {{ day }}
            </b-td>

            <b-td>
              <b-form-checkbox
                v-model="hoursOfOperationState[$idx].isClosed"
                switch
                :value="false"
                :unchecked-value="true"
                button-variant="success"
                :class="{ 'checked': hoursOfOperationState[$idx].isClosed }"
                @change="onChangeSettings"
              />
            </b-td>

            <b-td :class="{'show-time-warning pl-4': isOpenAfterClosing(hoursOfOperationState[$idx].openTime, hoursOfOperationState[$idx].closeTime)}">
              <i
                v-b-tooltip.hover.html
                class="text-danger mr-2 time-warning-icon"
                :title="openTimeAfterClosingTimeTooltip"
              >
                <font-awesome-icon
                  :icon="['fas', 'exclamation-triangle']"
                />
              </i>
              <b-form-timepicker
                v-model="hoursOfOperationState[$idx].openTime"
                locale="en"
                size="sm"
                hide-header
                no-close-button
                @input="onChangeSettings"
              />
            </b-td>

            <b-td :class="{'show-time-warning pl-4': isOpenAfterClosing(hoursOfOperationState[$idx].openTime, hoursOfOperationState[$idx].closeTime)}">
              <i
                v-b-tooltip.hover.html
                class="text-danger mr-2 time-warning-icon"
                :title="openTimeAfterClosingTimeTooltip"
              >
                <font-awesome-icon
                  :icon="['fas', 'exclamation-triangle']"
                />
              </i>
              <b-form-timepicker
                v-model="hoursOfOperationState[$idx].closeTime"
                locale="en"
                size="sm"
                hide-header
                no-close-button
                @input="onChangeSettings"
              />
            </b-td>

            <b-td>
              <TargetBreaks
                v-if="hoursOfOperationState[$idx].breaks && hoursOfOperationState[$idx].breaks.length"
                class="mb-2"
                :breaks="hoursOfOperationState[$idx].breaks"
                @remove="removeBreak($idx, ...arguments)"
              />

              <b-button
                variant="primary"
                size="sm"
                @click="showBreakForm($idx)"
              >
                <font-awesome-icon
                  :icon="['fas', 'plus']"
                />
                Add New Break
              </b-button>
            </b-td>
          </b-tr>

          <b-tr
            v-if="showAdvancedConfig && hoursOfOperationState[$idx].showAddBreakForm"
            :key="`${day}-break`"
          >
            <b-td colspan="10">
              <TargetBreakForm
                @cancel="hoursOfOperationState[$idx].showAddBreakForm = false"
                @add-break="updateBreakForDay($idx, ...arguments)"
              />
            </b-td>
          </b-tr>
        </template>
        <!-- Advanced Hours Setup - END -->
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Vue from 'vue'

import TargetBreakForm from './TargetBreakForm.vue'
import TargetBreaks from './TargetBreaks.vue'
import {
  isBasic,
  daysOfWeek,
  transformHoursOfOperationsFromStore,
  getDefaultHoursOfOperation,
  transformHoursOfOperationsFromUI,
  getSettingObjectFromTimeString,
} from './hoursOfOperationsUtils'

export default {
  name: 'TargetHoursOfOperation',

  components: {
    TargetBreakForm,
    TargetBreaks
  },

  props: {
    hoursOfOperation: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      daysOfWeek: Object.freeze(daysOfWeek),

      hoursOfOperationState: [],

      showAllAddBreakForm: false,

      showAdvancedConfig: false,

      openTimeAfterClosingTimeTooltip: 'The open time is entered after the closing time which will push the closing time to the following day.  <br/> E.g., <b>Opening hour: 7PM, Closing hour: 7AM</b> The target will open at 7PM and it will close at 7AM of the next day'
    }
  },

  computed: {
    allOpenTime:{
      get() {
        return this.hoursOfOperationState[0]?.openTime
      },
      set(value) {
        if (!this.showAdvancedConfig) {
          this.hoursOfOperationState = getDefaultHoursOfOperation(value, this.allCloseTime, false, false, this.breakForAllDays)
          this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
        }
      }
    },

    allCloseTime: {
      get() {
        return this.hoursOfOperationState[0]?.closeTime
      },
      set(value) {
        if (!this.showAdvancedConfig) {
          this.hoursOfOperationState = getDefaultHoursOfOperation(this.allOpenTime, value, false, false, this.breakForAllDays)
          this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
        }
      }
    },

    breakForAllDays: {
      get() {
        return this.hoursOfOperationState[0]?.breaks
      },
      set(value) {
        if (!this.showAdvancedConfig) {
          this.hoursOfOperationState = getDefaultHoursOfOperation(this.allOpenTime, this.allCloseTime, false, false, value)
          this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
        }
      }
    },

    isOpenTimeAfterCloseTimeForAllDays() {
      return this.isOpenAfterClosing(this.allOpenTime, this.allCloseTime)
    }
  },

  watch: {
    hoursOfOperation: {
      immediate: true,
      handler(newValue) {
        let isBasicView = isBasic(newValue) && !this.showAdvancedConfig
        this.$emit('change-view', isBasicView)
        this.showAdvancedConfig = !isBasicView
        this.hoursOfOperationState = transformHoursOfOperationsFromStore(newValue)  
      }
    }
  },

  methods: {
    changeView(advancedConfig) {
      this.showAdvancedConfig = advancedConfig

      this.hoursOfOperationState = getDefaultHoursOfOperation(this.allOpenTime, this.allCloseTime, false, false, this.breakForAllDays)

      this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
    },

    updateBreaksForAllDays(opBreak) {
      let allBreaks = [...this.breakForAllDays]
      allBreaks.push(opBreak)

      this.breakForAllDays = allBreaks

      this.showAllAddBreakForm = false

      this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
    },


    updateBreakForDay(idx, opBreak) {
      this.hoursOfOperationState[idx].breaks.push(opBreak)

      this.hoursOfOperationState[idx].showAddBreakForm = false

      this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
    },

    removeBreak(dayIndex, existingBreaks, idx) {
      let breaks = [...existingBreaks]
      breaks.splice(idx, 1)

      this.hoursOfOperationState[dayIndex].breaks = breaks

      this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
    },

    removeBreakForAllDays(existingBreaks, idx) {
      let breaks = [...existingBreaks]
      breaks.splice(idx, 1)

      this.breakForAllDays = breaks
    },

    onChangeSettings() {
      this.$emit('update', transformHoursOfOperationsFromUI(this.hoursOfOperationState))
    },

    isOpenAfterClosing(openTime, closeTime) {
      const openTimeObject = getSettingObjectFromTimeString(openTime)
      const closeTimeObject = getSettingObjectFromTimeString(closeTime)

      let isOpenHourBiggerThanClosingHour = openTimeObject.hour > closeTimeObject.hour
      let isOpenMinBiggerThanClosingMin = (openTimeObject.hour === closeTimeObject.hour) &&  openTimeObject.minute >= closeTimeObject.minute

      return (isOpenHourBiggerThanClosingHour) || (isOpenMinBiggerThanClosingMin)
    },

    showBreakForm(idx) {
      Vue.set(this.hoursOfOperationState[idx], 'showAddBreakForm', true)
    }
  },
}
</script>

<style lang="scss" scoped>
.show-time-warning {
  position: relative;

  .time-warning-icon {
    display: block;
  }
}

.time-warning-icon {
  display: none;
  position: absolute;
  left: 0;
}
</style>