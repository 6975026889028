<template>
  <li class="sidebar__nav-menu-list-item">
    <BaseLink
      v-b-toggle="`id-${menuText}`"
      class="nav-link sidebar__nav-menu-link"
      href="javascript:;"
      :active="activeMenu"
    >
      <font-awesome-icon
        class="fa-fw mr-2 sidebar__nav-menu-icon"
        :icon="menuIcon"
      />

      <span class="sidebar__nav-menu-text">
        {{ menuText }}
      </span>

      <font-awesome-icon
        class="fa-fw sidebar__nav-menu-caret ml-auto"
        :icon="menuCaretIcon"
      />
    </BaseLink>

    <b-collapse
      :id="`id-${menuText}`"
      v-model="open"
      tag="ul"
      class="sidebar__nav-sub-menu list-unstyled"
    >
      <template v-for="(subMenu, $idx) in children">
        <li
          v-if="!subMenu.condition || subMenu.condition()"
          :key="$idx"
        >
          <BaseLink
            class="sidebar__nav-sub-menu-link"
            :to="subMenu.route"
            :data-cy="subMenu['data-cy']"
            exact
          >
            {{ subMenu.subMenuText }}
          </BaseLink>
        </li>
      </template>
    </b-collapse>
  </li>
</template>

<script>
import { mapState } from 'vuex'

function checkMultipleRouteNames(routeName = '', routeNames, splitBy = '|') {
  const routeNamesSplit = routeNames.split(splitBy)

  return routeNamesSplit.some(route => routeName === route)
}

export default {
  name: 'SidebarNavDropdown',

  props: {
    route: {
      type: String,
      default: ''
    },

    menuText: {
      type: String,
      default: ''
    },

    menuIcon: {
      type: Array,
      default() {
        return []
      }
    },

    children: {
      type: Array,
      default() {
        return []
      }
    },

    show: {
      type: Boolean,
      default: false
    }

  },

  data() {
    return {
      open: this.show
    }
  },


  computed: {
    ...mapState(['isSidebarExpanded']),

    menuCaretIcon() {
      return this.isSidebarExpanded ? ['fas', 'angle-down'] : ['fas', 'angle-right']
    },

    activeMenu() {
      return checkMultipleRouteNames(this.$route.name, this.route)
    }
  },

  created () {
    this.open = checkMultipleRouteNames(this.$route.name, this.route)
  },
}
</script>

<style>

</style>