<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :description="$attrs.description"
      :invalid-feedback="validationContext.errors[0]"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        [labelClass]: labelClass,
        'pr-4': true
      }"
      :class="formGroupClass"
      :label-cols-sm="horizontal ? 3 : null"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :content-cols-xl="widthXl || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <div class="d-flex align-items-center h-100">
        <!-- <BaseFormCheckbox
        v-model="state"
        v-bind="$attrs"
        switch
        :unchecked-value="uncheckedValue"
        :checked-value="checkedValue"
      /> -->
        <b-form-checkbox
          v-model="state"
          :value="checkedValue"
          v-bind="$attrs"
          :unchecked-value="uncheckedValue"
          :state="getValidationState(validationContext)"
          class="custom-form-checkbox"
          switch
        >
          <slot />
        </b-form-checkbox>
      </div>
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormSwitch',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object],
      required: true
    },

    checkedValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },

    uncheckedValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400,
    },

    rules: {
      type: [String, Array],
      default: ''
    },

    requireChecked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    isCheckboxTickedRequirement() {
      return this.requireChecked ? !!this.state : true
    }
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid && this.isCheckboxTickedRequirement) : null
    },
  },

}
</script>

<style>
</style>