<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Billing Settings</span>
    </template>

    <template v-slot:content>
      <div>
        <validation-observer
          ref="formValidation"
          v-slot="{ handleSubmit, invalid }"
        >
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit(onSubmit)"
          >
            <BaseFormInput
              v-model="accountBalanceFormatted"
              label="Account Balance"
              horizontal
              plaintext
              small
              internal-platform-input
            />

            <BaseFormInput
              v-if="!isMinimumInitialDepositRequirementSatisfied && accountDetails.billingConfiguration.prePay"
              id="buyer-billing-settings__minimum-initial-deposit"
              v-model="minimumInitialDepositFormatted"
              label="Minimum Initial Deposit"
              horizontal
              plaintext
              small
              internal-platform-input
              has-tooltip
            />

            <BaseFormInput
              id="buyer-billing-settings__minimum-balance"
              v-model="minimumBalanceFormatted"
              label="Minimum Balance"
              horizontal
              plaintext
              small
              internal-platform-input
              has-tooltip
            />

            <BaseFormSwitch
              v-if="!isMinimumInitialDepositRequirementSatisfied && accountDetails.billingConfiguration.prePay"
              id="autoRecharge"
              v-model="details.autoRecharge"
              required
              label="Auto Recharge"
              internal-platform-input
              horizontal
            />

            <div v-if="details.autoRecharge">
              <BaseFormInput
                id="rechargeBalance"
                v-model="details.rechargeBalance"
                horizontal
                class="mt-4"
                name="Recharge Below"
                placeholder="1000"
                data-cy="input-recharge-balance"
                label="Recharge Below"
                type="number"
                :number="true"
                :rules="`min_value:250|max_value:100000`"
                required
                width="2"
                input-class="fixed-width-130"
                small
                internal-platform-input
              >
                <template v-slot:helpText>
                  <b-form-text>
                    <div>
                      Auto-recharge will be triggered when balance falls below
                      this amount
                    </div>
                  </b-form-text>
                </template>
              </BaseFormInput>

              <BaseFormInput
                id="rechargeAmount"
                v-model="details.rechargeAmount"
                class="mt-4"
                name="Recharge Amount"
                placeholder="1000"
                data-cy="input-recharge-amount"
                label="Recharge Amount"
                type="number"
                :rules="`min_value:250|max_value:100000`"
                :number="true"
                internal-platform-input
                horizontal
                required
                width="2"
                input-class="fixed-width-130"
                small
              >
                <template v-slot:helpText>
                  <b-form-text>
                    <div data-cy="help-text">
                      <p v-if="!defaultCard">
                        Your primary payment method will be charged for this amount when auto-recharge is triggered. The charged amount will reflect in your account balance.
                      </p>

                      <p
                        v-else
                      >
                        Your primary <span class="text-capitalize">{{ defaultCard.cardType }}</span> payment card <strong>{{ defaultCard.name }}</strong> will be charged for additional <strong>{{ defaultCard.processingFee }}%</strong> fee (Total $<strong>{{ (details.rechargeAmount * (100 + defaultCard.processingFee) / 100) }}</strong>) when auto-recharge is triggered. 
                        <br>
                        In case charge of primary card fails, all backup cards will be tried in arbitrary order after until any one of the cards has been charged. The charged amount will reflect in your account balance.
                      </p>
                    </div>
                  </b-form-text>
                </template>
              </BaseFormInput>
            </div>

            <BaseButtonLoader
              v-if="accountDetails.billingConfiguration.prePay"
              id="submit-button"
              data-cy="button-submit"
              :disabled="invalid"
              :loading="loading"
              button-class="custom-button--white-hover-grad pulse"
              type="submit"
              horizontal
              content-cols="9"
            >
              Update
            </BaseButtonLoader>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import currencyFormatter from '@/utils/formatCurrency'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export const MINIMUM_INITIAL_DEPOSIT_REQUIREMENT_TOOLTIP = "The minimum amount of money you need to deposit in Ringba X to start purchasing calls."

export default {
  name: 'BillingSettings',

  title: 'Billing Settings',

  data() {
    return {
      loading: false,
      details: {
        accountId: null,
        billingConfigId: null,
        autoRecharge: false,
        rechargeBalance: 0,
        rechargeAmount: 0
      },

      MINIMUM_INITIAL_DEPOSIT_REQUIREMENT_TOOLTIP
    }
  },
  computed: {
    ...mapGetters('auth', ['accountId', 'accountDetails', 'isBuyerAccount', 'isMinimumInitialDepositRequirementSatisfied']),

    ...mapState('billing', ['accountBalance']),

    ...mapGetters('billing', ['accountBillingConfiguration']),

    ...mapState('billing', ['paymentMethods']),


    accountBalanceFormatted() {
      return currencyFormatter(this.accountBalance.balance)
    },

    minimumInitialDepositFormatted() {
      return currencyFormatter(this.accountBillingConfiguration.minimumInitialDeposit)
    },

    minimumBalanceFormatted() {
      return currencyFormatter(this.accountBillingConfiguration.minimumBalance)
    },

    defaultCard() {
      return this.paymentMethods.find(x => x.default)
    },
  },

  async created() {
    if (this.isBuyerAccount) {
      await this.getBuyerAccountBalance()
      await this.fetchPaymentMethods({accountId: this.accountId})
    }

    this.details.accountId = this.accountId
    this.details.billingConfigId = this.accountDetails.billingConfiguration.id

    if (this.accountDetails.billingConfiguration.prePay) {
      this.details.autoRecharge = this.accountDetails.billingConfiguration.autoRecharge
      this.details.rechargeBalance = this.accountDetails.billingConfiguration.rechargeBalance
      this.details.rechargeAmount = this.accountDetails.billingConfiguration.rechargeAmount
    }
  },

  methods: {
    ...mapActions('billing', ['updateAutoRechargeSettings', 'getBuyerAccountBalance', 'fetchPaymentMethods']),
    ...mapActions('auth', ['fetchUsersAccountDetails']),

    currencyFormatter,

    async onSubmit() {
      try {
        this.loading = true

        await this.updateAutoRechargeSettings({
          ...this.details,
        })

        await this.fetchUsersAccountDetails()

        showSuccessMessage('Successfully updated recharge settings')
      } catch (error) {
        showErrorMessage(error?.response?.data ?? 'Could not update recharge settings')
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
