import CommonEntitiesAPI from '@/api/common-entities.api'
import TIME_ZONES from '../timezones-list'
import US_STATES from '../us-states-list'

// helper functions
const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}


const state = {
  countries: [],
  categories: [],
  trafficSources: [],
  timeZones: Object.freeze(TIME_ZONES),
  US_STATES: Object.freeze(US_STATES)
}

const getters = {
  inboundTrafficSources(state) {
    return state.trafficSources.filter(t => t.trafficSourceType === "Inbound")
  },
  outboundTrafficSources(state) {
    return state.trafficSources.filter(t => t.trafficSourceType === "Outbound")
  },
  transferTrafficSources(state) {
    return state.trafficSources.filter(t => t.trafficSourceType === "Transfer")
  }
}

const actions = {
  async getCountries({ commit }) {
    try {
      const response = await CommonEntitiesAPI.getCountries()

      commit('SET_COUNTRIES', response.data)
    } catch (error) {
      // TODO: Handle error
    }
  },
  async getCategories({ commit }) {
    try {
      const response = await CommonEntitiesAPI.getCategories()
      commit('SET_CATEGORIES', response.data)
    } catch (error) {
      // TODO: Handle error
    }
  },
  async getTrafficSources({ commit }) {
    try {
      const response = await CommonEntitiesAPI.getTrafficSources()
      commit('SET_TRAFFIC_SOURCES', response.data)
    } catch (error) {
      // TODO: Handle error
    }
  }
}

const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries.sort(sortByName)
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories.sort(sortByName)
  },
  SET_TRAFFIC_SOURCES(state, trafficSources) {
    state.trafficSources = trafficSources.sort(sortByName)
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
