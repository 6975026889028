<template>
  <div>
    <div
      class="d-flex flex-column align-items-center justify-content-center flex-grow-1 py-5 onboarding"
    >
      <h4 class="alert alert-light">
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="mr-2"
        />
        You don't have access to any account at the moment. Please contact support for assistance.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccessToNoAccount',
  title: 'Ringba X'
}
</script>
<style>
  
</style>