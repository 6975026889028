<template>
  <b-container
    class="ml-5"
  >
    <b-form-row>
      <b-col 
        class="p-1"
        align-self="end" 
      >
        <span style="font-size: 2.2em; color: #00ff61;">
          <font-awesome-icon :icon="['fas', 'dollar-sign']" />
        </span>
      </b-col>
      <b-col
        v-if="isBuyerAccount"
        v-b-tooltip.hover 
        title="Your total account balance"
        class="p-2"
      >
        <div class="header__account-summary-balance">
          {{ formatCurrency(accountSummary.cashBalance) }}
        </div>
        <div class="header__account-summary-footer">
          Balance
        </div>
      </b-col>
      <b-col
        v-if="!isBuyerAccount"
        title="Total amount we have paid to you"
        class="p-2"
      >
        <div class="header__account-summary-balance">
          {{ formatCurrency(accountSummary.cashBalance) }}
        </div>
        <div class="header__account-summary-footer">
          Received
        </div>
      </b-col>
      <b-col 
        v-if="!isBuyerAccount"
        class="p-1 ml-3"
        align-self="end" 
      >
        <span style="font-size: 2.2em; color: #00cdff;">
          <font-awesome-icon :icon="['fas', 'hand-holding-usd']" />
        </span>
      </b-col>
      <b-col
        v-if="!isBuyerAccount" 
        v-b-tooltip.hover 
        title="Your estimated future payouts"
        class="p-2"
      >
        <div class="header__account-summary-pending">
          {{ formatCurrency(sellerPendingBalance) }}
        </div>
        <div class="header__account-summary-footer">
          Pending
        </div>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'

export default {
  name: 'AccountSummaryHeader',

  computed: {

    ...mapState('billing', [
      'accountSummary'
    ]),

    ...mapGetters('auth', [
      'isBuyerAccount'
    ]),

    ...mapGetters('billing', [
      'sellerPendingBalance'
    ]),

  },

  async created () {
    await this.loadAccountSummaryInIntervals()
  },

  destroyed () {
    clearInterval(this.accountSummaryInterval)
  },

  methods: {
    ...mapActions('billing', ['getAccountBillingSummary']),

    formatCurrency,

    async loadAccountSummaryInIntervals() {
      // Fetch and show account balance summary every 45 secs
      this.accountSummaryInterval = setInterval(async () => {
        await this.getAccountBillingSummary()
      }, 45 * 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.header__account-summary-balance {
  color: #00ff61; 
  line-height: 20px;
  font-weight: 600;
}

.header__account-summary-pending {
  color: #00cdff;
  line-height: 20px;
  font-weight: 600;
}

.header__account-summary-footer {
  color: #cfcfd0; 
  font-size: 12px;
}
</style>
