<template>
  <div class="d-flex flex-column mr-3">
    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Payment Receipts</span>
      </template>
      <template v-slot:content>
        <b-table
          :fields="fields"
          :items="chargeReceipts"
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(paymentDate)="row">
            {{ `${dateFormat(row.item.paymentDate, 'MMM DD, YYYY')}` }}
          </template>

          <template #cell(transactionId)="row">
            <div class="text-nowrap">
              <b-button 
                variant="outline-info"
                size="sm"
                @click="getDownloadLink(row.item.transactionId)"
              >
                Download
              </b-button>
            </div>
          </template>

          <template #cell(amount)="row">
            <div class="text-nowrap">
              {{ `${formatCurrency(row.item.amount)}` }}
            </div>
          </template>

          <template #cell(cardType)="row">
            <img
              :alt="row.item.cardType"
              :src="`/img/${row.item.cardType}.png`"
              width="40px"
            >
          </template>

          <template #cell(lastCreditCardDigits)="row">
            {{ row.item.lastCreditCardDigits }}
          </template>
        </b-table>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'
import dateFormatMixin from '@/mixins/dateFormatMixin'

export default {
  name: 'CreditCardCharges',
  components: {},
  mixins: [dateFormatMixin],
  data() {
    return {
      fields: [
        { key: 'paymentDate', label: 'Date' },
        { key: 'amount', label: 'Amount' },
        { key: 'transactionDescription', label: 'Description' },
        { key: 'cardType', label: 'Card Type' },
        { key: 'lastCreditCardDigits', label: 'Last 4 digits' },
        { key: 'cardName', label: 'Card Name' },
        { key: 'transactionId', label: 'Actions' }
      ]
    }
  },

  computed: {
    ...mapState('dashboard', [
      'chargeReceipts'
    ])
  },

  async created() {
    await this.getCreditCardCharges()
  },

  methods: {
    ...mapActions('dashboard', [
      'getCreditCardCharges',
      'generateInvoiceDownloadLink'
    ]),

    async getDownloadLink(transactionId) {
      const downloadURL = await this.generateInvoiceDownloadLink({ invoiceId: transactionId })
      window.open(downloadURL)
    },

    formatCurrency
  }
}
</script>

<style lang="scss" scoped></style>
