<template>
  <div class="d-flex flex-column mr-3">
    <b-table
      :fields="fields"
      :items="advancePayRequests"
      thead-class="text-nowrap"
      tbody-tr-class="text-nowrap"
    >
      <template #cell(creationDate)="row">
        {{ `${dateFormat(row.item.creationDate, 'MMM DD hh:mm:ss A')}` }}
      </template>

      <template #cell(id)="row">
        <div class="text-nowrap">
          <b-button 
            variant="outline-info"
            size="sm"
          >
            {{ `${row.item.id}` }}
          </b-button>
        </div>
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-button 
            variant="info"
            size="sm"
            @click="getDownloadLink(row.item.id)"
          >
            Download
          </b-button>
        </div>
      </template>

      <template #cell(status)="row">
        <b-badge
          tag="div"
          class="w-100 py-1"
          :variant="getBadgeVariant(row.item.status)"
        >
          {{ startCase(row.item.status) }}
        </b-badge>
      </template>

      <template #cell(amount)="row">
        <div class="text-nowrap">
          {{ `${formatCurrency(row.item.amount)}` }}
        </div>
      </template>

      <template #cell(amountPaid)="row">
        <div class="text-nowrap">
          {{ `${formatCurrency(row.item.amountPaid)}` }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'
import dateFormatMixin from '@/mixins/dateFormatMixin'

export default {
  name: 'AdvancePayRequests',
  components: {},
  mixins: [dateFormatMixin],

  props: {
    advancePayRequests: {
      type: Array,
      default: () => {}
    }
  },

  data() {
    return {
      fields: [
        { key: 'creationDate', label: 'Date' },
        { key: 'status', label: 'Status' },
        { key: 'amount', label: 'Expected Amount', tdClass: 'text-center' },
        { key: 'amountPaid', label: 'Settled Amount', tdClass: 'text-center' },
        { key: 'id', label: 'Payment Reference' },
        { key: 'actions', label: 'Payment Instructions' }
      ]
    }
  },


  methods: {
    ...mapActions('dashboard', [
      'generateInvoiceDownloadLink'
    ]),

    async getDownloadLink(paymentReference) {
      const downloadURL = await this.generateInvoiceDownloadLink({ invoiceId: paymentReference })
      window.open(downloadURL)
    },

    getBadgeVariant(status) {
      const statusToVariantMap = {
        Pending: 'secondary',
        Completed: 'success',
        Failed: 'danger',
        Cancelled: 'warning'
      }
      return statusToVariantMap[status]
    },

    formatCurrency
  }
}
</script>

<style lang="scss" scoped></style>
