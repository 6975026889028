<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        'pr-4': true
      }"
      :label-cols-sm="horizontal ? '3' : null"
      label-cols="12"
      :class="formGroupClass"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :content-cols-xl="widthXl || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <template
        v-if="hasTooltip"
        slot="label"
      >
        <div>
          {{ $attrs.label }}
          <span
            v-b-tooltip.hover.right
            :title="fetchTooltip($attrs.id)"
          >
            <font-awesome-icon
              class="ml-1 mt-1"
              :icon="['fas', 'question-circle']"
            />
          </span>
        </div>
      </template>

      <div class="d-flex flex-row">
        <b-form-select
          v-model="min"
          :size="$attrs.size"
          :disabled="$attrs.disabled"
          :text-field="$attrs['text-field']"
          :value-field="$attrs['value-field']"
          class="custom-range-dropdown-select--left"
          :class="{ 'custom-form-input--sm': small, 'custom-form-input': !small,
                    [inputClass]: inputClass }"
          :state="getValidationState(validationContext)"
          :options="minOptions"
        />
        <b-form-select
          v-model="max"
          :size="$attrs.size"
          :disabled="$attrs.disabled"
          :text-field="$attrs['text-field']"
          :value-field="$attrs['value-field']"
          class="custom-range-dropdown-select--right"
          :class="{ 'custom-form-input--sm': small, 'custom-form-input': !small,
                    [inputClass]: inputClass }"
          :state="getValidationState(validationContext)"
          :options="validMaxOptions"
        />
      </div>
      <div
        v-if="isMaxLessThanMin"
        class="text-danger"
      >
        Minimum value cannot be greater than maximum value
      </div>
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseRangeSelect',

  mixins: [formInputMixin],

  props: {
    value: {
      type: Object,
      default: () => {}
    },

    minOptions: {
      type: Array,
      default() {
        return []
      }
    },

    maxOptions: {
      type: Array,
      default() {
        return []
      }
    },

    formGroupClass: {
      type: String,
      default: ''
    },

    small: {
      type: Boolean,
      default: false
    },

    hasTooltip: {
      type: Boolean,
      default: false
    }

  },

  computed: {
    min: {
      get() {
        return this.value.min
      },
      set(value) {
        this.$emit('change', { ...this.value, min: value })
        this.$emit('input', { ...this.value, min: value })
      }
    },

    max: {
      get() {
        return this.value.max
      },
      set(value) {
        this.$emit('change', { ...this.value, max: value })
        this.$emit('input', { ...this.value, max: value })
      }
    },

    isMaxLessThanMin() {
      const minValueIndex = this.maxOptions.indexOf(this.value.min)
      const maxValueIndex = this.maxOptions.indexOf(this.value.max)
      return minValueIndex > maxValueIndex
    },

    validMaxOptions() {
      const minValueIndex = this.maxOptions.indexOf(this.value.min)
      return this.maxOptions.map((option, index) => {
        if (index >= minValueIndex) {
          return { value: option, text: option }
        } else {
          return { value: option, text: option, disabled: true }
        }
      })
    }


  }
}
</script>

<style lang="scss">

</style>
