<template>
  <div class="pt-4">
    <h5 class="mb-5">
      Please upload the masked pictures of the front/back of your credit card
      along with an ID proof
    </h5>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormSelect
          id="selectedCard"
          v-model="details.selectedCard"
          :disabled="disableSelect"
          size="sm"
          data-cy="select-credit-card"
          :options="paymentMethodOptions"
          label="Credit Card to submit KYC Docs for:"
          required
        /> 

        <BaseFileUpload
          id="ccFront"
          v-model="details.ccFront"
          data-cy="file-input-cc-front"
          name="ccFront"
          accept=".jpg,.png,.jpeg"
          rules="image|ext:jpg,jpeg,png|size:10240"
          placeholder="Browse"
          label="Upload card front"
          required
        >
          <template v-slot:helpText>
            <b-form-text>
              <div>
                Please block out all but 4 digits of credit card as pictured in
                the example below.
              </div>
              <img
                width="220px"
                src="/img/cc_front.png"
                alt="credit card front"
              >
            </b-form-text>
          </template>
        </BaseFileUpload>

        <BaseFileUpload
          id="ccBack"
          v-model="details.ccBack"
          name="ccBack"
          data-cy="file-input-cc-back"
          accept=".jpg,.png,.jpeg"
          rules="image|ext:jpg,jpeg,png|size:10240"
          placeholder="Browse"
          label="Upload card back"
          required
        >
          <template v-slot:helpText>
            <b-form-text class="mt-2">
              <img
                width="220px"
                src="/img/cc_back.png"
                alt="credit card back"
              >
            </b-form-text>
          </template>
        </BaseFileUpload>

        <BaseFileUpload
          id="photoId"
          v-model="details.photoId"
          name="photoId"
          data-cy="file-input-photo-id"
          accept=".jpg,.png,.jpeg"
          rules="image|ext:jpg,jpeg,png|size:10240"
          placeholder="Browse"
          label="Upload ID"
          required
        >
          <template v-slot:helpText>
            <b-form-text class="mt-2">
              <img
                width="220px"
                src="/img/drivers_licence.png"
                alt="photo id"
              >
            </b-form-text>
          </template>
        </BaseFileUpload>

        <div class="text-center">
          <BaseButtonLoader
            id="submit-button"
            data-cy="button-submit"
            :loading="isLoading"
            :disabled="invalid"
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            Submit
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'KYCForm',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedCard: {
      type: String,
      default: ''
    },
    disableSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details: {
        selectedCard: null,
        ccFront: null,
        ccBack: null,
        photoId: null,        
        billingConfigId: null
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['accountDetails']),
    ...mapGetters('billing', ['unverifiedPaymentMethods']),
    paymentMethodOptions() {
      return this.unverifiedPaymentMethods.map(pm => {
        return {
          value: pm.id,
          text: `${pm.maskedCC} - ${pm.name}`
        }
      })
    }
  },
  async created() {    
    this.details.billingConfigId = this.accountDetails.billingConfiguration.id
    await this.fetchPaymentMethods({
      accountId: this.accountDetails.id
    })
    if (this.selectedCard) {
      this.details.selectedCard = this.selectedCard
    } else {
      this.details.selectedCard = this.paymentMethodOptions?.[0]?.value
    }
  },
  methods: {
    ...mapActions('billing', ['submitKYCDocs', 'fetchPaymentMethods']),
    async onSubmit() {
      this.$emit('submit')
      try {
        await this.submitKYCDocs({
          accountId: this.accountDetails.id,
          paymentMethodId: this.details.selectedCard,
          ccFront: this.details.ccFront,
          ccBack: this.details.ccBack,
          photoId: this.details.photoId,
          billingConfigId: this.details.billingConfigId          
        })
        this.$emit('success')
      } catch (error) {
        this.$emit('error', error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
