import GlobalSettingsAPI from "@/api/global-settings.api"

const SHOW_CREATIVES = "ShowCreatives"

const state = {
  showCreatives: false
}

const actions = {
  async getShowCreativesSetting({ commit }) {
    const response = await GlobalSettingsAPI.getBoolSettingValue({ settingId: SHOW_CREATIVES })
    commit('SET_SHOW_CREATIVES', Boolean(response.data))
    return response.data
  },
}

const mutations = {
  SET_SHOW_CREATIVES(state, data) {
    state.showCreatives = data
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}  