import httpClient from './httpClient'

const END_POINT = '/info/settings'

const GlobalSettingsAPI = {
  async getStringSettingValue({ settingId }) {
    const url = `${END_POINT}/string/${settingId}`
    return httpClient.get(url)
  },

  async getBoolSettingValue({ settingId }) {
    const url = `${END_POINT}/bool/${settingId}`
    return httpClient.get(url)
  }  
}

export default GlobalSettingsAPI
