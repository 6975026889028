<template>
  <b-link
    :class="customClasses"
    v-bind="$props"
    exact-active-class="active"
    active-class="active"
  >
    <slot />
  </b-link>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    to: {
      type: [String, Object],
      default: ''
    },

    renderAsButton: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },
    
    exact: {
      type: Boolean,
      default: false
    },

    href: {
      type: String,
      default: ''
    }
  },

  computed: {
    customClasses() {
      return this.renderAsButton ? 'custom-button custom-button--white-hover-grad pulse text-decoration-none' : '' 
    }
  },
}
</script>

<style>

</style>