import DasbboardAPI from '@/api/dashboard.api.js'
import SellerAccountAPI from '@/api/seller-account.api'
import BuyerAccountAPI from '@/api/buyer-account.api'

const state = {
  buyerDashboardGraphData: [],
  buyerDashboardInsights: {},
  buyerDashboardCampaigns: [],
  buyerAutoRefresh: false,
  buyerCampaignChannsels: [],
  sellerDashboardGraphData: [],
  sellerDashboardInsights: {},
  sellerDashboardOffers: [],
  sellerAutoRefresh: false,
  sellerAvailableOffers: [],
  sellerPayouts: [],
  chargeReceipts: [],
  advancePayRequests: [],
  systemAccountBankDetails: {},
}

const getters = {
}

const actions = {
  async getBuyerDashboardData({ commit }, { from, to, timezone }) {
    const response = await DasbboardAPI.getBuyerDashboardData({
      from,
      to,
      timezone
    })
    commit('SET_BUYER_DASHBOARD_GRAPH_DATA', response.data.graphData)
    commit('SET_BUYER_DASHBOARD_INSIGHTS', response.data.insights)
    return response.data
  },
  async getSellerDashboardData({ commit }, { from, to, timezone }) {
    const response = await DasbboardAPI.getSellerDashboardData({
      from,
      to,
      timezone
    })
    commit('SET_SELLER_DASHBOARD_GRAPH_DATA', response.data.graphData)
    commit('SET_SELLER_DASHBOARD_INSIGHTS', response.data.insights)
    return response.data
  },
  async getBuyerCampaigns({ commit }) {
    const response = await DasbboardAPI.getBuyerCampaigns()
    commit('SET_BUYER_CAMPAIGNS', response.data)
    return response.data
  },
  async getSellerOffers({ commit }) {
    const response = await DasbboardAPI.getSellerOffers()
    commit('SET_SELLER_OFFERS', response.data)
    return response.data
  },
  async getBuyerCampaignChannels({ commit }, { campaignId }) {
    const response = await DasbboardAPI.getBuyerCampaignChannels({ campaignId })
    commit('SET_BUYER_CAMPAIGN_CHANNELS', response.data)
    return response.data
  },
  async getSellerAvailableOffers({ commit }) {
    const response = await DasbboardAPI.getSellerAvailableOffers()
    commit('SET_SELLER_AVAILABLE_OFFERS', response.data)
    return response.data
  },
  async getSellerPayouts({ rootGetters, commit }) {
    let accountId = rootGetters['auth/accountId']

    const response = await SellerAccountAPI.getSellerPayouts(accountId)
    commit('SET_SELLER_PAYOUTS', response.data)
    return response.data
  },

  async getCreditCardCharges({ rootGetters, commit }) {
    let accountId = rootGetters['auth/accountId']

    const response = await BuyerAccountAPI.getCreditCardCharges(accountId)
    commit('SET_CREDIT_CARD_CHARGES', response.data)
    return response.data
  },

  async getSystemAccountBankDetails({commit }) {
    const response = await BuyerAccountAPI.getSystemAccountBankDetails()
    commit('SET_BANK_DETAILS', response.data)
    return response.data
  },

  async getAdvancePayRequests({ rootGetters, commit }) {
    let accountId = rootGetters['auth/accountId']

    const response = await BuyerAccountAPI.getAdvancePayRequests(accountId)
    commit('SET_ADVANCE_PAY_REQUESTS', response.data)
    return response.data
  },

  async createAdvancePaymentRequest({ rootGetters }, advancePayRequest) {
    let accountId = rootGetters['auth/accountId']

    const response = await BuyerAccountAPI.createAdvancePaymentRequest({
      accountId: accountId,
      advancePayRequest: advancePayRequest
    })

    return response.data
  },

  async generateInvoiceDownloadLink({ rootGetters }, { invoiceId }) {
    let accountId = rootGetters['auth/accountId']

    const response = await DasbboardAPI.generateInvoiceDownloadLink({accountId, invoiceId })
    return response.data
  }
}

const mutations = {
  SET_BUYER_DASHBOARD_GRAPH_DATA(state, graphData) {
    state.buyerDashboardGraphData = graphData
  },
  SET_BUYER_DASHBOARD_INSIGHTS(state, insights) {
    state.buyerDashboardInsights = insights
  },
  SET_BUYER_CAMPAIGNS(state, campaigns) {
    state.buyerDashboardCampaigns = campaigns
  },
  TOGGLE_BUYER_AUTO_REFRESH(state) {
    state.buyerAutoRefresh = !state.buyerAutoRefresh
  },
  TOGGLE_BUYER_CALL_LOGS_AUTO_REFRESH(state) {
    state.buyerCallLogsAutoRefresh = !state.buyerCallLogsAutoRefresh
  },
  SET_BUYER_CAMPAIGN_CHANNELS(state, campaignChannels) {
    state.buyerCampaignChannels = campaignChannels
  },
  SET_SELLER_DASHBOARD_GRAPH_DATA(state, graphData) {
    state.sellerDashboardGraphData = graphData
  },
  SET_SELLER_DASHBOARD_INSIGHTS(state, insights) {
    state.sellerDashboardInsights = insights
  },
  SET_SELLER_OFFERS(state, offers) {
    state.sellerDashboardOffers = offers
  },
  TOGGLE_SELLER_AUTO_REFRESH(state) {
    state.sellerAutoRefresh = !state.sellerAutoRefresh
  },
  SET_SELLER_AVAILABLE_OFFERS(state, availableOffers) {
    state.sellerAvailableOffers = availableOffers
  },
  SET_SELLER_PAYOUTS(state, payouts) {
    state.sellerPayouts = payouts
  },
  SET_CREDIT_CARD_CHARGES(state, chargeReceipts) {
    state.chargeReceipts = chargeReceipts
  },
  SET_ADVANCE_PAY_REQUESTS(state, advancePayRequests) {
    state.advancePayRequests = advancePayRequests
  },
  SET_BANK_DETAILS(state, bankDetails) {
    state.systemAccountBankDetails = bankDetails
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
