/* eslint-disable */
// Remove above line when API is ready
import httpClient from './httpClient'

const END_POINT = '/customerresources'

const BillingAPI = {
  addFundsByCreditCardCharge(accountId, amount, creditCardId) {
    return httpClient.post(`${END_POINT}/${accountId}/billing/funds/pay`, {
      amount,
      creditCardId
    })
  },

  
  /**
   * Get account payments
   * @param {string} accountId 
   * @returns void
   */
  async getAccountPayments(accountId) {
    return await httpClient.get(`${END_POINT}/user-account/${accountId}/payments`)
  },
}

export default BillingAPI