var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{class:_vm.formGroupClass,attrs:{"label":_vm.$attrs.label,"label-for":_vm.$attrs.id,"label-class":{
      'custom-form-input-label--internal': _vm.internalPlatformInput,
      'custom-form-input-label': !_vm.internalPlatformInput,
      'pr-4': true
    },"label-cols-sm":_vm.horizontal ? '3' : null,"label-cols":"12","description":_vm.$attrs.description,"content-cols":_vm.widthXs || 12,"content-cols-sm":_vm.inputWidth,"content-cols-lg":_vm.widthLg || null,"content-cols-xl":_vm.widthXl || null,"label-align-sm":_vm.horizontal ? 'right' : '',"label-align":"left"}},[(_vm.hasTooltip)?_c('template',{slot:"label"},[_c('div',[_vm._v(" "+_vm._s(_vm.$attrs.label)+" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":_vm.fetchTooltip(_vm.$attrs.id)}},[_c('font-awesome-icon',{staticClass:"ml-1 mt-1",attrs:{"icon":['fas', 'question-circle']}})],1)])]):_vm._e(),_c('b-form-select',_vm._b({class:{
        'custom-form-input--sm': _vm.small,
        'custom-form-input': !_vm.small,
      },attrs:{"size":_vm.$attrs.size,"disabled":_vm.$attrs.disabled || _vm.$attrs.readonly,"text-field":_vm.$attrs['text-field'],"value-field":_vm.$attrs['value-field'],"disabled-field":_vm.$attrs['not-enabled-field'],"state":_vm.getValidationState(validationContext),"options":_vm.options},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},'b-form-select',_vm.$attrs,false))],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }