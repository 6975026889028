import RegistrationAPI from '@/api/registration.api'
import { convertToE164Format } from '@/utils/phoneNumberUtils'

const state = {
  registrationApplication: {
    companyDetails: {},
    representativeDetails: {},
    buyerBusinessDetails: {},
    callTechnologyDetails: {},
    sellerBusinessDetails: {},
    sellerTrafficSourcesDetails: {},
    buyerTrafficSourcesDetails: {},
    authorizeRingbaToContact: true
  },

  error: ''
}

const getters = {}

const actions = {
  setCompanyDetails({ commit }, companyDetails) {
    commit('SET_COMPANY_DETAILS', companyDetails)
  },

  setRepresentativeDetails({ commit }, representativeDetails) {
    commit('SET_REPRESENTATIVE_DETAILS', representativeDetails)
  },

  setBuyerBusinessDetails({ commit }, buyerBusinessDetails) {
    commit('SET_BUYER_BUSINESS_DETAILS', buyerBusinessDetails)
  },

  setCallTechnologyDetails({ commit }, callTechnologyDetails) {
    commit('SET_CALL_TECHNOLOGY_DETAILS', callTechnologyDetails)
  },

  setSellerTrafficSourcesDetails({ commit }, sellerTrafficSourcesDetails) {
    commit('SET_SELLER_TRAFFIC_SOURCES_DETAILS', sellerTrafficSourcesDetails)
  },

  setBuyerTrafficSourcesDetails({ commit }, buyerTrafficSourcesDetails) {
    commit('SET_BUYER_TRAFFIC_SOURCES_DETAILS', buyerTrafficSourcesDetails)
  },

  setSellerBusinessDetails({ commit }, sellerBusinessDetails) {
    commit('SET_SELLER_BUSINESS_DETAILS', sellerBusinessDetails)
  },

  setAuthorizeRingbaToContact({ commit }, authorizeRingbaToContact) {
    commit('SET_AUTHORIZE_RINGBA_TO_CONTACT', authorizeRingbaToContact)
  },

  async submitBuyerRegistrationRequest({ commit, state }) {
    commit('RESET_ERROR')

    try {
      await RegistrationAPI.submitBuyerRequest({
        ...state.registrationApplication.companyDetails,
        companyPhone: convertToE164Format(state.registrationApplication.companyDetails.companyPhone),
        representative: {
          ...state.registrationApplication.representativeDetails,
          cellPhone: convertToE164Format(state.registrationApplication.representativeDetails.cellPhone)
        },
        ...state.registrationApplication.buyerBusinessDetails,
        ...state.registrationApplication.callTechnologyDetails,
        ...state.registrationApplication.buyerTrafficSourcesDetails,
        authorizeRingbaToContact:
          state.registrationApplication.authorizeRingbaToContact
      })
    } catch (error) {
      commit('SET_ERROR', error.response.data)
    }
  },

  async submitSellerRegistrationRequest({ commit, state }) {
    commit('RESET_ERROR')

    try {
      await RegistrationAPI.submitSellerRequest({
        ...state.registrationApplication.companyDetails,
        companyPhone: convertToE164Format(state.registrationApplication.companyDetails.companyPhone),
        representative: {
          ...state.registrationApplication.representativeDetails,
          cellPhone: convertToE164Format(state.registrationApplication.representativeDetails.cellPhone)
        },
        ...state.registrationApplication.sellerBusinessDetails,
        ...state.registrationApplication.callTechnologyDetails,
        ...state.registrationApplication.sellerTrafficSourcesDetails,
        authorizeRingbaToContact:
          state.registrationApplication.authorizeRingbaToContact
      })
    } catch (error) {
      commit('SET_ERROR', error.response.data.title)
    }
  }
}

const mutations = {
  SET_COMPANY_DETAILS(state, companyDetails) {
    state.registrationApplication.companyDetails = companyDetails
  },

  SET_REPRESENTATIVE_DETAILS(state, representativeDetails) {
    state.registrationApplication.representativeDetails = representativeDetails
  },

  SET_BUYER_BUSINESS_DETAILS(state, buyerBusinessDetails) {
    state.registrationApplication.buyerBusinessDetails = buyerBusinessDetails
  },

  SET_SELLER_BUSINESS_DETAILS(state, sellerBusinessDetails) {
    state.registrationApplication.sellerBusinessDetails = sellerBusinessDetails
  },

  SET_CALL_TECHNOLOGY_DETAILS(state, callTechnologyDetails) {
    state.registrationApplication.callTechnologyDetails = callTechnologyDetails
  },

  SET_SELLER_TRAFFIC_SOURCES_DETAILS(state, sellerTrafficSourcesDetails) {
    state.registrationApplication.sellerTrafficSourcesDetails = sellerTrafficSourcesDetails
  },

  SET_BUYER_TRAFFIC_SOURCES_DETAILS(state, buyerTrafficSourcesDetails) {
    state.registrationApplication.buyerTrafficSourcesDetails = buyerTrafficSourcesDetails
  },

  SET_AUTHORIZE_RINGBA_TO_CONTACT(state, authorizeRingbaToContact) {
    state.registrationApplication.authorizeRingbaToContact = authorizeRingbaToContact
  },

  RESET_ERROR(state) {
    state.error = ''
  },

  SET_ERROR(state, error) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
