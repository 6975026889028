import { mapActions } from 'vuex'

import BaseESignOnboarding from './BaseESignOnboarding.vue'

const SellerESignOnboarding = BaseESignOnboarding.extend({
  methods: {
    ...mapActions('account', {
      getPendingDocumentBundleStatusForAccount: 'getPendingDocumentBundleStatusForSeller',
      createOnboardingDocsForAccount: 'createOnboardingDocsForSeller'
    }),
  }
})

export default SellerESignOnboarding