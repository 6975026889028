<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(addBreak)"
      >
        <BaseTimePicker
          id="startTime"
          v-model="opBreak.startTime"
          internal-platform-input
          :horizontal="true"
          :small="true"
          name="Start Time"
          label="Break Start Time"
          required
          width="5"
          width-lg="3"
        />

        <BaseFormInput
          id="breakDuration"
          v-model="opBreak.lengthInMin"
          internal-platform-input
          horizontal
          small
          name="Duration"
          placeholder="Enter Duration"
          label="Break Duration"
          type="number"
          required
          width="4"
          width-lg="2"
          rules="min_value:1"
        >
          <template v-slot:helpText>
            minutes
          </template>
        </BaseFormInput>

        <b-form-row>
          <b-col offset-sm="3">
            <b-button
              class="custom-button--flat"
              size="sm"
              variant="primary"
              type="submit"
              :disabled="invalid"
            >
              Add Break
            </b-button>
            
            <b-button
              class="ml-4 custom-button--flat"
              size="sm"
              variant="danger"
              @click="$emit('cancel')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { getSettingObjectFromTimeString } from './hoursOfOperationsUtils'
export default {
  name: 'TargetBreakForm',

  data() {
    return {
      opBreak: {
        startTime: '12:00:00',
        lengthInMin: 1
      }
    }
  },

  methods: {
    addBreak() {
      this.$emit('add-break', {
        ...this.opBreak,
        startTime: getSettingObjectFromTimeString(this.opBreak.startTime)
      })
    }
  },

}
</script>

<style>

</style>