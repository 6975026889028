<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(submitForm)"
      >
        <BaseCurrencyInput
          v-model="amountInput"
          :horizontal="true"
          internal-platform-input
          small
          name="Amount"
          label="Amount"
          placeholder="Amount"
          :rules="amountValidation"
          required
          width="2"
          width-lg="2"
          width-xs="4"
          input-class="fixed-width-130"
        />

        <BaseFormSelect
          id="buyer-add-funds-credit-card"
          ref="buyer-add-funds-credit-card"
          v-model="creditCardId"
          :horizontal="true"
          :has-tooltip="true"
          internal-platform-input
          :small="true"
          name="Credit Card"
          label="Credit Card"
          text-field="name"
          value-field="id"
          not-enabled-field="notEnabled"
          :options="creditCardsList"
          required
          width="5"
          width-lg="4"
          width-xl="3"
        />      

        <BaseButtonLoader
          horizontal
          :loading="isLoading"
          class="mt-4"
          button-class="custom-button--white-hover-grad pulse"
          type="submit"
          content-cols="9"
          :disabled="!isCreditCardApproved()"
        >
          Add
        </BaseButtonLoader>
      </b-form>
    </validation-observer>

    <b-modal
      id="confirm-funds-modal"
      ref="confirm-funds-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Add Funds"
      ok-title="Add"
      button-size="sm"
      @ok="addFunds"
    >
      <p class="mb-2">
        Are you sure you want to add <strong>{{ formatCurrency(amount || 0) }}</strong>?
      </p>
      <p class="mb-0">
        <span class="text-muted"> A {{ processingFee }}% ({{ processingFeeFormatted }}) processing fee applies.</span>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'

import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'AddFundsCreditCardCharge',

  data() {
    return {
      amount: undefined,
      amountFormatted: '',
      isLoading: false,
      creditCardId: null
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountId'
    ]),

    ...mapState('billing', [
      'paymentMethods'
    ]),

    ...mapGetters('billing', [
      'buyerMinimumCreditCardChargeAmount',
      'buyerMaximumCreditCardChargeAmount',
    ]),

    amountValidation() {
      return `amount_range:${this.buyerMinimumCreditCardChargeAmount},${this.buyerMaximumCreditCardChargeAmount}`
    },

    amountInput: {
      get() {
        return this.amountFormatted
      },

      set(value) {
        this.amountFormatted = value
        this.amount = Number(value.replace(/[$,]/g, ''))
      }
    },

    creditCardsList() {
      let list = this.paymentMethods.map(cc => {
        return {
          name: cc._Status === "Approved" ? cc.name : cc.name + " (" + cc._Status.toLowerCase() + ")",
          id: cc.id,
          notEnabled: cc._Status !== "Approved"
        }
      })

      list.unshift({ name: 'Please select', id: null })

      return list
    },

    processingFee(){
      if (!this.creditCardId){
        return 0
      }

      let paymentMethod = this.paymentMethods.find(x => x.id === this.creditCardId)
      return paymentMethod.processingFee
    },

    processingFeeFormatted(){
      if (!this.creditCardId){
        return 0
      }

      let paymentMethod = this.paymentMethods.find(x => x.id === this.creditCardId)
      let result = this.amount * (paymentMethod.processingFee/100)
      return formatCurrency(result)
    }    
  },

  async created () {
    await this.fetchPaymentMethods({ accountId: this.accountId })
  },

  methods: {
    formatCurrency,

    ...mapActions('billing', [
      'addFundsByCreditCardCharge',
      'fetchPaymentMethods'
    ]),

    submitForm() {
      this.$bvModal.show('confirm-funds-modal')
    },

    async addFunds() {
      try {
        this.isLoading = true

        await this.addFundsByCreditCardCharge({ amount: this.amount, accountId: this.accountId, creditCardId: this.creditCardId })

        showSuccessMessage('Successfully added funds')

        this.amountInput = ''
        this.creditCardId = null
        this.$refs.formValidation.reset()

        this.$emit('update-balance')
      } catch (error) {
        showErrorMessage(error.response?.data?.title ?? error.message)
      } finally {
        this.isLoading = false
      }
    },

    isCreditCardApproved(){
      let paymentMethod = this.paymentMethods.find(x => x.id === this.creditCardId)
      return paymentMethod && paymentMethod._Status === "Approved"
    }
  },
}
</script>

<style>

</style>