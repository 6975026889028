<template>
  <div>
    <div
      class="d-flex flex-column align-items-center justify-content-center flex-grow-1 py-5 onboarding"
    >
      <h5 class="alert alert-light d-flex">
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
        />

        <div class="ml-2">
          We have received your submission. A team member is reviewing your account for final approval.

          <div class="mt-1">
            Please contact us if you have any questions.
          </div>
        </div>
      </h5>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdminApprovalPending',

  title: 'Ringba X'
}
</script>
<style>
  
</style>