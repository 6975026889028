import httpClient from './httpClient'

const END_POINT = '/seller'

const SellerOfferApi = {
  getAllSellerOffers(accountId) {
    const url = `${END_POINT}/${accountId}/offers`
    return httpClient.get(url)
  },
  getSellerOfferRegistrations(accountId) {
    const url = `${END_POINT}/${accountId}/offers/applied`
    return httpClient.get(url)
  },
  getSellerOfferDetailsById(accountId, id) {
    const url = `${END_POINT}/${accountId}/offers/${id}`
    return httpClient.get(url)
  },
  submitParticipationRequest(accountId, payload) {    
    const url = `${END_POINT}/${accountId}/offers/submit-request`
    return httpClient.post(url, payload)
  },
  withdrawParticipationRequest(accountId, id) {
    const url = `${END_POINT}/${accountId}/offers/${id}/withdraw-request`
    return httpClient.post(url, {})
  },

  getSellerOfferBiddingURL(accountId, registrationId) {
    const url = `${END_POINT}/${accountId}/offers/${registrationId}/urls`
    return httpClient.get(url)
  },
}

export default SellerOfferApi
