<template>
  <div id="app">
    <notifications group="default" />

    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>

<script>
export default {
  name: 'App',

  computed: {
    layout() {
      return this.$route.matched[0]?.meta?.layout || 'div'
    }
  },

}
</script>
