<template>
  <b-modal
    id="rejection-reason-modal"
    ref="rejection-reason-modal"
    size="lg"
    header-class="custom-modal"
    body-class="custom-modal"
    footer-class="custom-modal"
    :hide-footer="true"
    :title="`Rejection Details: ${registration.sellerOffer.name}`"
    @hidden="$emit('hidden')"
  >
    <div class="px-4">
      <BaseFormInput
        id="seller-offer__updated-at"
        v-model="updatedAt"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Rejected At"
        type="text"
        width-lg="4"
        width-xl="3"
        plaintext
        input-class="px-0"
      />

      <BaseFormTextArea
        v-model="registration.reason"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Rejection reason"
        type="text"
        width-lg="4"
        width-xl="3"
        rows="5"
        plaintext
        disabled
        input-class="px-0"
        placeholder="The reason for the rejection"
      />
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'RejectionReasonModal',
  props: {
    registration: {
      type: Object,
      default: () => {}
    },
  },

  computed:{
    updatedAt() {
      return moment(this.registration.updatedAt).format('MMM DD, YYYY')
    },
  }
}
</script>

<style lang="scss">
</style>
