export default {
  methods: {
    renderFractionInUSLocale(numerator, denominator) {
      return numerator
        ? `${numerator.toLocaleString('en-US')} / ${denominator.toLocaleString(
          'en-US'
        )}`
        : '-'
    },
    renderNumberInUSLocale(number) {
      return number.toLocaleString('en-US')
    }
  }
}
