<template>
  <div>
    <h5 class="mb-5">
      Please add your credit card details below
    </h5>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid, untouched }"
    >
      <b-form
        data-cy="credit-card-form"
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-form-row>
          <b-col>
            <BaseFormInput
              id="cardName"
              v-model="details.cardName"
              data-cy="input-card-name"
              name="Card Name"
              placeholder="An alias for your own reference"
              label="Card Name"
              type="text"
              required
            />
          </b-col>

          <b-col>
            <BaseFormInput
              id="fullName"
              v-model="details.fullName"
              data-cy="input-full-name"
              name="Full Name"
              placeholder="Name as it appears on the card"
              label="Card Holder's Name"
              type="text"
              required
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="8">
            <b-form-group
              label="Credit Card Number"
              label-for="credit_card"
              label-class="custom-form-input-label"
            >
              <div
                id="spreedly-number"
                class="form-control custom-form-input"
                :class="{ 'is-invalid': !validNumber && numberTouched }"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="CVV"
              label-for="cvv"
              label-class="custom-form-input-label"
            >
              <div
                id="spreedly-cvv"
                class="form-control custom-form-input"
                :class="{ 'is-invalid': !validCvv && cvvTouched }"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <BaseFormInput
              id="email"
              v-model="details.email"
              name="Email"
              data-cy="input-email"
              placeholder="Your email address"
              label="Email"
              rules="email"
              type="text"
              required
            />
          </b-col>
          <b-col sm="3">
            <BaseFormSelect
              id="expMonth"
              v-model="details.expMonth"
              data-cy="select-expMonth"
              :options="monthOptions"
              label="Expiry Month"
              required
            />
          </b-col>
          <b-col sm="3">
            <BaseFormSelect
              id="expYear"
              v-model="details.expYear"
              data-cy="select-expYear"
              :options="yearOptions"
              label="Expiry year"
              required
            />
          </b-col>
        </b-form-row>

        <BaseFormCheckbox
          id="useAccountAddress"
          v-model="details.useAccountAddress"
          name="useAccountAddress"
        >
          Use the same address as the one used during registration
        </BaseFormCheckbox>

        <div
          v-if="!details.useAccountAddress"
          class="mt-3"
        >
          <BaseFormInput
            id="streetAddress"
            v-model="details.address.streetAddress"
            name="Street Address"
            placeholder="Address Line 1"
            label="Address Line 1"
            type="text"
            required
          />

          <BaseFormInput
            id="addressLine2"
            v-model="details.address.addressLine2"
            name="Address Line2"
            placeholder="Address Line 2"
            label="Adress Line 2"
            type="text"
          />
          <b-form-row>
            <b-col>
              <BaseFormInput
                id="city"
                v-model="details.address.city"
                name="City"
                placeholder="City"
                label="city"
                type="text"
                required
              />
            </b-col>
            <b-col>
              <BaseFormInput
                id="state"
                v-model="details.address.state"
                name="State"
                placeholder="State"
                label="state"
                type="text"
                required
              />
            </b-col>
            <b-col>
              <BaseFormInput
                id="countryName"
                v-model="details.address.countryName"
                name="Country Name"
                placeholder="Country"
                label="country"
                type="text"
                required
              />
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <BaseFormInput
                id="postalCode"
                v-model="details.address.postalCode"
                name="Postal Code"
                placeholder="Postal Code"
                label="Postal Code"
                type="text"
                required
              />
            </b-col>
            <b-col>
              <BaseFormInput
                id="phoneNumber"
                v-model="details.address.phoneNumber"
                name="Phone Number"
                placeholder="Phone Number"
                label="Phone Number"
                type="tel"
                rules="phone"
                required
              />
            </b-col>
          </b-form-row>
        </div>

        <div class="text-center mt-3">
          <BaseButtonLoader
            id="submit-button"
            data-cy="button-submit"
            :loading="isLoading"
            :disabled="untouched || invalid || !validCardDetails"
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            {{ buttonLabel }}
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>

    <b-modal
      id="added-credit-card-modal"
      ref="added-credit-card-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Thank you for Adding a Credit Card"     
      button-size="sm"        
      @close="hideModalWindow"   
    >
      <p class="mb-0">        
        Please upload necessary verification documents <strong>within 2 weeks</strong> to activate the card. 
      </p>

      <template #modal-footer>        
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          @click="returnToPaymentMethods"
        >
          Ok
        </b-button>
        <b-button
          type="button"
          variant="primary"
          size="sm"          
          @click="redirectToUploadKYC"
        >
          Upload
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { showErrorMessage } from '@/notification-utils'
const spreedlyToken = process.env.VUE_APP_SPREEDLY_TOKEN

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreditCardForm',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default: 'Submit'
    }
  },
  data() {
    return {
      details: {
        expMonth: null,
        expYear: null,
        useAccountAddress: true,
        address: {}
      },
      validNumber: false,
      validCvv: false,
      numberTouched: false,
      cvvTouched: false,
      paymentMethodId: '',
    }
  },
  computed: {
    ...mapGetters('auth', ['accountDetails']),
    
    validCardDetails() {
      return this.validNumber && this.validCvv
    },
    monthOptions() {
      const monthsArray = Array.from(
        { length: 12 },
        (_, i) => i + 1
      ).map(value => ({ text: value, value }))
      monthsArray.unshift({ value: null, text: 'Month' })
      return monthsArray
    },
    yearOptions() {
      const today = new Date()
      const currentMonth = today.getUTCMonth() + 1
      const currentYear = today.getFullYear()
      let minYear = currentYear
      if (this.details.expMonth && this.details.expMonth < currentMonth) {
        minYear += 1
      }
      const yearsArray = Array.from(
        { length: 100 },
        (_, i) => i + minYear
      ).map(value => ({ text: value, value }))
      yearsArray.unshift({ value: null, text: 'Year' })
      return yearsArray
    }
  },
  created() {
    setTimeout(() => {
      initializeSpreedly()
      addErrorHandlers(this)
    }, 500)
  },
  methods: {
    ...mapActions('billing', ['addPaymentMethod']),

    showModalWindow() {
      this.$bvModal.show('added-credit-card-modal')
    },

    returnToPaymentMethods() {
      this.$bvModal.hide('added-credit-card-modal')
      this.$emit('success')
    },

    redirectToUploadKYC() {
      this.$bvModal.hide('added-credit-card-modal')
      if (this.paymentMethodId) {
        this.isLoading = false
        this.$router.push({
          name: 'UploadKYCDocs',
          params: { paymentMethodId: this.paymentMethodId }
        })
      }
      else {
        this.$emit('success')
      }
    },

    async onSubmit() {
      this.$emit('submit')
      // const submitButton = document.getElementById('submit-button')
      // submitButton.disabled = true

      // Get required, non-sensitive, values from host page
      const requiredFields = {}
      requiredFields.full_name = this.details.fullName
      requiredFields.month = this.details.expMonth
      requiredFields.year = this.details.expYear

      requiredFields.email = this.details.email
      window.Spreedly.removeHandlers()
      try {
        window.Spreedly.tokenizeCreditCard(requiredFields)
        window.Spreedly.on('errors', errors => {
          const message = errors.map(e => e.message).join(' -- ')
          this.$emit('error', message)
        })
        window.Spreedly.on('paymentMethod', async (token, pmData) => {
          const additionalAddressDetails = this.details.useAccountAddress
            ? {}
            : { ...this.details.address }
          try {
            const response = await this.addPaymentMethod({
              accountId: this.accountDetails.id,
              details: {
                cardName: this.details.cardName,
                customerNameOnCard: pmData.full_name,
                email: pmData.email,
                token: pmData.token,
                creditCardDetails: {
                  lastFour: pmData.last_four_digits,
                  firstSix: pmData.first_six_digits,
                  cardType: pmData.card_type,
                  expMonth: pmData.month,
                  expYear: pmData.year,
                  maskedCC: pmData.number
                },
                paymentMethodTypeString: pmData.payment_method_type,
                address: {
                  ...additionalAddressDetails
                }
              }
            })

            if (response.id) {
              this.paymentMethodId = response.id
            }

            this.showModalWindow()
            
          } catch (error) {
            this.$emit('error', error.response.data?.title)
            showErrorMessage('Unable to add credit card')
          } finally {
            addErrorHandlers(this)
          }
        })
      } catch (error) {
        this.$emit('error', error.message)
      }
    }
  }
}

const initializeSpreedly = () => {
  window.Spreedly.removeHandlers()

  window.Spreedly.init(spreedlyToken, {
    numberEl: 'spreedly-number',
    cvvEl: 'spreedly-cvv'
  })

  window.Spreedly.on('ready', function() {
    window.Spreedly.setPlaceholder('number', 'Credit Card Number')
    window.Spreedly.setFieldType('number', 'text')
    window.Spreedly.setNumberFormat('prettyFormat')
    window.Spreedly.setStyle(
      'number',
      'color: #fff; height: 48px; font-family: Lato, Helvetica; font-size: 14px; font-weight: 700; letter-spacing: 0.2px; border-radius: 5px;'
    )
    window.Spreedly.setStyle(
      'cvv',
      'color: #fff; height: 48px; font-family: Lato, Helvetica; font-size: 14px; font-weight: 700; letter-spacing: 0.2px; border-radius: 5px;'
    )
    window.Spreedly.setPlaceholder('cvv', 'CVV')
  })
}

const addErrorHandlers = (component) => {
  window.Spreedly.on('fieldEvent', (name, type, activeEl, inputProperties) => {
    if (type === 'focus') {
      if (name === 'number') {
        component.numberTouched = true
      } else if (name === 'cvv') {
        component.cvvTouched = true
      }
    }

    if ((name === 'number' || name === 'cvv') && type === 'input') {
      component.validNumber = inputProperties.validNumber
      component.validCvv = inputProperties.validCvv
    }
  })

  window.Spreedly.on('errors', errors => {
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i]
      // eslint-disable-next-line no-console
      component.$notify({
        group: 'default',
        type: 'error',
        duration: 10000,
        title: error.message
      })
    }
  })
}

</script>

<style lang="scss" scoped></style>
