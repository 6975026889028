import { Crisp } from "crisp-sdk-web"

export function resetCrispSession() {
  Crisp.session.reset()
  customizeCrispChatbox()
}

export function customizeCrispChatbox() {
  Crisp.chat.hide()
  Crisp.setPosition("left")
}