<template>
  <div>
    <Panel>
      <template v-slot:header>
        <span data-cy="heading">Advance Pay</span>
      </template>

      <template v-slot:content>
        <p>To pay via Wire Transfer, click "Add Funds" and follow the downloadable payment instructions (PDF).</p>
        <b-modal
          id="create-advance-pay-modal"
          ref="create-advance-pay-modal"
          size="lg"
          :hide-footer="true"
          header-class="custom-modal"
          body-class="custom-modal"
          footer-class="custom-modal"
          title="Create Advance Pay Request"
          button-size="sm"
        >
          <validation-observer
            ref="formValidation"
            v-slot="{ handleSubmit }"
          >
            <b-form
              novalidate
              @submit.stop.prevent="handleSubmit(submitForm)"
            >
              <BaseFormInput
                :value="accountBalanceFormatted"
                :horizontal="true"
                :small="true"
                name="Current Balance"
                internal-platform-input
                label="Current Balance"
                type="text"
                width="5"
                width-lg="4"
                width-xl="3"
                required
                plaintext
                input-class="px-0"
              />

              <BaseCurrencyInput
                v-model="amountInput"
                :horizontal="true"
                internal-platform-input
                small
                name="Amount"
                label="Advance Amount"
                placeholder="Amount"
                :rules="amountValidation"
                required
                width="10"
                width-lg="2"
                width-xs="4"
                input-class="fixed-width-170"
              />
              
              <BaseButtonLoader
                horizontal
                :loading="isLoading"
                class="mt-4"
                button-class="custom-button--white-hover-grad pulse"
                type="submit"
                content-cols="9"
                @click="submitForm"
              >
                Submit
              </BaseButtonLoader>
            </b-form>
          </validation-observer>
        </b-modal>
        <div class="text-left">
          <BaseButtonLoader
            :loading="isLoading"
            class="ml-2"
            type="button"
            :custom-button="true"
            size="sm"
            variant="primary"
            @click="$bvModal.show('create-advance-pay-modal')"
          >
            Add Funds
          </BaseButtonLoader>
        </div>
      </template>
    </Panel>
    <Panel :hide-header="true">
      <template v-slot:content>
        <AdvancePayRequests :advance-pay-requests="advancePayRequests" />
      </template>
    </Panel>

    <b-modal
      id="advance-pay-instructions-modal"
      ref="advance-pay-instructions-modal"
      size="lg"
      :hide-footer="true"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Advance Pay Instructions"
      button-size="sm"
    >
      <h5>Request Summary</h5>

      <b-container
        fluid
      >
        <b-row class="my-2">
          <b-col sm="3">
            <label>Request Date</label>
          </b-col>
          <b-col sm="6">
            <span>{{ dateFormat(new Date(), 'MMM DD hh:mm:ss A') }}</span>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label>Amount</label>
          </b-col>
          <b-col sm="6">
            <h5>
              <b-badge
                variant="warning"
              >
                {{ amountFormatted }}
              </b-badge>
            </h5>
          </b-col>
        </b-row>
      </b-container>

      <h5>Transfer Details</h5>
      <p>Follow these instructions to make the advance payment. Please include the following payment reference when making the funds transfer payment</p>

      <b-container
        fluid
      >
        <b-row class="my-2">
          <b-col sm="3">
            <label>Payment Reference</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              v-model="paymentReference" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label for="transferDetailsBankName">Bank Name</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              id="transferDetailsBankName"
              v-model="systemAccountBankDetails.bankName" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label for="transferDetailsRoutingNumber">Account Holder</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              id="transferDetailsBankName"
              v-model="systemAccountBankDetails.beneficiaryName" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label for="transferDetailsRoutingNumber">Routing (Wire)</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              id="transferDetailsBankName"
              v-model="systemAccountBankDetails.routingNumberWire" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label for="transferDetailsRoutingNumber">Routing (ACH)</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              id="transferDetailsBankName"
              v-model="systemAccountBankDetails.routingNumberAch" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label for="transferDetailsRoutingNumber">Account Number</label>
          </b-col>
          <b-col sm="6">
            <b-form-input 
              id="transferDetailsBankName"
              v-model="systemAccountBankDetails.accountNumber" 
              class="form-control-plaintext"
              readonly 
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3">
            <label>Payment Instructions</label>
          </b-col>
          <b-col sm="6">
            <b-button
              variant="primary"
              size="sm"
              @click="getDownloadLink(paymentReference)"
            >
              Download (PDF)
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <hr>
      <p class="text-muted">
        If you have any questions, please do not hesitate to call us on (800) 525-0000 or contact us by x@ringba.com.
      </p>
    </b-modal>
  </div> 
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'
import dateFormatMixin from '@/mixins/dateFormatMixin'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import AdvancePayRequests from '@/components/buyer/AdvancePayRequests.vue'

export default {
  name: 'AdvancePay',

  title: 'Advance Pay',

  components: {
    AdvancePayRequests,
  },
  
  mixins: [dateFormatMixin],

  data() {
    return {
      isLoading: false,
      amount: undefined,
      amountFormatted: '',
      paymentReference: '',
      buyerMinimumAdvancePayAmount: 1000.00,
    }
  },

  computed: {
    ...mapState('billing', [
      'accountBalance'
    ]),
    ...mapState('dashboard', [
      'advancePayRequests',
      'systemAccountBankDetails'
    ]),

    ...mapGetters('billing', [
      'accountBillingConfiguration'
    ]),

    accountBalanceFormatted() {
      return formatCurrency(this.accountBalance.balance ?? 0)
    },

    amountValidation() {
      return `amount_range:${this.buyerMinimumAdvancePayAmount},999999.99`
    },

    amountInput: {
      get() {
        return this.amountFormatted
      },

      set(value) {
        this.amountFormatted = value
        this.amount = Number(value.replace(/[$,]/g, ''))
      }
    },
  },

  async created () {
    await Promise.all([
      this.getAdvancePayRequests(),
      this.getBuyerAccountBalance(),
      this.getSystemAccountBankDetails()
    ])
  },

  methods: {

    ...mapActions('billing', [
      'getBuyerAccountBalance',
    ]),

    ...mapActions('dashboard', [
      'getSystemAccountBankDetails',
      'getAdvancePayRequests',
      'createAdvancePaymentRequest',
      'generateInvoiceDownloadLink'
    ]),

    async createAdvancePay(){
      try {
        this.isLoading = true

        const advancePayRequest = {
          AmountUSD: this.amount
        }
        const createAdvancePayResult = await this.createAdvancePaymentRequest(advancePayRequest)
        this.paymentReference = createAdvancePayResult.id

        await this.getAdvancePayRequests()

      } catch (error) {
        showErrorMessage(error.response?.data?.title ?? error.message)
      } finally {
        setTimeout(() => {
          //defer to ensure the payment instructions are uploaded to the aws s3 bucket
          this.isLoading = false
          showSuccessMessage('Successfully created an advance payment request')

          this.$bvModal.hide('create-advance-pay-modal')
          this.$bvModal.show('advance-pay-instructions-modal')
        }, 3*1000)
      }
    },

    async submitForm() {     
      if (!this.amount){
        return
      }

      await this.createAdvancePay()
    },
    
    async getDownloadLink(paymentReference) {
      const downloadURL = await this.generateInvoiceDownloadLink({ invoiceId: paymentReference })
      window.open(downloadURL)
    },
  },

}
</script>

<style>
</style>