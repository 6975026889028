import BuyerAccountAPI from "@/api/buyer-account.api"
import CustomerServicesAPI from "@/api/customer-services.api"
import ESignAPI from "@/api/esign.api"
import {convertCurrencyStringToNumber} from  './campaign.store'

const state = {
  embeddedSigningSessionUrl: '',
  accountUsers: [],
}

const getters = {
  allowedTrafficSources: (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/accountDetails']?.allowedTrafficSources
  },

  allowedCategories: (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/accountDetails']?.allowedCategories
  },
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async createOnboardingDocsForSeller({ rootGetters, commit }, successRedirectURL) {
    const response = await ESignAPI.createOnboardingDocsForSeller(rootGetters['auth/accountDetails']?.id, successRedirectURL)

    commit('SET_EMBEDDED_SIGNING_SESSION_URL', response.data.embeddedSessionURL)

    return response.data
  },

  async getPendingDocumentBundleStatusForSeller({ rootGetters, commit, dispatch }) {
    const response = await ESignAPI.getPendingDocumentBundleStatusForSeller(rootGetters['auth/accountDetails']?.id)

    if (response.data.status === 'SHARED') {
      commit('SET_EMBEDDED_SIGNING_SESSION_URL', response.data.embeddedSessionURL)  
    } else if (response.data.status === 'EXECUTED') {
      await dispatch('auth/fetchUsersAccountDetails', null, { root: true })
    }

    return response.data
  },

  async createOnboardingDocsForBuyer({ rootGetters, commit }, successRedirectURL) {
    const response = await ESignAPI.createOnboardingDocsForBuyer(rootGetters['auth/accountDetails']?.id, successRedirectURL)

    commit('SET_EMBEDDED_SIGNING_SESSION_URL', response.data.embeddedSessionURL)

    return response.data
  },

  async getPendingDocumentBundleStatusForBuyer({ rootGetters, commit, dispatch }) {
    const response = await ESignAPI.getPendingDocumentBundleStatusForBuyer(rootGetters['auth/accountDetails']?.id)

    if (response.data.status === 'SHARED') {
      commit('SET_EMBEDDED_SIGNING_SESSION_URL', response.data.embeddedSessionURL)  
    } else if (response.data.status === 'EXECUTED') {
      await dispatch('auth/fetchUsersAccountDetails', null, { root: true })
    }

    return response.data
  },

  async updateBuyerAccountBudgetCap({ rootGetters, dispatch }, budget) {
    const budgetCopy = {
      ...budget,
      dailyBudgetInUSD: convertCurrencyStringToNumber(budget.dailyBudgetInUSD)
    }
    
    await BuyerAccountAPI.updateBuyerAccountBudgetCap(rootGetters['auth/accountId'], budgetCopy)

    await dispatch('auth/fetchUsersAccountDetails', null, { root: true })
  },

  async updateAccountTimezone({ rootGetters, dispatch }, timezone) {
    const timezoneCopy = {      
      timeZoneId: timezone
    }
    
    await CustomerServicesAPI.updateAccountTimezone(rootGetters['auth/accountId'], timezoneCopy)

    await dispatch('auth/fetchUsersAccountDetails', null, { root: true })
  },

  async getAccountUsers({ rootGetters, commit }) {
    const accountId = rootGetters['auth/accountDetails']?.id

    const response = await CustomerServicesAPI.getAccountUsers({accountId: accountId})
    commit('SET_ACCOUNT_USERS', response.data)

    return response.data
  },

  async createAccountUser({ rootGetters }, payload) {
    const accountId = rootGetters['auth/accountDetails']?.id

    const response = await CustomerServicesAPI.createAccountUser({accountId: accountId, userData: payload})
    return response.data
  },

  async removeAccountUser({ rootGetters, dispatch }, userId) {    
    const accountId = rootGetters['auth/accountDetails']?.id
    const response = await CustomerServicesAPI.removeAccountUser({accountId: accountId, userId: userId})
    console.log(response)

    if(response.status === 200){
      await dispatch('getAccountUsers')
    }

    return response.data
  },
}

const mutations = {
  SET_EMBEDDED_SIGNING_SESSION_URL(state, url) {
    state.embeddedSigningSessionUrl = url
  },

  SET_ACCOUNT_USERS(state, data) {
    state.accountUsers = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}  