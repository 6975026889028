<template>
  <div
    class="d-flex flex-column justify-content-center flex-grow-1 py-5 onboarding"
  >
    <div class="py-2">
      <b-container
        v-if="showBankForm"
        class="registration"
      >
        <BankInfoOnboarding
          class="mt-3"
          @success="onBankInfoSave"
          @cancel="manualShowBankForm = false"
        />
      </b-container>

      <b-container v-if="showESignForm">
        <SellerESignOnboarding
          :sign-pending="signPending"
          class="mt-3"
          @back="manualShowBankForm = true"
        />
      </b-container>

      <b-container
        v-if="successfullySignedDoc"
        class="d-flex align-items-center justify-content-center"
      >
        <h4
          v-if="!showSupportMessage"
          class="mb-0"
        >
          Please wait while your document is being submitted.
        </h4>

        <b-spinner
          v-if="!showSupportMessage"
          style="width: 1.2rem; height: 1.2rem;"
          class="ml-2"
        />

        <h4
          v-if="showSupportMessage"
          class="mb-0"
        >
          Please reach to support, your documents are under review.
        </h4>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import BankInfoOnboarding from '@/components/seller/BankInfoOnboarding.vue'
import SellerESignOnboarding from '@/components/esign/SellerESignOnboarding'
import { SELLER_ONBOARDING_STATUS } from '@/store/modules/auth.store'
import esignOnboardingMixin from '@/mixins/esign-onboarding-mixin'

export default {
  name: 'OnboardingSeller',

  title: 'Onboarding',


  components: {
    BankInfoOnboarding,
    SellerESignOnboarding,
  },

  mixins: [esignOnboardingMixin],
    
  props: {
    documentStatus: {
      type: String,
      default: ''
    },

    folderId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      intervalTimer: null,
      showSupportMessage: false,
      onboardingCompleteStatus: SELLER_ONBOARDING_STATUS.COMPLETED,

      manualShowBankForm: false
    }
  },

  computed: {
    showBankForm() {
      return this.accountDetails.onboardingStatus === SELLER_ONBOARDING_STATUS.PENDING || this.manualShowBankForm
    },

    showESignForm() {
      return !this.manualShowBankForm && !this.successfullySignedDoc && (this.accountDetails.onboardingStatus === SELLER_ONBOARDING_STATUS.BANK_INFO_SUBMITTED || this.signPending)
    },

    signPending() {
      return this.accountDetails.onboardingStatus === SELLER_ONBOARDING_STATUS.PENDING_DOCUMENT_SIGNING
    },
  },

  methods: {
    ...mapActions('account', {
      getPendingDocumentBundleStatusForAccount: 'getPendingDocumentBundleStatusForSeller'
    }),

    onBankInfoSave() {
      this.manualShowBankForm = false
    }
  },
}
</script>

<style lang="scss" scoped>

</style>