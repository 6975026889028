<template>
  <div>
    <Panel
      size="small"
      :hide-header="true"
    >
      <template v-slot:content>
        <div
          data-cy="container-dashboard-graph"
          class="mr-4"
        >
          <line-chart
            :chart-data="chartData"
            :options="options"
          />
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart'

const GRAPH_DEFAULT_FONT_COLOR = '#c3c4c4'

export default {
  name: 'DashboardGraph',
  components: {
    LineChart
  },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 2
          },
          line: {
            tension: 0
          }
        },
        legend: {
          position: 'bottom',
          align: 'middle',
          labels: {
            boxWidth: 15,
            usePointStyle: true,
            fontColor: GRAPH_DEFAULT_FONT_COLOR,
            padding: 20,
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM DD HH:mm'
                }
              },
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: GRAPH_DEFAULT_FONT_COLOR,
                padding: 5,

              },
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                zeroLineColor: GRAPH_DEFAULT_FONT_COLOR,
                color: GRAPH_DEFAULT_FONT_COLOR,
                drawBorder: false
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                fontColor: GRAPH_DEFAULT_FONT_COLOR,
                padding: 10,
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
