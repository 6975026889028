<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Add Funds</span>
    </template>

    <template v-slot:content>
      <BaseFormInput
        :value="accountBalanceFormatted"
        :horizontal="true"
        :small="true"
        name="Current Balance"
        internal-platform-input
        label="Current Balance"
        type="text"
        width="5"
        width-lg="4"
        width-xl="3"
        required
        plaintext
        input-class="px-0"
      />

      <BaseFormInput
        v-if="!isMinimumInitialDepositRequirementSatisfied"
        id="buyer-add-funds__minimum-initial-deposit"
        :value="minimumInitialDepositFormatted"
        :horizontal="true"
        :small="true"
        name="Minimum Initial Deposit"
        internal-platform-input
        label="Minimum Initial Deposit"
        type="text"
        width="5"
        width-lg="4"
        width-xl="3"
        required
        plaintext
        input-class="px-0"
        has-tooltip
      />

      <AddFundsCreditCardCharge @update-balance="handleSuccessfulAddBalance" />
    </template>
  </Panel>
</template>

<script>
import AddFundsCreditCardCharge from '@/components/billing/AddFundsCreditCardCharge.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import formatCurrency from '@/utils/formatCurrency'

export default {
  name: 'AddFunds',

  title: 'Add Funds',

  components: {
    AddFundsCreditCardCharge,
  },

  computed: {
    ...mapState('billing', [
      'accountBalance'
    ]),

    ...mapGetters('billing', [
      'accountBillingConfiguration'
    ]),

    ...mapGetters('auth', [
      'isMinimumInitialDepositRequirementSatisfied'
    ]),

    minimumInitialDepositFormatted() {
      return formatCurrency(this.accountBillingConfiguration?.minimumInitialDeposit ?? 0)
    },

    accountBalanceFormatted() {
      return formatCurrency(this.accountBalance.balance ?? 0)
    }
  },

  watch: {
    isMinimumInitialDepositRequirementSatisfied(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.hideDashboardNotification()
      }
    }
  },

  async created () {
    await this.getBuyerAccountBalance()
  },

  methods: {
    ...mapActions([
      'hideDashboardNotification',
    ]),

    ...mapActions('billing', [
      'getBuyerAccountBalance',
    ]),

    ...mapActions('auth', [
      'fetchCurrentAccountRequirements'
    ]),

    async handleSuccessfulAddBalance() {
      await this.getBuyerAccountBalance()
      await this.fetchCurrentAccountRequirements()
    }
  },
}
</script>

<style>

</style>