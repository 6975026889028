import { showSuccessMessage } from "@/notification-utils"

/**
 * Copy to clipboard
 * @param {Event} event 
 */
function handleCopyClick(event) {
  /**
   * @type {Element}
   */
  const currentTarget = event.currentTarget

  navigator.clipboard.writeText(currentTarget.getAttribute('data-copy-text')).then(() => {
    showSuccessMessage(currentTarget.getAttribute('data-copy-success') || 'Copied')
  }).catch((e) => {
    console.error('Unable to copy', e)
  })
}

export default {
  bind(element) {
    element.addEventListener('click', handleCopyClick)
  },

  update(element, { value, oldValue }) {
    if (oldValue && value !== oldValue) {
      element.removeEventListener('click', handleCopyClick)

      element.addEventListener('click', handleCopyClick)
    }
  },

  unbind(element) {
    element.removeEventListener('click', handleCopyClick)
  }
}