import { mapGetters } from 'vuex'

const CHECK_ACCOUNT_STATUS_INTERVAL = 15 * 1000

const RESET_INTERVAL_TIMEOUT = 60 * 1000

export default {
  data() {
    return {
      intervalTimer: null,
      showSupportMessage: false,

      // This will be defined in the child component
      onboardingCompleteStatus: ''
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountDetails', 
      'accountOnboardingStatus'
    ]),
    successfullySignedDoc() {
      return this.documentStatus === 'signing_success' && !!this.folderId
    }
  },

  async created() {
    if (this.successfullySignedDoc) {
      // This will be implemented in the child component
      await this.checkPendingDocumentStatus()
    }
  },

  destroyed() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
      this.intervalTimer = null
    }

    if (this.timeoutInterval) {
      clearTimeout(this.timeoutInterval)
      this.timeoutInterval = null
    }
  },

  methods: {
    async checkPendingDocumentStatus() {
      this.timeoutInterval = setTimeout(() => {
        if (this.accountOnboardingStatus !== this.onboardingCompleteStatus) {
          clearInterval(this.intervalTimer)
          this.intervalTimer = null

          this.showSupportMessage = true
        }
      }, RESET_INTERVAL_TIMEOUT)

      await this.checkAndRedirectToDashboard()

      this.intervalTimer = setInterval(this.checkAndRedirectToDashboard, CHECK_ACCOUNT_STATUS_INTERVAL)
    },

    async checkAndRedirectToDashboard() {
      await this.getPendingDocumentBundleStatusForAccount()

      if (this.accountOnboardingStatus === this.onboardingCompleteStatus) {
        clearInterval(this.intervalTimer)
        this.intervalTimer = null

        this.$router.push({
          name: 'Dashboard'
        })
      }
    }
  },
  
}