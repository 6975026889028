<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        'pr-4': true
      }"
      :label-cols-sm="horizontal ? '3' : null"
      label-cols="12"
      :description="$attrs.description"
      :class="formGroupClass"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :content-cols-xl="widthXl || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <template
        v-if="hasTooltip"
        slot="label"
      >
        <div>
          {{ $attrs.label }}
          <span
            v-b-tooltip.hover.right
            :title="fetchTooltip($attrs.id)"
          >
            <font-awesome-icon
              class="ml-1 mt-1"
              :icon="['fas', 'question-circle']"
            />
          </span>
        </div>
      </template>
      <b-form-select
        v-model="state"
        v-bind="$attrs"
        :size="$attrs.size"
        :disabled="$attrs.disabled || $attrs.readonly"
        :text-field="$attrs['text-field']"
        :value-field="$attrs['value-field']"
        :disabled-field="$attrs['not-enabled-field']"
        :class="{
          'custom-form-input--sm': small,
          'custom-form-input': !small,
        }"
        :state="getValidationState(validationContext)"
        :options="options"
      />
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormSelect',

  mixins: [formInputMixin],

  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: ''
    },

    options: {
      type: Array,
      default() {
        return []
      }
    },

    formGroupClass: {
      type: String,
      default: ''
    },

    small: {
      type: Boolean,
      default: false
    },

    hasTooltip: {
      type: Boolean,
      default: false
    }

  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    },
  }
}
</script>

<style></style>
