<template>
  <div class="d-flex flex-column mr-3">
    <DashboardFilters
      :auto-refresh="autoRefresh"
      :toggle-auto-refresh="TOGGLE_AUTO_REFRESH"
      :refresh="reloadData"
      :date-range="dateRange"
      :time-zone="timeZone"
      :max-date-range-in-days="90"
      @date-range-updated="handleDateRangeUpdate"
      @update-time-zone="handleTimeZoneUpdate"
    />

    <div
      class="row mt-4"
      style="margin: 0 10px;"
    >
      <div class="col-md-4 col-xl-2 col-lg-3 pl-0">
        <ReportingCardStats
          :icon="['fas', 'phone-volume']"
          :stats-number="totalItems"
          stats-title="Total Calls"
          icon-color="var(--neon-green-color)"
        />
      </div>

      <div class="col-md-4 col-xl-2 col-lg-3">
        <ReportingCardStats
          :icon="['fas', 'money-check-alt']"
          :stats-number="totalRevenue"
          is-stats-number-currency
          stats-title="Sold for"
          icon-color="var(--stats-blue-color)"
        />
      </div>
    </div>

    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Call Logs</span>
      </template>

      <template v-slot:content>
        <b-row>
          <b-table
            ref="callLogsTable"
            :fields="fields"
            data-cy="table-seller-call-logs"
            :items="loadSellerCallLogs"
            :busy.sync="isLoading"
            thead-class="text-nowrap"
            tbody-tr-class="text-nowrap"
            responsive
            :per-page="pageSize"
            :current-page="currentPage"
            caption-top
            sort-desc
            sort-by="timestamp"
            class="col-12"
          >
            <template #table-caption>
              <div class="ml-2">
                <BaseButtonLoader
                  v-if="totalItems > 0"
                  size="sm"                          
                  nowrap                  
                  :loading="isExportLoading"
                  variant="secondary"
                  @click="() => exportToFile()"
                >
                  Export CSV
                </BaseButtonLoader>
              </div>
            </template>

            <template #cell(timestamp)="row">
              <div class="text-nowrap">
                {{ dateFormat(row.item.timestamp, 'MMM DD hh:mm:ss A') }}
              </div>
            </template>   
            
            <template #cell(trafficSourceName)="row">
              <div class="text-nowrap">
                {{ row.item.trafficSourceName }} <small class="text-muted">{{ row.item.trafficSourceType }}</small>
              </div>
            </template>

            <template #cell(country)="row">
              <div class="text-nowrap">
                {{ row.item.country }}-{{ row.item.language }}
              </div>
            </template>

            <template #cell(callId)="row">
              <div class="d-flex align-items-center">
                <div
                  class="text-truncate fixed-width-150 mr-2"
                  :title="row.item.callId"
                >
                  {{ row.item.callId }}
                </div>
                
                <font-awesome-icon
                  role="button"
                  :icon="['fas', 'copy']"
                  @click="copyCallIdToClipboard(row.item.callId)"
                />
              </div>               
            </template>

            <template #cell(durationInSeconds)="row">
              <div class="text-nowrap">
                {{ secondsToDuration(row.item.durationInSeconds) }}
              </div>
            </template>

            <template #cell(payoutRequiredCallDuration)="row">
              <div class="text-nowrap">
                {{ secondsToDuration(row.item.payoutRequiredCallDuration) }}
              </div>
            </template>

            <template #cell(revenue)="row">
              <div class="text-nowrap">
                {{ formatCurrency(row.item.revenue) }}
              </div>
            </template>

            <template #cell(recordingUrl)="row">              
              <div>
                <button
                  v-if="row.item.recordingUrl !== null && row.item.recordingUrl !== undefined"
                  class="btn btn-outline-info btn-sm"
                  @click="openRecording(row.item.recordingUrl)"                  
                >
                  Play
                </button>                
              </div>
            </template>
          </b-table>
        </b-row>

        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            size="sm"
            :total-rows="totalItems"
            :per-page="pageSize"
          />
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import DashboardFilters from '@/components/DashboardFilters.vue'
import dateFormatMixin from '@/mixins/dateFormatMixin'
import callLogsMixin from '@/mixins/call-logs.mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import SellerCallLogsAPI from '@/api/seller-call-logs.api'
import ReportingCardStats from '@/components/common/ReportingCardStats.vue'

export default {
  name: 'SellerCallLogs',

  title: 'Call Logs',

  components: {
    DashboardFilters,
    ReportingCardStats
  },

  mixins: [dateFormatMixin, callLogsMixin, copyToClipboardMixin],

  data() {
    return {
      fields: Object.freeze([
        { key: 'timestamp', label: 'Date', sortable: true, sortDirection: 'desc' },
        { key: 'sellerOfferName', label: 'Offer' },
        { key: 'categoryName', label: 'Category' },
        { key: 'trafficSourceName', label: 'Traffic Source' },        
        { key: 'country', label: 'Locale' },
        { key: 'callerId', label: 'Caller ID' },       
        { key: 'durationInSeconds', label: 'Duration' },
        { key: 'payoutRequiredCallDuration', label: 'Required Duration' },
        { key: 'revenue', label: 'Amount' },
        { key: 'recordingUrl', label: 'Recording' },
        { key: 'callId', label: 'Call ID' },
      ]),
      totalRevenue: 0
    }
  },

  methods: {
    async loadSellerCallLogs(ctx) { // eslint-disable-line
      try {
        this.isLoading = true

        if (this.totalItems === 0) {
          await this.getTotalCalls()
        }

        const response = await SellerCallLogsAPI.getSellerCallLogs(this.accountId, {
          from: this.dateRange[0],
          to: this.dateRange[1],
          timeZone: this.timeZone,
          pageSize: ctx.perPage,
          pageNumber: ctx.currentPage
        })

        this.calls = response.data        

        return response.data
      } catch (error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to load call logs`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }
        this.calls = []
        return []
      } finally {
        this.isLoading = false
      }
    },

    async getTotalCalls() {
      const totalCallsresponse = await SellerCallLogsAPI.getSellerTotalCalls(this.accountId, {
        from: this.dateRange[0],
        to: this.dateRange[1],
        timeZone: this.timeZone
      })

      this.totalItems = totalCallsresponse.data.totalCallCount
      this.totalRevenue = totalCallsresponse.data.totalRevenue
    },

    openRecording(url) {
      window.open(url)
    },

    async exportToFile() {
      try {
        this.isExportLoading = true

        const exportResponse = await SellerCallLogsAPI.exportSellerCalls(this.accountId, {
          from: this.dateRange[0],
          to: this.dateRange[1],
          timeZone: this.timeZone
        })

        window.location.href = exportResponse.data.url
      }
      catch (error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to export calls to file`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }        
      }
      finally {
        this.isExportLoading = false      
      }
    },

    copyCallIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Call ID copied to clipboard')
    }

  }
}
</script>

<style lang="scss" scoped>

</style>