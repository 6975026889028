<template>
  <div>
    <b-badge
      v-if="status === 'Active'"
      variant="info"
      tag="div"
      class="w-100 py-1"
    >
      {{ status }}
      <font-awesome-icon
        v-if="!loading"
        class="ml-2"
        data-cy="icon-pause"
        :icon="['fas', 'pause']"
        :style="{ cursor: 'pointer' }"
        @click="() => updateCampaignStatus(campaignId, pauseCampaign)"
      />
      <b-spinner
        v-if="loading"
        class="ml-2"
        style="width: 0.6rem; height: 0.6rem;"
      />
    </b-badge>

    <b-badge
      v-if="status === 'Paused'"
      variant="warning"
      tag="div"
      class="w-100 py-1"
    >
      {{ status }}
      <font-awesome-icon
        v-if="!loading"
        data-cy="icon-play"
        class="ml-2"
        :icon="['fas', 'play']"
        :style="{ cursor: 'pointer' }"
        @click="() => updateCampaignStatus(campaignId, resumeCampaign)"
      />
      <b-spinner
        v-if="loading"
        class="ml-2"
        style="width: 0.6rem; height: 0.6rem;"
      />
    </b-badge>
  </div>
</template>

<script>

import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'CampaignStatusButton',
  props: {
    campaignId: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    pauseCampaign: {
      type: Function,
      default: () => {}
    },
    resumeCampaign: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async updateCampaignStatus(campaignId, updateFn) {
      this.loading = true
      try {
        await updateFn({ campaignId })
        showSuccessMessage('Updated campaign status')
      } catch (error) {
        // TODO: show actual error message from the API
        showErrorMessage('Failed to update campaign status')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
