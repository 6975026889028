<template>
  <footer class="footer">
    <div class="container footer__container flex-column flex-md-row">
      <a
        href="https://ringba.com/"
        target="_blank"
        class="footer__logo"
      >
        <RingbaLogo />
      </a>

      <nav class="footer-menu__wrap d-none d-xl-block">
        <ul class="footer-menu__list d-flex flex-column list-unstyled">
          <li class="footer-menu__item">
            <a
              href="http://status.ringba.com"
              target="_BLANK"
              class="footer-text text-uppercase footer-menu__item__link"
            >System Status</a>
          </li>
          <li class="footer-menu__item">
            <a
              href="https://www.ringba.com/terms"
              target="_BLANK"
              class="footer-text text-uppercase footer-menu__item__link"
            >Terms</a>
          </li>
          <li class="footer-menu__item">
            <a
              href="https://www.ringba.com/privacy"
              target="_BLANK"
              class="footer-text text-uppercase footer-menu__item__link"
            >Privacy</a>
          </li>
          <li class="footer-menu__item">
            <a
              href="https://www.ringba.com/abuse"
              target="_BLANK"
              class="footer-text text-uppercase footer-menu__item__link"
            >Abuse</a>
          </li>
        </ul>
      </nav>

      <div class="contact__wrap">
        <h4
          class="footer-text footer__muted-text text-uppercase d-none d-xl-block mb-2"
        >
          Call us
        </h4>
        <a
          href="tel:18005250000"
          class="call__link"
        >(800) 525-0000</a>
      </div>
    </div>

    <div class="container flex text-center pt-2 pb-3 footer-text opacity-50">
      Copyright &#169; {{ year }} Ringba Exchange Technologies, LLC - All rights reserved.
    </div>
  </footer>
</template>

<script>
import RingbaLogo from '@/assets/img/ringba-logo.svg'

export default {
  name: 'LayoutPublicFooter',
  components: {
    RingbaLogo,
  },

  computed: {
    year() {
      return new Date().getFullYear() 
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  z-index: 2;
  background: var(--component-layout-public__footer-bg);
}

.footer__container {
  display: flex;
  justify-content: space-between;
  height: $layout-public-footer-height;
  padding: 20px 0 0 0;
}

.footer__logo {
  width: 100%;
  max-width: 129px;
  padding-top: 8px;
}

.footer-menu__wrap {
  width: 230px;
  height: 100%;
  max-height: 205px;
  margin-left: -2px;
}

.footer-menu__list {
  height: 100%;
  align-content: space-between;
  flex-wrap: wrap;
  padding-top: 13px;
}

.footer-menu__item {
  margin-bottom: 16px;
}

.footer-text {
  color: #ffffff;
  font-family: 'Lato', 'Helvetica';
  font-size: 10px;
  font-weight: 750;
  letter-spacing: 1px;
  line-height: 12px;
}

.footer-menu__item__link {
  opacity: 0.5;
}

.contact__wrap,
.footer__social-wrap {
  padding-top: 17px;
}

.call__link {
  color: #ffffff;
  font-family: 'Lato', 'Helvetica';
  font-size: 14px;
  font-weight: 700;
}

.footer__muted-text {
  opacity: 0.4;
}

.footer__social-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #fff;
  opacity: 0.4;
  color: #000;
  margin-right: 10px;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

@include media-breakpoint-down(lg) {
  .contact__wrap,
  .footer__social-wrap {
    padding: 0px;
    margin: 0;
  }

  .footer__container {
    height: 100px;
    align-items: center;
  }

  .call__link {
    font-size: 20px;
    font-weight: 400;
  }

  .footer__social-item {
    width: 44px;
    height: 44px;
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer__container {
    min-height: 120px;
    height: auto;
    align-items: center;
  }
}
</style>