export default function (number, locale = 'en-US', minimumFractionDigits = 2, defaultEmpty = false) {
  if (!number && defaultEmpty) {
    return ''
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits
  }).format(number)
}

export function currencyFormatter(value, maxValue) {
  if (!value) {
    return ''
  }

  if (typeof value !== 'string') {
    value = value.toString()
  }

  // remove all characters except numbers, commas, decimal and dollar sign
  const cleanedUpString = value.replace(/[^$\d,.]/g, '')

  let numericString = cleanedUpString.replace('$', '').replace(/,/g, '')

  // if the next digit exceeds the max value, ignore it
  if (maxValue && numericString > maxValue) {
    numericString = numericString.slice(0, -1)
  }

  let [wholeNumber, decimalPart ] = numericString.split('.')

  // if the decimal part is longer than 2 digits, consider only first 2 digits
  decimalPart = decimalPart && decimalPart.slice(0, 2)

  const numericValue = Number(wholeNumber.replace('$', '').replace(/,/g, ''))
  let formatted = numericValue.toLocaleString('en-US')
  formatted = decimalPart !== undefined ? `$${formatted}.${decimalPart}` : `$${formatted}`
  return formatted
}