<template>
  <div class="d-flex flex-column mr-3">
    <DashboardFilters
      :from="defaultStartDate"
      :to="defaultEndDate"
      :auto-refresh="buyerAutoRefresh"
      :toggle-auto-refresh="TOGGLE_BUYER_AUTO_REFRESH"
      :refresh="reloadData"
      @dateRangeUpdated="reloadData"
    />
    <div>
      <b-row>
        <b-col md="3">
          <DashboardInsights
            v-if="Object.keys(buyerDashboardInsights).length > 0"
            :insights="buyerDashboardInsights"
            account-type="buyer"
          />
        </b-col>
        <b-col md="9">
          <DashboardGraph
            v-if="buyerDashboardGraphData.length > 0"
            :chart-data="chartData"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <BuyerDashboardCampaigns :campaigns="buyerDashboardCampaigns" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import DashboardFilters from '@/components/DashboardFilters.vue'
import DashboardInsights from '@/components/DashboardInsights.vue'
import DashboardGraph from '../../DashboardGraph.vue'
import BuyerDashboardCampaigns from './BuyerDashboardCampaigns.vue'
import { ACCOUNT_REQUIREMENTS, dashboardNotificationForAccountRequirementsMessages } from '@/store/modules/auth.store'

const AUTO_RELOAD_INTERVAL = 120 * 1000 // 2 mins interval

let autoReloadTimer

export default {
  name: 'BuyerDashboard',

  components: {
    DashboardFilters,
    DashboardInsights,
    DashboardGraph,
    BuyerDashboardCampaigns
  },

  computed: {
    ...mapState('dashboard', [
      'buyerDashboardGraphData',
      'buyerDashboardInsights',
      'buyerDashboardCampaigns',
      'buyerAutoRefresh'
    ]),

    ...mapGetters('auth', ['isMinimumInitialDepositRequirementSatisfied']),

    defaultStartDate() {
      const now = Date.now()
      return new Date(now - 7 * 24 * 60 * 60 * 1000)
    },
    defaultEndDate() {
      return new Date()
    },
    chartData() {
      const colors = ['#a1eb34', '#eb8f34', '#34ebe5', '#eb34e8', '#343deb']
      const datasets = this.buyerDashboardGraphData.map(campaign => {
        const color = colors.pop()
        return {
          type: 'line',
          tension: 0.1,
          borderWidth: 1.5,
          borderColor: color,
          label: campaign.name,
          pointBackgroundColor: color,
          fill: false,
          data: campaign.data.map(entry => {
            return {
              x: entry.timestamp,
              y: entry.value
            }
          })
        }
      })
      return {
        datasets
      }
    }
  },
  watch: {
    buyerAutoRefresh (newValue) {
      if (newValue) {
        autoReloadTimer = setInterval(async () => {
          await Promise.all([
            this.getBuyerDashboardData({
              from: this.defaultStartDate,
              to: this.defaultEndDate
            }),
            this.getBuyerCampaigns()
          ])
        }, AUTO_RELOAD_INTERVAL)
      } else {
        this.clearAutoReloadInterval()
      }
    }
  },
  async created() {
    if (!this.isMinimumInitialDepositRequirementSatisfied) {
      this.showDashboardNotification(dashboardNotificationForAccountRequirementsMessages[ACCOUNT_REQUIREMENTS.MinimumInitialDeposit])
    }

    await Promise.all([
      this.getBuyerDashboardData({
        from: this.defaultStartDate,
        to: this.defaultEndDate
      }),
      this.getBuyerCampaigns()
    ])
  },
  methods: {
    ...mapActions([
      'showDashboardNotification'
    ]),
    ...mapActions('dashboard', ['getBuyerDashboardData', 'getBuyerCampaigns']),

    ...mapMutations('dashboard', ['TOGGLE_BUYER_AUTO_REFRESH']),

    reloadData({ from, to }) {
      return this.getBuyerDashboardData({ from, to })
    },

    clearAutoReloadInterval() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
        autoReloadTimer = null
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
