<template>
  <div>
    <BaseFormSelect
      id="buyer-create-campaign__destination__parsing-type"
      v-model="parserType"
      :options="RESPONSE_PARSER_TYPES"
      name="Parsing Type"
      label="Parsing Type"
      internal-platform-input
      horizontal
      small
      required
      width="3"
      width-xl="2"
      has-tooltip
    />

    <BaseFormTextArea
      id="buyer-create-campaign__destination__parsing-expression"
      v-model="parsingValue"
      name="Parsing Expression"
      label="Parsing Expression"
      internal-platform-input
      horizontal
      small
      required
      width="7"
      width-xs="12"
      width-lg="4"
      rows="10"
      custom-input-class="text-monospace font-weight-light"
      :debounce="500"
      has-tooltip
    >
      <template
        v-if="parserType.toLowerCase() === 'javascript'"
        v-slot:preInput
      >
        <code class="code--custom">
          // `input` is the response from the request
          <br>
          function(input) {
        </code>
      </template>

      <template
        v-if="parserType.toLowerCase() === 'javascript'"
        v-slot:postInput
      >
        <code class="code--custom">
          }
        </code>
      </template>
    </BaseFormTextArea>
  </div>
</template>

<script>
const RESPONSE_PARSER_TYPES = [{
  text: 'JPath',
  value: 'jpath'
},
{
  text: 'XPath',
  value: 'xpath'
},
{
  text: 'Regular Expression',
  value: 'regex'
},
{
  text: 'JavaScript',
  value: 'javascript'
}]


export default {
  name: 'ProgrammaticTargetParserForm',

  props: {
    parser: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      RESPONSE_PARSER_TYPES: Object.freeze(RESPONSE_PARSER_TYPES),
    }
  },

  computed: {
    parserType: {
      get() {
        return this.parser.parserType
      },

      set(value) {
        this.$emit('update', { ...this.parser, parserType: value})
      }
    },

    parsingValue: {
      get() {
        return this.parser.value
      },

      set(value) {
        this.$emit('update', { ...this.parser, value})
      }
    }
  },
}
</script>

<style>

</style>