<template>
  <b-form-group
    :label-class="{
      'custom-form-input-label--internal': internalPlatformInput,
      'custom-form-input-label': !internalPlatformInput,
      'pr-4': true
    }"
    :label="label"
    :label-cols-sm="horizontal ? '3' : null"
    label-cols="12"
    :label-align-sm="horizontal ? 'right' : ''"
    label-align="left"
    :content-cols-sm="horizontal ? '8' : null"
  >
    <template
      v-if="hasTooltip"
      slot="label"
    >
      <div>
        {{ label }}
        <span
          v-b-tooltip.hover.right
          :title="fetchTooltip($attrs.id)"
        >

          <font-awesome-icon
            class="ml-1 mt-1"
            :icon="['fas', 'question-circle']"
          />
        </span>
      </div>
    </template>

    <b-button-group>
      <b-button
        v-for="(option, $idx) in options"
        :key="$idx"
        :pressed="isOptionPressed(option)"
        variant="primary"
        size="sm"
        :class="getOptionClasses(option)" 
        :disabled="getDisabled(option)"
        @click="onClick(option)"
      >
        {{ optionDisplayFormatter(option) }}
      </b-button>
    </b-button-group>
  </b-form-group>
</template>

<script>

import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseButtonGroupHorizontal',

  mixins: [formInputMixin],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    disabledOptions: {
      type: Array,
      default: () => []
    },
    optionsClasses: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    selectedOption: {
      type: [String, Boolean],
      default: ''
    },
    internalPlatformInput: {
      type: Boolean,
      default: false
    },
    clickHandler: {
      type: Function,
      default: () => {}
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
  }, 

  methods: {
    onClick(option) {
      if (option.value != null) {
        this.clickHandler(option.value)
        return
      }

      this.clickHandler(option)
    },

    optionDisplayFormatter(option) {
      return option.text || option
    },

    getOptionClasses(option) {      
      let allOptionClasses = "base-button-group "

      if (this.optionsClasses.some(item => item.name === option)) {
        allOptionClasses = allOptionClasses + this.optionsClasses.find(item => item.name === option).additionalClasses             
      }
      
      return allOptionClasses
    },

    getDisabled(option) {   
      if (option.value) {
        return this.disabledOptions.some(item => item === option.value)
      }

      return this.disabledOptions.some(item => item === option)
    },

    isOptionPressed(option) {
      if (option.value != null) {
        return option.value === this.selectedOption
      }

      return option === this.selectedOption
    }
  },
}
</script>

<style lang="scss">
</style>