export function retryUntil(callingFunction, checkingFunction, noOfTimes = 2, interval = 500) {
  let runTimes = 0

  return async function retry() {
    runTimes++

    if (runTimes > noOfTimes) {
      return
    }

    try {
      await callingFunction()
    } catch {
      setTimeout(retry, interval)
      return
    }
    
    const check = checkingFunction()
  
    if (!check) {
      setTimeout(retry, interval)
    } else if (check) {
      return
    }
  }
}