<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Campaigns</span>
    </template>

    <template v-slot:content>
      <b-table
        :fields="fields"
        data-cy="table-buyer-dashboard-campaigns"
        :items="campaigns"
        thead-class="text-nowrap"
        tbody-tr-class="text-nowrap"
      >
        <template #cell(spent)="row">
          <div class="text-nowrap">
            {{ `$${row.item.spent.toLocaleString()}` }}
          </div>
        </template>

        <template #cell(conversionPercentage)="row">
          <div class="text-nowrap">
            {{ `${row.item.conversionPercentage.toFixed(2)} %` }}
          </div>
        </template>

        <template #cell(qualityScore)="row">
          <div class="text-nowrap">
            {{ `${row.item.qualityScore.toFixed(2)} / 10` }}
          </div>
        </template>

        <template #cell(winRatio)="row">
          <div class="text-nowrap">
            {{ `${row.item.winRatio.toFixed(2)} %` }}
          </div>
        </template>

        <template #cell(avgBid)="row">
          <div class="text-nowrap">
            {{ `$${row.item.avgBid.toLocaleString()}` }}
          </div>
        </template>

        <template #cell(bid)="row">
          <CampaignBidInput
            :initial-value="row.item.avgBid"
            :campaign-id="row.item.id"
          />
        </template>

        <template #cell(status)="row">
          <div class="text-nowrap">
            <CampaignStatusButton
              :campaign-id="row.item.id"
              :status="row.item.status"
              :pause-campaign="pauseBuyerCampaign"
              :resume-campaign="resumeBuyerCampaign"
            />
          </div>
        </template>
      </b-table>
    </template>
  </Panel>
</template>

<script>
import CampaignStatusButton from '@/components/CampaignStatusButton.vue'
import CampaignBidInput from './CampaignBidInput'
import { mapActions } from 'vuex'

export default {
  name: 'BuyerDashboardCampaigns',
  components: {
    CampaignStatusButton,
    CampaignBidInput
  },
  props: {
    campaigns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          label: 'Status',
          tdAttr: (value) => {
            return {
              'data-cy': 'status-badge',
              'data-cy-status': value
            }
          },
          sortable: true
        },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'calls', label: 'Calls', sortable: true, tdClass: 'text-right' },
        { key: 'billable', label: 'Billable', sortable: true, tdClass: 'text-right' },
        { key: 'spent', label: 'Spent', sortable: true, tdClass: 'text-right' },
        { key: 'conversionPercentage', label: 'Conv. %', sortable: true, tdClass: 'text-right' },
        { key: 'qualityScore', label: 'Quality Score', sortable: true, tdClass: 'text-right' },
        { key: 'winRatio', label: 'Win Ratio', sortable: true, tdClass: 'text-right' },
        { key: 'avgBid', label: 'Average Bid', sortable: true, tdClass: 'text-right' },
        { key: 'bid', label: 'Bid', sortable: true },
        { key: 'bidLevel', label: 'Bid Level', sortable: true },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },
  methods: {
    ...mapActions('campaign', ['pauseBuyerCampaign', 'resumeBuyerCampaign'])
  }
}
</script>

<style lang="scss" scoped></style>
