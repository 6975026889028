<template>
  <div>
    <BaseButtonGroup
      id="buyer-create-campaign__destination__routing-type"
      :horizontal="true"
      internal-platform-input
      data-cy="button-group-type"
      :selected-option="destinationType"
      :click-handler="updateDestinationType"
      label="Type"
      :options="['Number', 'SIP']"
      has-tooltip
    />

    <BaseFormInput
      v-if="destinationType === 'Number'"
      id="buyer-create-campaign__destination__phone-number"
      v-model="number"
      input-class="fixed-width-170"
      data-cy="input-number"
      internal-platform-input
      :horizontal="true"
      :small="true"
      name="Number"
      placeholder="Phone Number"
      label="Number"
      rules="us_phone"
      type="tel"
      required
      :formatter="phoneNumberFormatter"
      width="5"
      width-lg="3"
      :debounce="500"
      has-tooltip
    />

    <div v-if="destinationType === 'SIP'">
      <BaseFormInput
        id="buyer-create-campaign__destination__sip-endpoint"
        v-model="sipEndpoint"
        data-cy="input-sip-endpoint"
        internal-platform-input
        :horizontal="true"
        :small="true"
        name="SIP Endpoint"
        placeholder="SIP Endpoint"
        label="SIP Endpoint"
        type="text"
        required
        width="5"
        width-lg="3"
        :debounce="500"
        has-tooltip
      />

      <BaseFormInput
        id="buyer-create-campaign__destination__sip-username"
        v-model="sipUsername"
        data-cy="input-sip-username"
        internal-platform-input
        :horizontal="true"
        :small="true"
        name="SIP Username"
        placeholder="SIP Username"
        label="SIP Username"
        type="text"
        :rules="Boolean(sipPassword) ? 'required': ''"
        width="5"
        width-lg="3"
        :debounce="500"
        has-tooltip
        autocomplete="off"
      />

      <BaseFormInput
        id="buyer-create-campaign__destination__sip-password"
        v-model="sipPassword"
        data-cy="input-sip-password"
        internal-platform-input
        :horizontal="true"
        :small="true"
        name="SIP Password"
        placeholder="SIP Password"
        label="SIP Password"
        type="password"
        :rules="Boolean(sipUsername) ? 'required': ''"
        width="5"
        width-lg="3"
        :debounce="500"
        has-tooltip
        autocomplete="new-password"
      />
    </div>
  </div>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'TargetDestinationForm',

  mixins: [formInputMixin],

  props: {
    targetDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      targetDetailsCopy: {}
    }
  },

  computed: {
    destinationType: {
      get() {
        return this.targetDetailsCopy.destinationType
      },
      set(value) {
        this.targetDetailsCopy.destinationType = value
        this.$emit('update', this.targetDetailsCopy)
      }
    },

    number: {
      get() {
        return this.targetDetailsCopy.number
      },
      set(value) {
        this.targetDetailsCopy.number = value
        this.$emit('update', this.targetDetailsCopy)
      }
    },
    sipEndpoint: {
      get() {
        return this.targetDetailsCopy.sipNumber.endpoint
      },
      set(value) {
        this.targetDetailsCopy.sipNumber.endpoint = value
        this.$emit('update', this.targetDetailsCopy)
      }
    },
    sipUsername: {
      get() {
        return this.targetDetailsCopy.sipNumber.username
      },
      set(value) {
        this.targetDetailsCopy.sipNumber.username = value
        this.$emit('update', this.targetDetailsCopy)
      }
    },
    sipPassword: {
      get() {
        return this.targetDetailsCopy.sipNumber.password
      },
      set(value) {
        this.targetDetailsCopy.sipNumber.password = value
        this.$emit('update', this.targetDetailsCopy)
      }
    }
  },

  watch: {
    targetDetails: {
      immediate: true,
      handler(newValue) {
        this.targetDetailsCopy = {
          ...newValue,
          sipNumber: {
            ...newValue.sipNumber
          }
        }
      }
    }
  },

  methods: {
    updateDestinationType(option) {
      this.destinationType = option
    },
  },
}
</script>

<style>

</style>