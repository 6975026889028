<template>
  <div>
    <div v-if="showBackToBankInfo">
      <button
        class="btn btn-link bg-transparent btn-sm onboarding__back-btn"
        type="button"
        @click="$emit('back')"
      >
        <font-awesome-icon
          :icon="['fas', 'angle-left']"
          class="mr-2"
        />
        Change Bank Info
      </button>
    </div>

    <div
      v-if="showLoader && !showErrorTitle"
      class="d-flex align-items-center justify-content-center"
    >
      <h4 class="mb-0">
        Preparing your Exchange Agreement
      </h4>
      <b-spinner
        style="width: 1.2rem; height: 1.2rem;"
        class="ml-2"
      />
    </div>

    <div
      v-if="showErrorTitle"
      class="d-flex align-items-center justify-content-center"
    >
      <h4 class="mb-0 text-danger">
        <em>{{ errorTitle }}</em>
      </h4>
    </div>

    <iframe
      v-if="!creatingSigningSessionInProgress && !checkingDocumentPendingStatus && !showErrorTitle"
      id="esignIframe"
      :src="embeddedSigningSessionUrl"
      style="width: 100%; height: 100vh;"
      :style="loadingIFrame ? 'visibility:hidden;position: fixed; right: -5000px;' : 'visibility:visible;'"
      frameborder="0"
      @load="onLoadIFrame"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'BaseESignOnboarding',

  title: 'Sign Contract',

  props: {
    signPending: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      creatingSigningSessionInProgress: false,
      checkingDocumentPendingStatus: false,
      loadingIFrame: false,
      showErrorTitle: false,
      errorTitle: ''
    }
  },

  computed: {
    ...mapState('account', [
      'embeddedSigningSessionUrl'
    ]),

    ...mapGetters('auth', [
      'isSellerAccount'
    ]),

    showLoader() {
      return (!this.checkingDocumentPendingStatus && (this.creatingSigningSessionInProgress || this.loadingIFrame)) || (this.signPending && this.checkingDocumentPendingStatus)
    },

    showBackToBankInfo() {
      return this.isSellerAccount && !this.loadingIFrame && !this.creatingSigningSessionInProgress && !this.checkingDocumentPendingStatus && !this.showErrorTitle
    }
  },

  async created () {
    if (this.signPending) {
      await this.getPendingDocumentStatus()
    } else {
      await this.createSessionURL()
    }
  },

  methods: {
    ...mapActions('auth', [
      'fetchUsersAccountDetails',
    ]),

    async getPendingDocumentStatus() {
      try {
        this.showErrorTitle = false
        this.checkingDocumentPendingStatus = true

        // This will be implemented in the child component
        await this.getPendingDocumentBundleStatusForAccount()

        if (this.embeddedSigningSessionUrl) {
          this.loadingIFrame = true
        }
      } catch (error) {
        this.showErrorTitle = true
        this.errorTitle = error.response?.data?.title || 'Unable to fetch agreement'
      } finally {
        this.checkingDocumentPendingStatus = false
      }
    },

    async createSessionURL() {
      try {
        this.showErrorTitle = false
        this.creatingSigningSessionInProgress = true
  
        // This will be implemented in the child component
        await this.createOnboardingDocsForAccount(`${window.location.origin}${this.$router.history.current.path}`)

        await this.fetchUsersAccountDetails()
        
        this.loadingIFrame = true
      } catch (error) {
        this.showErrorTitle = true
        this.errorTitle = error.response?.data?.title || 'Unable to fetch agreement'
      } finally {
        this.creatingSigningSessionInProgress = false
      }
    },

    onLoadIFrame() {
      this.loadingIFrame = false
    }
  },
})
</script>

<style lang="scss" scoped>
.onboarding__back-btn {
  font-size: 1.1rem;
}
</style>