function setKeyInMap(map, key, obj, overrideExistingCondition) {
  const existingObjectInMap = map.get(key)

  if (overrideExistingCondition && existingObjectInMap) {
    if (overrideExistingCondition(existingObjectInMap, obj)) {
      map.set(key, { ...obj })
    }
  } else {
    map.set(key, { ...obj })
  }

  return map
}

/**
 * Merge array 2 with array 1
 * @param {Array<Object>} arr1 
 * @param {Array<Object>} arr2 
 * @param {Function} customKey1 
 * @param {Function?} customKey2 
 * @param {Function?} overrideExistingCondition 
 * @returns Array<Object>
 */
export function mergeArraysOfObjects(arr1, arr2, customKey1, customKey2, overrideExistingCondition, mergeAllObjectsFromArr2 = false) {
  if (!customKey2) {
    customKey2 = customKey1
  }

  /**
   * @type {Map<string | number, Object>}
   */
  const arr1Map = arr1.reduce((map, o) => {
    const key = customKey1(o)

    return setKeyInMap(map, key, o, overrideExistingCondition)
  }, new Map())

  /**
   * @type {Map<string | number, Object>}
   */
  const arr2Map = arr2.reduce((map, o) => {
    const key = customKey2(o)

    return setKeyInMap(map, key, o, overrideExistingCondition)
  }, new Map())

  for (const [key, value] of arr1Map) {
    arr1Map.set(key, Object.assign(value, arr2Map.get(key) || {}))
  }

  if (mergeAllObjectsFromArr2) {
    for (const [key, value] of arr2Map) {
      if (!arr1Map.get(key)) {
        arr1Map.set(key, {
          ...value
        })
      }
    } 
  }

  return Array.from(arr1Map.values())
}