<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-checkbox
      v-model="state"
      :value="checkedValue"
      v-bind="$attrs"
      :unchecked-value="uncheckedValue"
      :state="getValidationState(validationContext)"
      class="custom-form-checkbox"
    >
      <slot />
    </b-form-checkbox>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormCheckbox',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object],
      required: true
    },

    checkedValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },

    uncheckedValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400,
    },

    rules: {
      type: [String, Array],
      default: ''
    },

    requireChecked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    isCheckboxTickedRequirement() {
      return this.requireChecked ? !!this.state : true
    }
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid && this.isCheckboxTickedRequirement) : null
    },
  },

}
</script>

<style>
</style>