<template>
  <div>
    <div
      v-if="biddingDetails.status === 'Ready'"
      class="accordion"
    >
      <h5>Required Parameters <span class="small text-muted">must be added to a bid request</span></h5>

      <SellerOfferBidParameters
        class="mb-5"
        :parameters="biddingDetails.parameters.filter(p => p.required)"
      />

      <h5>Optional Parameters <span class="small text-muted">can be omitted in a bid request</span></h5>

      <SellerOfferBidParameters
        class="mb-5"
        :parameters="biddingDetails.parameters.filter(p => !p.required)"
      />

      <h5>Code Snippets</h5>

      <!-- GET Method -->
      <b-card
        no-body
        class="mb-3"
      >
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          header-bg-variant="dark"
        >
          <div
            v-b-toggle.get-code
            class="ml-2 d-flex align-items-center"
          >
            <strong>Option 1 - GET</strong>
        
            <font-awesome-icon
              class="ml-auto mr-1"
              :icon="codeSnippetAccordion['get'] ? ['fas', 'angle-down'] : ['fas', 'angle-right']"
            />
          </div>
        </b-card-header>
        <b-collapse
          id="get-code"
          v-model="codeSnippetAccordion['get']"
          visible
          accordion="codeSnippet"
          role="tabpanel"
        >
          <b-card-body body-bg-variant="dark">
            Send a GET request to the following URL.

            <div class="mt-3">
              <h6>
                URL - For JSON response
              </h6>
              <CodeContainer :code-text="getMethodJSONUrl" />
            </div>

            <div class="mt-2">
              <h6>
                URL - For XML response
              </h6>
              <CodeContainer :code-text="getMethodXMLUrl" />
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- POST Method - JSON -->
      <b-card
        no-body
        class="mb-3"
      >
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          header-bg-variant="dark"
        >
          <div
            v-b-toggle.json-code
            class="ml-2 d-flex align-items-center"
          >
            <strong>Option 1 - JSON - POST</strong>
        
            <font-awesome-icon
              class="ml-auto mr-1"
              :icon="codeSnippetAccordion['json'] ? ['fas', 'angle-down'] : ['fas', 'angle-right']"
            />
          </div>
        </b-card-header>
        <b-collapse
          id="json-code"
          v-model="codeSnippetAccordion['json']"
          accordion="codeSnippet"
          role="tabpanel"
        >
          <b-card-body body-bg-variant="dark">
            <SellerBidCodeSnippet
              :url="biddingDetails.json"
              header="Content-Type: application/json"
              :parameters="jsonParameters"
            />
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- POST Method - XML -->
      <b-card
        no-body
      >
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          header-bg-variant="dark"
        >
          <div
            v-b-toggle.xml-code
            class="ml-2 d-flex align-items-center"
          >
            <strong>Option 1 - XML - POST</strong>
        
            <font-awesome-icon
              class="ml-auto mr-1"
              :icon="codeSnippetAccordion['xml'] ? ['fas', 'angle-down'] : ['fas', 'angle-right']"
            />
          </div>
        </b-card-header>
        <b-collapse
          id="xml-code"
          v-model="codeSnippetAccordion['xml']"
          visible
          accordion="codeSnippet"
          role="tabpanel"
        >
          <b-card-body body-bg-variant="dark">
            <SellerBidCodeSnippet
              :url="biddingDetails.xml"
              header="Content-Type: application/xml"
              :parameters="xmlParameters"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <div v-else-if="biddingDetails.status === 'NotApproved'">
      Offer application not approved.
    </div>

    <div v-else-if="biddingDetails.status === 'NotAvailableYet'">
      Bidding URL not available yet, please get in touch with support.
    </div>
  </div>
</template>

<script>
import SellerOfferBidParameters from './SellerOfferBidParameters.vue'
import SellerBidCodeSnippet from './SellerBidCodeSnippet.vue'

function getParamValue(param) {
  if (param.name === 'cid') {
    return '14061571951'
  } 

  if (param.choices?.length) {
    return param.choices[0]
  }  
}

export default {
  name: 'SellerOfferBiddingDetails',

  components: {
    SellerOfferBidParameters,
    SellerBidCodeSnippet
  },

  props: {
    /**
     * @type {import('vue').PropType<Seller.OfferBiddingURLDetails>}
     */
    biddingDetails: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      codeSnippetAccordion: {
        get: true
      }
    }
  },

  computed: {
    jsonParameters() {
      return JSON.stringify(this.biddingDetails?.parameters?.filter(p => p.required).reduce((finalParamsJson, param) => {
        finalParamsJson[param.name] = getParamValue(param)

        return finalParamsJson
      }, {}) || {}, null, 2)
    },

    xmlParameters() {
      let xmlParameters = '<bid_request>\n'

      xmlParameters = this.biddingDetails?.parameters?.filter(p => p.required).reduce((finalParamsFormatted, param) => {
        finalParamsFormatted += `\t<${param.name}>${getParamValue(param)}</${param.name}>\n`
        return finalParamsFormatted
      }, xmlParameters)

      xmlParameters += `</bid_request>`

      return xmlParameters
    },

    getMethodJSONUrl() {
      return `${this.biddingDetails.json}?${this.queryParameters}`
    },

    getMethodXMLUrl() {
      return `${this.biddingDetails.xml}?${this.queryParameters}`
    },

    queryParameters() {
      return this.biddingDetails?.parameters?.filter(p => p.required).reduce((finalQueryParams, param, idx) => {
        finalQueryParams += `${param.name}=${getParamValue(param)}`
        
        if (idx !== this.biddingDetails?.parameters.length - 1) {
          finalQueryParams += '&'
        }

        return finalQueryParams
      }, '')
    }
  },
}
</script>

<style lang="scss" scoped>

</style>