<template>
  <div>
    <b-button
      v-if="showAddButton"
      type="button"
      size="sm"
      @click="addInput"
    >
      {{ addText }}
    </b-button>

    <div
      v-if="!showAddButton"
    >
      <div
        v-for="(model, $idx) in list"
        :key="$idx"
        class="form-row mb-4"
      >
        <div class="col-5 ">
          <validation-provider
            v-slot="validationContext"
            name="File"
            rules="required"
          >
            <b-form-file
              v-model="list[$idx].file"
              :accept="accept"
              name="File"
              size="sm"
              placeholder="Choose file"             
              :disabled="uploadBlocked"
              :state="getValidationState({validationContext : validationContext, index: $idx})"              
              @input="fileSelected($idx)"
            />

            <div class="invalid-feedback">
              {{ validationContext.errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="align-self-start mb-2">
          <b-button          
            type="button"
            class="ml-2 bg-transparent border-0"
            :disabled="removalBlocked($idx)"
            @click="removeInput($idx)"
          >
            <font-awesome-icon
              :icon="['fas', 'minus-circle']"
            />
          </b-button>
          <b-button
            v-if="$idx === (list.length - 1)"
            type="button"
            class="ml-2 bg-transparent border-0"
            @click="addInput"
          >
            <font-awesome-icon
              :icon="['fas', 'plus-circle']"
            />
          </b-button>
          <b-button
            v-if="list[$idx] && list[$idx].error"       
            type="button"
            class="ml-2 bg-transparent border-0"            
            @click="retryUpload($idx)"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
            />
          </b-button>      
        </div>
        <div 
          v-if="list[$idx].progress > 0 && list[$idx].progress < 100 && !list[$idx].uploaded"
          class="col-5 col-sm-4"
        >
          <b-progress           
            :value="list[$idx].progress" 
            class="mt-2"
            :max="100"            
            animated
          />
          File size {{ ((list[$idx].file.size / 1024) / 1024).toFixed(2) }} MB
          <div
            v-if="list[$idx].error"
          >
            <small class="text-danger">
              <p>
                Upload failed <br>
                {{ list[$idx].error }}
              </p>
            </small>
          </div>  
        </div>            
      </div>
    </div>
  </div>
</template>

<script>

import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'FileUploadList',

  mixins: [formInputMixin],

  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },

    accept: {
      type: String,
      default: null
    },

    addText: {
      type: String,
      default: 'Add'
    },  
  },

  data() {
    return {
      addButtonClicked: false,
      listCopyWithIndex: {}
    }
  },

  computed: {
    list: {
      get() {
        return this.value
      },

      set(value) {        
        this.$emit('input', value)
      }
    },

    showAddButton() {
      return !this.list.length || !this.addButtonClicked
    },

    uploadBlocked(){
      return this.list.filter(x => x.progress > 0 && x.progress < 100).length > 4
    },    
  },

  methods: {   

    addInput() {
      this.addButtonClicked = true

      this.list = [...this.list, {
        fileId: "",
        fileName: "",
        file: null,
        uploaded: false,
        progress: 0,
        error: ""
      }]
    },

    removeInput(idx) {
      const removedFile = { uploaded: this.list[idx].uploaded, fileId: this.list[idx].fileId }

      const tempList = [...this.list]
      tempList.splice(idx, 1)
      this.list = tempList

      if (removedFile.uploaded && removedFile.fileId) {
        this.$emit('fileRemoved', removedFile.fileId)
      }
    },

    getValidationState({ validationContext, index } ) {       
      return (validationContext.validated ? validationContext.valid : null) && this.list[index].uploaded && !this.list[index].error      
    },

    fileSelected(index){
      if (this.list[index]?.uploaded && this.list[index].fileId) {
        this.$emit('fileRemoved', this.list[index].fileId)
      }

      if (this.list[index]?.file != null) {
        this.list[index].fileName = this.list[index].file.name     
        this.list[index].progress = 0
        this.list[index].uploaded = false

        this.$emit('fileSelected', {
          list: this.list,
          index: index
        })
      }
    },

    removalBlocked(index){
      return this.list[index] && this.list[index].progress > 0 && this.list[index].progress < 100 && !this.list[index].error
    },

    retryUpload(index){
      this.list[index].error = ""
      this.list[index].progress = 0
      this.list[index].uploaded = false

      this.$emit('fileSelected', {
        list: this.list,
        index: index
      })
    },

    

  },
}
</script>

<style>

</style>