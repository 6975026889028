<template>
  <div class="mb-4">
    <div
      v-for="(parser, $idx) in parsersCopy"
      :key="$idx"
    >
      <div
        v-if="$idx !== 0"
        class="text-center py-3"
      >
        <font-awesome-icon
          :icon="['fas', 'level-down-alt']"
          class="text-center"
          size="2x"
        />
      </div>

      <div
        class="parser mx-4"
      >
        <b-button
          v-if="$idx !== 0"
          type="button"
          class="bg-transparent border-0 parser__remove-btn"
          @click="removeParser($idx)"
        >
          <font-awesome-icon
            class="text-danger"
            :icon="['fas', 'times']"
          />
        </b-button>
        

        <ProgrammaticTargetParserForm
          :parser="parser"
          @update="updateParser($idx, ...arguments)"
        />
      </div>
    </div>

    <b-button
      type="button"
      class="border-0 ml-4 mt-4"
      size="sm"
      @click="addParser"
    >
      <font-awesome-icon
        :icon="['fas', 'plus']"
        class="mr-2"
      />
      Add New Parsing Step
    </b-button>
  </div>
</template>

<script>
import { getDefaultParser } from '@/store/modules/campaign.store'
import ProgrammaticTargetParserForm from './ProgrammaticTargetParserForm.vue'

export default {
  name: 'ProgrammaticTargetParsersList',

  components: {
    ProgrammaticTargetParserForm,
  },

  props: {
    parsers: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      parsersCopy: []
    }
  },

  watch: {
    parsers: {
      immediate: true,
      handler(newValue) {
        this.parsersCopy = [...newValue]
      }
    }
  },

  methods: {
    updateParser(idx, parser) {
      this.parsersCopy[idx] = parser

      this.$emit('update', this.parsersCopy)
    },

    addParser() {
      this.parsersCopy.push(getDefaultParser())

      this.$emit('update', this.parsersCopy)
    },

    removeParser(idx) {
      this.parsersCopy.splice(idx, 1)

      this.$emit('update', this.parsersCopy)
    }
  },
}
</script>

<style lang="scss" scoped>
.parser {
  position: relative;
  border: 1px solid var(--component-parser-border-color);
  padding: 20px 5px 5px 5px;
  border-radius: 0.25rem;
}

.parser__remove-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>