<template>
  <div>
    <b-table
      bordered
      small
      :fields="fields"
      :items="parameters"
      fixed
    >
      <template #cell(name)="data">
        <code class="text-nowrap">{{ data.item.name }}</code>
      </template>     

      <template #cell(choices)="data">
        <span
          v-for="(choice, idx) in data.item.choices"
          :key="choice"
        >
          <code>{{ choice }}</code>
          <span
            v-if="idx !== data.item.choices.length - 1"
            class="ml-1 mr-1"
          >|</span>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'SellerOfferBidParameters',

  props: {
    /**
     * @type {import('vue').PropType<Seller.OfferBidParameters>}
     */
    parameters: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      fields: [
        { key: 'name', thClass: 'fixed-width-130' },
        { key:'description', tdClass: 'w-50' },        
        {
          key: 'choices',
          label: 'Valid Values'
        }
      ]
    }
  },
}
</script>

<style>

</style>