<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">{{ editMode? 'Edit': 'Create' }} Campaign</span>
    </template>
    <template v-slot:content>
      <div>
        <b-form-group
          v-if="editMode"
          label="Status"
          label-class="pr-4 custom-form-input-label--internal"
          label-cols-sm="3"
          label-cols="12"
          content-cols="12"
          content-cols-sm="5"
          content-cols-lg="4"
          content-cols-xl="3"
          label-align-sm="right"
          label-align="left"
        >
          <div class="form-control-plaintext">
            <b-badge
              :variant="getBadgeVariantForCampaignStatus(buyerCampaign)"
            >
              {{ getStatusText(buyerCampaign) }}
            </b-badge>
          </div>
        </b-form-group>

        <BaseFormInput
          id="buyer-create-campaign__campaign-name"
          v-model="name"
          :horizontal="true"
          :small="true"
          :readonly="readonly"
          name="Campaign Name"
          data-cy="input-campaign-name"
          internal-platform-input
          placeholder="Campaign Name"
          label="Name"
          type="text"
          width="5"
          width-lg="4"
          width-xl="3"
          required
          has-tooltip
        />

        <BaseFormSelect
          id="buyer-create-campaign__campaign-category"
          v-model="categoryId"
          :horizontal="true"
          internal-platform-input
          :small="true"
          :readonly="readonly"
          name="Category"
          data-cy="select-category"
          label="Category"
          text-field="name"
          value-field="id"
          :options="categoryList"
          required     
          width="5"
          width-lg="4"
          width-xl="3"
          has-tooltip
        />

        <BaseFormSelect
          v-if="Boolean(categoryId)"
          id="buyer-create-campaign__campaign-language"
          v-model="languageId"
          :horizontal="true"
          :readonly="readonly"
          internal-platform-input
          :small="true"
          name="Language"
          data-cy="select-language"
          label="Language"
          text-field="name"
          value-field="id"
          :options="languageOptions"
          width="5"
          width-lg="4"
          width-xl="3"
          required
          has-tooltip
        />

        <BaseFormSelect
          id="buyer-create-campaign__campaign-country"
          v-model="countryId"
          internal-platform-input
          :horizontal="true"
          :readonly="readonly"
          :small="true"
          name="Country"
          label="Country"
          data-cy="select-country"
          text-field="name"
          value-field="id"
          :options="countryList"
          width="5"
          width-lg="4"
          width-xl="3"
          required
          has-tooltip
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { getBadgeVariantForCampaignStatus, getStatusText } from '@/store/modules/campaign.store'

export default {
  name: 'MainSection',
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => { }
    },
    countries: {
      type: Array,
      default: () => { }
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    isCampaignActive() {
      return this.buyerCampaign?.status === 'Active'
    },

    countryList() {
      const countryList = [...this.countries]
      countryList.unshift({ name: 'Please select', value: null })
      return countryList
    },

    categoryList() {
      const categoryList = [...this.categories]
      categoryList.unshift({ name: 'Please select', value: null })
      return categoryList
    },

    name: {
      get() {
        return this.buyerCampaign.name
      },
      set(value) {
        this.UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS({ countryId: this.countryId, name: value, categoryId: this.categoryId, languageId: this.languageId })
      }
    },
    countryId: {
      get() {
        return this.buyerCampaign.countryId
      },
      set(value) {
        this.UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS({ countryId: value, name: this.name, categoryId: this.categoryId, languageId: this.languageId })
      }
    },
    categoryId: {
      get() {
        return this.buyerCampaign.categoryId
      },
      set(value) {
        const categoryLanguages = this.categoryList?.find(c => c.id === value)?.languages ?? []
        let newLanguageId = null

        if (this.languageId && categoryLanguages.some(l => l.id === this.languageId)) {
          newLanguageId = this.languageId
        }

        this.UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS({ countryId: this.countryId, name: this.name, categoryId: value, languageId: newLanguageId })
      }
    },

    languageId: {
      get() {
        return this.buyerCampaign.languageId
      },
      set(value) {
        this.UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS({ countryId: this.countryId, name: this.name, categoryId: this.categoryId, languageId: value })
      }
    },

    languageOptions() {
      const categoryLanguages = this.categoryList?.find(c => c.id === this.buyerCampaign?.categoryId)?.languages ?? []
      const languageOptions = [...categoryLanguages]
      languageOptions.unshift({ name: 'Please select', value: null })
      return languageOptions
    },

  },
  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_BASIC_DETAILS']),

    getBadgeVariantForCampaignStatus,

    getStatusText
  }
}
</script>

<style lang="scss" scoped>

</style>
