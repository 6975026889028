<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Timezone</span>
    </template>

    <template v-slot:content>
      <validation-observer
        ref="formValidation"
        v-slot="{ handleSubmit, invalid }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(updateTimezone)"
        >
          <BaseFormSelect
            id="timeZonesList"
            v-model="timeZoneId"
            :horizontal="true"
            internal-platform-input
            :small="true"
            data-cy="select-timezone"
            name="Time Zone"            
            label="Preferred timezone"
            placeholder="Preferred timezone"
            :options="timeZoneOptions"
            width="6"
            width-lg="3"
            required
          />          

          <BaseButtonLoader
            :horizontal="true"
            internal-platform-input
            variant="primary"
            :loading="loading"
            type="submit"
            :disabled="invalid || !differentTimeZoneSelected"
            @click="updateTimezone"
          >
            Set Timezone
          </BaseButtonLoader>
        </b-form>
      </validation-observer>
    </template>
  </Panel>
</template>

<script>
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AccountTimezone',

  title: 'Set Timezone',

  data() {
    return {
      loading: false,
      timeZoneId: ''
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountTimeZoneId'
    ]),

    ...mapState('common-entities', [
      'timeZones'
    ]), 
    
    timeZoneOptions() {
      return this.timeZones.map(tz => {
        return {
          value: tz.id,
          text: tz.display
        }
      })
    },
    
    differentTimeZoneSelected() {
      return this.timeZoneId !== this.accountTimeZoneId
    },    
  },

  watch: {
    accountTimeZoneId: {
      immediate: true,
      deep: false,
      handler(newValue) {        
        this.timeZoneId = newValue
      }
    }
  },

  methods: {
    ...mapActions('account', [
      'updateAccountTimezone'
    ]),

    async updateTimezone() {
      this.loading = true
      
      try {
        await this.updateAccountTimezone(this.timeZoneId)

        showSuccessMessage('Successfully updated timezone')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>