<template>
  <b-alert
    :show="show"
    variant="warning"
  >
    <h5>
      <font-awesome-icon
        :icon="['fas', 'exclamation-triangle']"
        class="mr-2"
      />
      Please note
    </h5>

    You'll need to resign the <strong>W9 form</strong>, when any of these information have been updated:
    <ul>
      <li>
        Account Number
      </li>
      <li>
        Bank Name
      </li>
      <li>
        Routing Number
      </li>
    </ul>
    You'll need to resign the <strong>W9 form and the contract</strong>, when any of these information have been updated:
    <ul>
      <li>
        Beneficiary Name
      </li>
      <li>
        Beneficiary Address
      </li>
      <li>
        EIN
      </li>
    </ul>


    <h6><strong><u>Traffic and payment will be paused until you sign the respective documents</u></strong></h6>
  </b-alert>
</template>

<script>
export default {
  name: 'BankInfoAlert',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="">
  
</style>