<template>
  <div class="d-flex justify-content-end mt-2 align-items-center dashboard-filters">
    <div class="mr-2">
      <BaseFormSelect
        id="timezone"
        v-model="timeZoneLocalCopy"
        internal-platform-input
        :small="true"
        name="Timezone"
        data-cy="select-timezone"
        text-field="display"
        value-field="id"
        form-group-class="mb-0"
        :options="timeZonesList"
        horizontal
        width="9"
      />
    </div>

    <div>
      <v-datepicker
        v-model="dateRangeLocalCopy"
        format="MMM D, YYYY h:mm A"
        range-separator="  -  "
        type="datetime"
        range
        confirm
        icon-calendar=""
        input-class="mx-input custom-date-range-selector dashboard-filters__item custom-form-input--sm"
        data-cy="multiselect-daterange"
        confirm-text="Apply"
        :use12h="true"
      >
        <template slot="icon-calendar">
          <div />
        </template>
        <template slot="icon-clear">
          <div />
        </template>
      </v-datepicker>
    </div>

    <div class="ml-2 dashboard-filters__item">
      <b-form-group>
        <b-button-group>
          <BaseButtonLoader
            size="sm"
            data-cy="button-auto-refresh"
            class="mb-0"
            button-class="dashboard-filters__item"
            :variant="autoRefreshButtonVariant"
            nowrap
            :width="3"
            @click="toggleAutoRefresh"
          >
            Auto Refresh
          </BaseButtonLoader>

          <BaseButtonLoader
            size="sm"
            data-cy="button-auto-refresh"
            class="mb-0"
            button-class="dashboard-filters__item dashboad-filters__refresh-button"
            nowrap
            :width="3"
            @click="() => refresh()"
          >
            <font-awesome-icon
              class="mr-0"
              :icon="['fas', 'sync']"
            />
          </BaseButtonLoader>
        </b-button-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { showErrorMessage } from '@/notification-utils'

export default {
  name: 'DashboardFilters',
  props: {
    toggleAutoRefresh: {
      type: Function,
      default: () => {}
    },
    autoRefresh: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Function,
      default: () => {}
    },

    dateRange: {
      type: Array,
      default() {
        return []
      }
    },

    timeZone: {
      type: String,
      default: ''
    },

    maxDateRangeInDays: {
      type: Number,
      default: 31
    }
  },
  data() {
    return {
      timezone: null,
    }
  },
  computed: {
    ...mapState('common-entities', [
      'timeZones'
    ]),

    dateRangeLocalCopy: {
      get() {
        return this.dateRange
      },

      set([from, to]) {
        if (to - from > this.daysToMilliseconds(this.maxDateRangeInDays)){
          showErrorMessage(`Time range cannot be longer than ${this.maxDateRangeInDays} days`)
        }
        else{
          this.$emit('date-range-updated', [from, to])
        }
      }
    },

    timeZoneLocalCopy: {
      get() {
        return this.timeZone
      },
      set(value) {
        this.$emit('update-time-zone', value)
      }
    },

    autoRefreshButtonVariant() {
      return this.autoRefresh ? 'primary' : 'default'
    },
    
    timeZonesList() {
      const timeZonesList = [...this.timeZones]
      timeZonesList.unshift({ display: 'Please select', value: null })
      return timeZonesList
    },    
  },

  methods:{
    daysToMilliseconds(days) {
      return days * 24 * 60 * 60 * 1000
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-filters {
  margin: 0 10px;
}
</style>
