<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Budget Cap</span>
    </template>

    <template v-slot:content>
      <validation-observer
        ref="formValidation"
        v-slot="{ handleSubmit, invalid }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(updateAccountBudgetCap)"
        >
          <BaseFormToggle
            id="buyer-account-budget__daily_budget"
            v-model="overrideDailyBudget"
            label="Daily Budget"
            internal-platform-input
            :checked-value="true"
            :unchecked-value="false"
            has-tooltip
          >
            <template
              v-if="overrideDailyBudget"
              v-slot:extra
            >
              <BaseCurrencyInput
                v-model="budget.dailyBudgetInUSD"
                class="ml-2"
                form-group-class="mb-0"
                required
                internal-platform-input
                small
                horizontal
                width="10"
                width-lg="8"
                label-class="d-none"
                name="Daily Budget"
                :max-value="Number.MAX_SAFE_INTEGER"
                :rules="`amount_range:1,${Number.MAX_SAFE_INTEGER}`"
              />
            </template>
          </BaseFormToggle>
          

          <BaseButtonLoader
            :horizontal="true"
            internal-platform-input
            variant="primary"
            :loading="loading"
            type="submit"
            :disabled="invalid"
            @click="updateAccountBudgetCap"
          >
            Update Budget
          </BaseButtonLoader>
        </b-form>
      </validation-observer>
    </template>
  </Panel>
</template>

<script>
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { currencyFormatter } from '@/utils/formatCurrency'
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BuyerAccountBudget',

  title: 'Set Budget Cap',

  data() {
    return {
      loading: false,
      budget: {
        dailyBudgetInUSD: null
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountDetails'
    ]),

    overrideDailyBudget: {
      set(value) {
        if (!value) {
          this.budget.dailyBudgetInUSD = null
        } else {
          this.budget.dailyBudgetInUSD = ''
        }
      },

      get() {
        return this.budget?.dailyBudgetInUSD != null
      }
    }
  },

  watch: {
    accountDetails: {
      immediate: true,
      deep: false,
      handler(newValue) {
        
        this.budget = {
          ...cloneDeep(newValue.budget),
          dailyBudgetInUSD: currencyFormatter(newValue.budget.dailyBudgetInUSD) || null
        } || {
          dailyBudgetInUSD: null
        }
      }
    }
  },

  methods: {
    ...mapActions('account', [
      'updateBuyerAccountBudgetCap'
    ]),

    async updateAccountBudgetCap() {
      this.loading = true
      
      try {
        await this.updateBuyerAccountBudgetCap(this.budget)

        showSuccessMessage('Successfully updated budget cap')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>