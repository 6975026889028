<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <UpdateCampaignAlert
        class="m-3"
        show
        :is-programmatic-buyer-campaign="isProgrammaticBuyerCampaign"
      />
    
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(showConfirmSubmit)"
      >
        <MainSection
          :categories="allowedCategories"
          :countries="countries"
          :edit-mode="true"
          :readonly="isProgrammaticBuyerCampaign"
        />

        <Destination v-if="!isProgrammaticBuyerCampaign" />

        <DestinationSchedulingCapacity
          v-if="!isProgrammaticBuyerCampaign" 
        /> 

        <TrafficSources
          v-if="!isProgrammaticBuyerCampaign" 
          :traffic-sources="allowedTrafficSources" 
        />

        <Audience
          v-if="!isProgrammaticBuyerCampaign" />

        <Budget
          v-if="!isProgrammaticBuyerCampaign"
          :edit="true"
          :invalid="invalid"
          :minimum-initial-deposit-satisfied="isMinimumInitialDepositRequirementSatisfied"
          @submit="showConfirmSubmit"
        />
      </b-form>
    </validation-observer>

    <b-modal
      id="update-buyer-campaign-modal"
      ref="update-buyer-campaign-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Update"
      ok-title="Update"
      button-size="sm"
      @ok="submitForm"
    >
      <p class="mb-0">
        Are you sure you want to update the buyer campaign?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('update-buyer-campaign-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="submitForm"
        >
          Update
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

import MainSection from '../campaign/MainSection.vue'
import Destination from '../campaign/Destination.vue'
import DestinationSchedulingCapacity from '../campaign/DestinationSchedulingCapacity.vue'
import TrafficSources from '../campaign/TrafficSources.vue'
import Audience from './Audience.vue'
import Budget from './Budget.vue'
import UpdateCampaignAlert from './UpdateCampaignAlert.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'BuyerEditCampaign',

  title: function() {
    return this.id ? (this.buyerCampaign?.name || 'Campaign') : 'Create Campaign'
  },

  components: {
    MainSection,
    Destination,
    DestinationSchedulingCapacity,
    TrafficSources,
    Audience,
    Budget,
    UpdateCampaignAlert
  },

  props: {
    id: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState('common-entities', ['countries']),

    ...mapGetters('account', ['allowedTrafficSources', 'allowedCategories']),

    ...mapState('campaign', ['buyerCampaign']),
    ...mapGetters('campaign', ['isProgrammaticBuyerCampaign']),

    ...mapGetters('auth', ['isMinimumInitialDepositRequirementSatisfied']),
  },

  async created() {
    this.setDefaultBuyerCampaign()
    await this.fetchBuyerCampaign(this.id)
    document.title = this.buyerCampaign.name
    await this.getCountries()
  },

  methods: {
    // Also, the list of categories and countries returned should
    // be specific to a buyer. The API endpoint should use the
    // id of the logged in user to fetch specific categories and countries
    // TODO: Set allowed countries for an account?
    ...mapActions('common-entities', ['getCountries']),

    ...mapActions('campaign', ['setDefaultBuyerCampaign', 'editBuyerCampaign', 'fetchBuyerCampaign']),

    showConfirmSubmit() {
      this.$bvModal.show('update-buyer-campaign-modal')
    },

    async submitForm() {
      try {
        this.isLoading = true

        await this.editBuyerCampaign(cloneDeep(this.buyerCampaign))

        showSuccessMessage('Campaign updated successfully')

        this.$bvModal.hide('update-buyer-campaign-modal')

        await this.fetchBuyerCampaign(this.id)
      } catch (error) {
        showErrorMessage(error.response?.data?.title || error.message)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
