// import httpClient from './httpClientDebug'
import httpClient from './httpClient'

const END_POINT = '/customerresources'
// const END_POINT = ''

const CampaignAPI = {
  createBuyerCampaign({ accountId, campaign }) {
    return httpClient.post(`${END_POINT}/${accountId}/buyer-campaigns`, campaign)
  },

  // eslint-disable-next-line no-unused-vars
  editBuyerCampaign({ accountId, campaign }) {
    return httpClient.patch(`${END_POINT}/${accountId}/buyer-campaigns/${campaign.id}`, campaign)
  },

  listBuyerCampaigns(accountId) {
    return httpClient.get(`${END_POINT}/${accountId}/buyer-campaigns`)
  },

  getBuyerCampaign(accountId, id) {
    return httpClient.get(`${END_POINT}/${accountId}/buyer-campaigns/${id}`)
  },

  pauseBuyerCampaign({ accountId, campaignId }) {
    return httpClient.put(`${END_POINT}/${accountId}/buyer-campaigns/${campaignId}/pause`)
  },

  resumeBuyerCampaign({ accountId, campaignId }) {
    return httpClient.put(`${END_POINT}/${accountId}/buyer-campaigns/${campaignId}/resume`)
  },

  pauseSellerCampaign({ campaignId }) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ id: campaignId })
      }, 3000)
    })
  },

  resumeSellerCampaign({ campaignId }) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ id: campaignId })
      }, 3000)
    })
  },

  updateCampaignBid({ campaignId, bidValue }) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ id: campaignId, bid: bidValue })
      }, 1500)
    })
  }
}

export default CampaignAPI
