import httpClient from './httpClient'

const END_POINT = '/customerresources/user-account/buyer'

const BuyerAccountAPI = {

  /**
   * Update buyer account budget cap
   * @param {string} accountId 
   * @param {{ dailyBudgetInUSD: number }} budget 
   * @returns void
   */
  async updateBuyerAccountBudgetCap(accountId, budget) {
    return await httpClient.put(`${END_POINT}/${accountId}/budget`, budget)
  },

  /**
   * Get credit card charges (pre-paid buyer account)
   * @param {string} accountId 
   * @returns void
   */
  async getCreditCardCharges(accountId) {
    return await httpClient.get(`${END_POINT}/${accountId}/charge-receipts`)
  },

  /**
   * Get advance pay requests (buyer account)
   * @param {string} accountId 
   * @returns Array<Object> advancePayRequests
   */
  async getAdvancePayRequests(accountId) {
    let data = await httpClient.get(`/customerresources/${accountId}/billing/advance-pay`)
    return data
  },

  /**
   * Create buyer advance pay pending request
   * @param {string} accountId 
   * @param {{ }} requestData 
   * @returns Object advancePayRequest
   */
  async createAdvancePaymentRequest({ accountId, advancePayRequest }) {
    let data = await httpClient.post(`/customerresources/${accountId}/billing/advance-pay`, advancePayRequest)
    return data
  },
  
  /**
   * Get system account bank details
   * @returns Object bankDetails
   */
  async getSystemAccountBankDetails() {
    let data = await httpClient.get(`/info/bank-details`)
    return data
  },
  
}

export default BuyerAccountAPI