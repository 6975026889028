<template>
  <div>
    <BaseFormToggle
      id="buyer-create-campaign__destination__max-concurrency"
      v-model="isConcurrencyCapEnabled"
      label="Max Concurrency"
      data-cy="toggle-max-concurrency"
      internal-platform-input
      has-tooltip
    >
      <template
        v-if="isConcurrencyCapEnabled"
        v-slot:extra
      >
        <BaseFormInput
          v-model="concurrencyCap"
          class="ml-2"
          form-group-class="mb-0"
          type="number"
          required
          internal-platform-input
          small
          horizontal
          width="8"
          width-lg="6"
          label-class="d-none"
          name="Concurrency"
          rules="min_value:1"
        />
      </template>
    </BaseFormToggle>

    <!-- <BaseFormToggle
      id="buyer-create-campaign__destination__hourly-concurrency"
      v-model="isHourlyConcurrencyEnabled"
      label="Hourly Concurrency"
      data-cy="toggle-hourly-concurrency"
      internal-platform-input
      :checked-value="true"
      :unchecked-value="false"
      has-tooltip
    >
      <template
        v-if="isHourlyConcurrencyEnabled"
        v-slot:extra
      >
        <BaseFormInput
          v-model="hourlyConcurrencyCap"
          class="ml-2"
          type="number"
          required
          internal-platform-input
          small
          horizontal
          width="8"
          width-lg="6"
          label-class="d-none"
          name="Hourly Concurrency"
          rules="min_value:1"
        />
      </template>
    </BaseFormToggle> -->
  </div>
</template>

<script>

export default {
  name: 'TargetCapacity',

  props: {
    targetSchedule: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    isConcurrencyCapEnabled:{
      get() {
        return this.targetSchedule.concurrencyCap != null
      },
      set(value) {
        if (value) {
          this.concurrencyCap = this.concurrencyCap == null ? 1 : this.concurrencyCap
        } else {
          this.concurrencyCap = null
        }
      }
    },

    concurrencyCap: {
      get() {
        return this.targetSchedule.concurrencyCap
      },
      set(value) {
        this.$emit('update', {...this.targetSchedule, concurrencyCap: value})
      }
    },

    isHourlyConcurrencyEnabled: {
      get() {
        return this.targetSchedule.hourlyConcurrencyCap != null
      },

      set(value) {
        if (value) {
          this.hourlyConcurrencyCap = this.hourlyConcurrencyCap == null ? 1 : this.hourlyConcurrencyCap
        } else {
          this.hourlyConcurrencyCap = null
        }
      }
    },

    hourlyConcurrencyCap: {
      get() {
        return this.targetSchedule.hourlyConcurrencyCap
      },
      set(value) {
        this.$emit('update', {...this.targetSchedule, hourlyConcurrencyCap: value})
      }
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>