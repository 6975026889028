<template>
  <Panel>
    <template v-slot:header>
      Add Credit Card
    </template>

    <template v-slot:content>
      <div class="w-50">
        <CreditCardForm
          :is-loading="isLoading"
          @submit="onSubmit"
          @success="onSuccess"
          @error="onError"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import CreditCardForm from '@/components/onboarding/CreditCardForm.vue'

export default {
  name: 'AddCreditCard',
  components: {
    CreditCardForm,
  },

  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true
    },
    onError() {
      this.isLoading = false
    },
    async onSuccess() {
      this.isLoading = false
      this.$router.push({ name: 'PaymentMethods' })
    }
  },
  head: {
    script: [
      {
        hid: 'spreedly-script1',
        src: 'https://core.spreedly.com/iframe/iframe-v1.min.js'
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
</style>
