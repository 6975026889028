<template>
  <div>
    <validation-provider
      v-slot="validationContext"
      :name="$attrs.name"
      :rules="validationRules"
    >
      <b-form-row>
        <b-col md="12">
          <b-form-group
            label-cols-sm="3"
            label-class="pr-4"
            label-cols="12"
            label-align-sm="right"
            label-align="left"
            :label="label"
            content-cols="12"
            content-cols-sm="9"
          >
            <template
              v-if="hasTooltip"
              slot="label"
            >
              <div>
                {{ label }}
                <span
                  v-b-tooltip.hover.right
                  :title="fetchTooltip($attrs.id)"
                >
                  <font-awesome-icon
                    class="ml-1 mt-1"
                    :icon="['fas', 'question-circle']"
                  />
                </span>
              </div>
            </template>

            <div class="d-flex">
              <b-form-checkbox
                v-model="state"
                switch
                button-variant="success"
                size="lg"
                :value="checkedValue"
                v-bind="$attrs"
                :unchecked-value="uncheckedValue"
                :state="getValidationState(validationContext)"
                :class="{ 'checked': Boolean(state) }"
                v-on="$listeners"
              />

              <slot name="extra" />
            </div>

            <slot
              name="helpText"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </validation-provider>
  </div>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormToggle',
  mixins: [formInputMixin],
  inheritAttrs: false,

  props: {

    label: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number, Boolean, Object],
      required: true
    },

    checkedValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },

    uncheckedValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400,
    },

    rules: {
      type: [String, Array],
      default: ''
    },

    requireChecked: {
      type: Boolean,
      default: false
    },

    hasTooltip: {
      type: Boolean,
      default: false
    }


  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    isCheckboxTickedRequirement() {
      return this.requireChecked ? !!this.state : true
    }
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid && this.isCheckboxTickedRequirement) : null
    },
  },

}
</script>

<style lang="scss" scoped>

</style>