<template>
  <Panel>
    <template v-slot:header>
      <span data-cy="heading">Multi-Factor Authentication</span>
    </template>

    <template v-slot:content>
      <div>
        <validation-observer
          ref="formValidation"
          v-slot="{ handleSubmit }"
        >
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit(completeMFASetup)"
          >
            <div>
              <BaseButtonLoader
                id="button-setup-mfa"
                data-cy="button-setup-mfa"
                :loading="loading"
                label="Software based MFA"
                internal-platform-input
                button-class="custom-button--white-hover-grad pulse"
                type="button"
                horizontal
                @click="initiateMFASetup"
              >
                Setup
              </BaseButtonLoader>
            </div>
            
            <b-row v-if="MFASecretKey">
              <b-col
                offset-sm="4"
                offset-lg="3"
                sm="5"
                lg="4"
              >
                <canvas
                  id="canvas"
                  class="my-2"
                />

                <div class="mb-2">
                  <div
                    class="custom-form-input-label mr-2"
                  >
                    Secret Key
                  </div>
                  <div>{{ MFASecretKey }}</div>
                </div>

                <BaseFormInput
                  id="software-token"
                  v-model="details.softwareToken"
                  name="Software Token"
                  placeholder="Enter the 6 digit MFA code"
                  class="mt-4 d-block"
                  label="Code"
                  type="text"
                  required
                >
                  <template v-slot:helpText>
                    <b-form-text>
                      <div>
                        Enter the code from the authenticator app and click 'Verify Code' button to complete the MFA setup.
                      </div>
                    </b-form-text>
                  </template>
                </BaseFormInput>

                <BaseButtonLoader
                  id="button-complete-mfa"
                  data-cy="button-complete-mfa"
                  :loading="verifyButtonLoading"
                  button-class="custom-button--white-hover-grad pulse mt-2"
                  type="submit"
                  @click="completeMFASetup"
                >
                  Verify Code
                </BaseButtonLoader>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import QRCode from 'qrcode'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

function qrCodeToCanvas(canvas, value) {
  return new Promise((resolve, reject) => {
    try {
      QRCode.toCanvas(canvas, value)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export default {
  name: 'TwoFactorAuth',
  
  data() {
    return {
      loading: false,
      verifyButtonLoading: false,
      details: {
        softwareToken: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['MFASecretKey', 'authenticatedUserDetails'])
  },
  created() {
    this.resetMFAKey()
  },
  methods: {
    ...mapActions('auth', [
      'associateSoftwareMFA',
      'verifySoftwareMFA',
      'resetMFAKey'
    ]),
    async initiateMFASetup() {
      try {
        this.loading = true
        await this.associateSoftwareMFA()
        const canvas = document.getElementById('canvas')
        const email = this.authenticatedUserDetails.email
        const value = `otpauth://totp/RingbaX:${email}?secret=${this.MFASecretKey}&issuer=RingbaX`
        return qrCodeToCanvas(canvas, value)
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loading = false
      }
    },

    async completeMFASetup() {
      try {
        this.verifyButtonLoading = true
        const softwareToken = this.details.softwareToken
        await this.verifySoftwareMFA(softwareToken)
        showSuccessMessage('Software MFA Setup Completed')
        this.resetMFAKey()
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.verifyButtonLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
