<template>
  <div>
    <BaseButtonGroup
      id="buyer-create-campaign__destination__revenue-type"
      :horizontal="true"
      internal-platform-input
      data-cy="button-group-type"
      :selected-option="bidSettingsCopy.bidType"
      :click-handler="updateBidType"
      label="Bid Type"
      :options="['Dynamic', 'Static']"
      has-tooltip
    />

    <BaseCurrencyInput
      v-if="bidSettingsCopy.bidType === 'Static'"
      id="buyer-create-campaign__destination__static-revenue-amount"
      v-model="bidSettingsCopy.staticBidAmount"
      internal-platform-input
      horizontal
      small
      name="Static Bid"
      label="Static Bid"
      required
      min="0"
      width="2"
      width-lg="2"
      width-xs="4"
      input-class="fixed-width-130"
      has-tooltip
      @change="$emit('update', bidSettingsCopy)"
    />

    <BaseCurrencyInput
      id="buyer-create-campaign__destination__failure-revenue-amount"
      v-model="bidSettingsCopy.bidOnFailure"
      internal-platform-input
      horizontal
      small
      name="Failure Bid"
      label="Failure Bid"
      required
      min="0"
      width="2"
      width-lg="2"
      width-xs="4"
      input-class="fixed-width-130"
      has-tooltip
      @change="$emit('update', bidSettingsCopy)"
    />

    <BaseButtonGroup
      id="buyer-create-campaign__destination__conversion-call-length-source"
      :horizontal="true"
      internal-platform-input
      data-cy="button-group-type"
      :selected-option="bidSettingsCopy.conversionCallLengthDynamic"
      :click-handler="updateConversionCallLengthSource"
      label="Conversion Duration"
      :options="[
        { text: 'Dynamic', value: true },
        { text: 'Static', value: false },
      ]"
      has-tooltip
    />

    <BaseFormInput
      v-if="!bidSettingsCopy.conversionCallLengthDynamic"
      id="buyer-create-campaign__destination__call-length"
      v-model="bidSettingsCopy.conversionCallLengthInSeconds"
      internal-platform-input
      :horizontal="true"
      :small="true"
      name="Call Length"
      label="Call Length"
      type="number"
      required
      width="2"
      width-lg="1"
      rules="min_value:0"
      input-class="fixed-width-130"
      has-tooltip
      @change="$emit('update', bidSettingsCopy)"
    >
      <template v-slot:helpText>
        <b-form-text>
          <div>
            seconds
          </div>
        </b-form-text>
      </template>
    </BaseFormInput>

    <BaseFormInput
      v-if="bidSettingsCopy.conversionCallLengthDynamic"
      id="buyer-create-campaign__destination__max-dynamic-duration"
      v-model="bidSettingsCopy.maxConversionCallDurationSeconds"
      internal-platform-input
      :horizontal="true"
      :small="true"
      name="Duration Limit"
      label="Duration Limit"
      placeholder="unlimited"
      type="number"
      width="2"
      width-lg="1"
      rules="min_value:0"
      input-class="fixed-width-130"
      has-tooltip
      @change="$emit('update', bidSettingsCopy)"
    >
      <template v-slot:helpText>
        <b-form-text>
          <div>
            seconds
          </div>
        </b-form-text>
      </template>
    </BaseFormInput>
  </div>
</template>

<script>

export default {
  name: 'ProgrammaticTargetBidSettings',

  props: {
    bidSettings: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      bidSettingsCopy: {}
    }
  },

  watch: {
    bidSettings: {
      immediate: true,
      handler(newValue) {
        this.bidSettingsCopy = { ...newValue }
      },
    },
  },

  methods: {
    updateBidType(option) {
      this.bidSettingsCopy.bidType = option
      this.bidSettingsCopy.isBidDynamic = option === 'Dynamic'

      this.$emit('update', this.bidSettingsCopy)
    },

    updateConversionStartTimer(option) {
      this.bidSettingsCopy.conversionParams.conversionArgs.startTimerEvent = option

      this.$emit('update', this.bidSettingsCopy)
    },

    updateConversionCallLengthSource(option) {
      this.bidSettingsCopy.conversionCallLengthDynamic = option

      this.$emit('update', this.bidSettingsCopy)
    }
  },
}
</script>

<style>
</style>