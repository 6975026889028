import { Crisp } from 'crisp-sdk-web'
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      unreadChatCount: 0
    }
  },

  computed: {
    ...mapState('auth', [
      'userDetails'
    ]),

    ...mapGetters('auth', [
      'accountDetails',
      'isBuyerAccount'
    ]),

    showSupportChat() {
      return Boolean(process.env.VUE_APP_CRISP_WEBSITE_ID)
    }
  },

  created () {
    if (this.showSupportChat) {
      Crisp.chat.hide()
  
      this.setUserDetailsInCrisp()
      
      this.updateUnreadCount()
  
      Crisp.chat.onChatClose(() => {
        Crisp.chat.hide()
        this.updateUnreadCount()
      })
  
      Crisp.message.onMessageReceived(() => {
        this.updateUnreadCount()
      })
    }
  },

  methods: {
    toggleSupportChat() {
      if (Crisp.chat.isVisible()) {
        Crisp.chat.hide()
        this.updateUnreadCount()
      } else {
        setTimeout(() => {
          this.updateUnreadCount()
        }, 500)
        Crisp.chat.show()
        Crisp.chat.open()
      }
    },

    setUserDetailsInCrisp() {
      Crisp.session.setData({
        accountId : this.accountDetails.id,
        accountName : this.accountDetails.name,
        role: this.isBuyerAccount ? 'buyer' : 'seller'
      })

      Crisp.session.setSegments([this.isBuyerAccount ? 'buyer' : 'seller'])

      Crisp.user.setNickname(`${this.userDetails.name} ${this.userDetails.lastName}`)
      Crisp.user.setEmail(this.userDetails.email)
    },

    updateUnreadCount() {
      this.unreadChatCount = Crisp.chat.unreadCount()
    }
  },
}