<template functional>
  <div class="header">
    <img
      src="@/assets/img/layout-public-header-bg.png"
      alt="login background"
      class="header__bg"
    >
    <div class="py-sm-4 my-sm-3 py-3 my-3 container header__title-container">
      <h1 class="header__title mb-0">
        Welcome to <span class="text-nowrap">Ringba X!</span>
      </h1>

      <p class="header__subtitle mb-0">
        Get ready to roll!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutPublicHeader',
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  box-shadow: 1px 1px 20px 5px var(--component-layout-public__header-shadow);
}

.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__title-container {
  max-width: 75%;
  position: relative;
}

.header__title {
  @include md-title(var(--white));
}

.header__subtitle {
  @include subtitle(var(--component-layout-public__header-subtitle-color));
}

@include media-breakpoint-down(xs) {
  .header__title-container {
    max-width: 90%;
  }
}
</style>
