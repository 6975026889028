<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Budget</span>
    </template>

    <template v-slot:content>
      <BaseFormToggle
        v-if="!isRTBSelected"
        id="buyer-create-campaign__audience__minimum-duration"
        v-model="minimumDurationSelected"
        label="Minimum Duration"
        data-cy="toggle-minimum-duration"
        internal-platform-input
        :checked-value="true"
        :unchecked-value="false"
        has-tooltip
      >
        <template 
          v-if="minimumDurationSelected"
          v-slot:extra
        >          
          <BaseFormInput
            v-model="minimumDurationInSeconds"              
            form-group-class="mb-0"
            type="number"
            max="9999"
            required
            internal-platform-input
            small
            horizontal
            width="7"
            width-lg="5"
            label-class="d-none"
            name="Minimum Duration"
            rules="min_value:0|max_value:9999"
          >
            <template v-slot:helpText>
              <b-form-text class="col-1">
                <div>
                  seconds
                </div>
              </b-form-text>
            </template>
          </BaseFormInput>                    
        </template>
        <template 
          v-else
          v-slot:helpText          
        >
          <b-badge             
            variant="warning"
            tag="div"
            class="mt-3"
          >
            <font-awesome-icon             
              :icon="['fas', 'exclamation-triangle']"
              :style="{ cursor: 'pointer' }"              
            /> 
            Without minimum call duration limit you pay for every call that connects
          </b-badge>
        </template>
      </BaseFormToggle>
      
      <BaseCurrencyInput
        id="buyer-create-campaign__audience__daily-budget"
        v-model="dailyBudgetInUSD"
        internal-platform-input
        horizontal
        data-cy="input-daily-budget"
        small
        name="Daily Budget"
        placeholder="ex. $1000.00"
        rules="amount_range:10,99999.99"
        label="Daily Budget"
        required
        width="2"
        width-lg="2"
        width-xs="4"
        input-class="fixed-width-130"
        has-tooltip
      />      

      <BaseCurrencyInput
        id="buyer-create-campaign__audience__bid-per-call"
        v-model="maxBidPerCallInUSD"
        :horizontal="true"
        internal-platform-input
        small
        data-cy="input-bid-per-call"
        name="Bid Per Call"
        label="Bid Amount"
        placeholder="ex. $100.00"
        min="0"
        rules="amount_range:0.01,9999.99"
        :max-value="9999.99"
        v-bind="maxBidAttributes"
        width="6"
        width-lg="6"
        width-xs="12"
        input-class="fixed-width-130"
        has-tooltip
        :disabled="isRTBSelected"
      >
        <template v-slot:helpText>
          <b-form-text v-if="isRTBSelected">
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="mr-1"
            />
            <i>Bid amount and duration will come from the bids in Ringba RTB.</i>
          </b-form-text>
        </template>
      </BaseCurrencyInput>

      <b-row>
        <b-col md="3" />
        <b-col
          v-if="submitted && invalid"
          class="text-danger"
        >
          Please fix the errors in your form
        </b-col>
      </b-row>

      <BaseButtonLoader
        :horizontal="true"
        data-cy="button-create-campaign"
        internal-platform-input
        variant="primary"
        :loading="loading"
        :disabled="!minimumInitialDepositSatisfied"
        type="submit"
      >
        {{ edit ? 'Save' : 'Create' }} Campaign
      </BaseButtonLoader>
    </template>
  </Panel>
</template>

<script>

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Budget',
  props: {
    invalid: {
      type: Boolean,
      default: true
    },

    edit: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    minimumInitialDepositSatisfied: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    isRTBSelected() {
      return this.buyerCampaign?.routing?.type === 'RingbaRTB' || this.buyerCampaign?.routing?.type === 'Programmatic'
    },

    maxBidAttributes() {
      return !this.isRTBSelected ? { required: '' } : {}
    },

    minimumDurationSelected: {
      get() {
        return this.buyerCampaign.budget.minimumDurationSelected
      },
      set(value) {
        const budget = { ...this.buyerCampaign.budget }
        budget.minimumDurationSelected = value
        this.UPDATE_BUYER_CAMPAIGN_BUDGET(budget)
      }
    },

    minimumDurationInSeconds: {
      get() {
        return this.buyerCampaign.budget.minimumDurationInSeconds
      },
      set(value) {
        const budget = { ...this.buyerCampaign.budget }
        budget.minimumDurationInSeconds = Number(value)
        this.UPDATE_BUYER_CAMPAIGN_BUDGET(budget)
      }
    },

    dailyBudgetInUSD: {
      get() {
        return this.buyerCampaign.budget.dailyBudgetInUSD
      },
      set(value) {
        const budget = { ...this.buyerCampaign.budget }
        budget.dailyBudgetInUSD = value
        this.UPDATE_BUYER_CAMPAIGN_BUDGET(budget)
      }
    },

    maxBidPerCallInUSD: {
      get() {
        return this.buyerCampaign.budget.maxBidPerCallInUSD
      },
      set(value) {
        const budget = { ...this.buyerCampaign.budget }
        budget.maxBidPerCallInUSD = value
        this.UPDATE_BUYER_CAMPAIGN_BUDGET(budget)
      }
    },
  },
  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_BUDGET']),

    submit() {
      this.submitted = true
      if (!this.invalid) {
        this.$emit('submit')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
