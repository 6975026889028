import AuthAPI from '@/api/auth.api'
import CustomerServiceAPI from '@/api/customer-services.api'

const LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY = 'authenticatedUserDetails'

const ENTER_USER_CREDS = 'ENTER_USER_CREDS'
const SELECT_MFA_TYPE = 'SELECT_MFA_TYPE'
const SMS_MFA = 'SMS_MFA'
const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/ // eslint-disable-line

const BANK_INFO_SUBMITTED = 'BankInfoSubmitted'
const PENDING_DOCUMENT_SIGNING = 'PendingDocumentSigning'
const PENDING = 'Pending'
const COMPLETED = 'Completed'

export const BUYER_GROUP = 'Buyer'
export const SELLER_GROUP = 'Seller'

const ENTER_EMAIL = 'ENTER_EMAIL'
const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'

const MFA_STAGES = {
  ENTER_USER_CREDS,
  SMS_MFA,
  SOFTWARE_TOKEN_MFA,
  SELECT_MFA_TYPE,
  NEW_PASSWORD_REQUIRED,
  LOGIN_SUCCESS
}

const SELLER_ONBOARDING_STATUS = {
  PENDING,
  BANK_INFO_SUBMITTED,
  PENDING_DOCUMENT_SIGNING,
  COMPLETED
}

const BUYER_ONBOARDING_STATUS = {
  PENDING,
  PENDING_DOCUMENT_SIGNING,
  COMPLETED
}

const PASSWORD_RESET_STAGES = {
  ENTER_EMAIL,
  SET_NEW_PASSWORD
}

const initialAuthData = {
  accessToken: '',
  refreshToken: '',
  idToken: '',
  cognitoUsername: '',
  email: ''
}

const ACCOUNT_REQUIREMENTS = {
  MinimumInitialDeposit: 'MinimumInitialDeposit',
  AdminApproval: 'AdminApproval'
}

const dashboardNotificationForAccountRequirementsMessages = {
  [ACCOUNT_REQUIREMENTS.MinimumInitialDeposit]: {
    text: `You can't create campaigns until you add funds to meet the minimum initial deposit requirement. `,
    linkText: 'Click here to add funds',
    routeName: 'AddFunds',
    hideOnClick: true,
  }
}

function _getInitialAuthorizationUserDetails() {
  try {
    return JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY)
    )
  } catch (error) {
    console.error(error)

    return initialAuthData
  }
}

function _saveAuthenticatedUserDataToLocalStorage(authData) {
  localStorage.setItem(
    LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
    JSON.stringify(authData)
  )
}


const state = {
  authenticatedUserDetails: _getInitialAuthorizationUserDetails(),

  currentAccountDetails: {
    accountRequirements: {},
  },

  redirectToAfterLoginPath: '',

  loginError: {
    errorMessage: '',
    errorCode: null
  },

  loginDetails: {
    cognitoUsername: '',
    sessionID: '',
    currentAuthChallenge: MFA_STAGES.ENTER_USER_CREDS,
    email: ''
  },

  MFASecretKey: '',
  userDetails: {
    name: '',
    email: '',
    id: '' // this is the cognito username
  },
}

/** 
 * @type {import('vuex').GetterTree<typeof state>} 
 */
const getters = {
  currentAuthChallenge(state) {
    return state.loginDetails?.currentAuthChallenge
  },

  isAuthenticated(state) {
    return !!state.authenticatedUserDetails?.accessToken
  },

  accessToken(state) {
    return state.authenticatedUserDetails?.accessToken
  },

  accountDetails(state) {
    return state.currentAccountDetails
  },

  userId(state) {
    return state.userDetails?.id
  },

  accountId(state) {
    return state.currentAccountDetails?.id
  },

  accountTimeZoneId(state) {
    return state.currentAccountDetails?.timeZoneId
  },

  haveUserCurrentAccountDetails(state) {
    return !!state.currentAccountDetails?.id
  },

  isBuyerAccount(state) {
    return state.currentAccountDetails?.type === BUYER_GROUP
  },

  isSellerAccount(state) {
    return state.currentAccountDetails?.type === SELLER_GROUP
  },

  allUserAccounts(state) {
    return state.authenticatedUserDetails?.accounts
  },

  haveUserData(state) {
    return !!state.userDetails?.id
  },

  accountOnboardingStatus(state, getters) {
    return getters.accountDetails?.onboardingStatus
  },

  isMinimumInitialDepositRequirementSatisfied(state, getters) {
    return getters.accountDetails?.accountRequirements?.[ACCOUNT_REQUIREMENTS.MinimumInitialDeposit]
  },

  isMFASetup(state) {
    return Boolean(state.userDetails?.mfaSettings?.enabledMFA?.length)
  },

  lastAccountAccessedType(state) {
    return state.userDetails.accounts.find(a => a.accountId === state.userDetails.lastAccessedAccountId)?.accountType
  },

  isAdminApprovalPending(state, getters) {
    return !getters.accountDetails?.accountRequirements?.[ACCOUNT_REQUIREMENTS.AdminApproval]
  }
}

const actions = {
  setRedirectToPathAfterLogin({ commit }, path) {
    commit('SET_REDIRECT_TO_AFTER_LOGIN_PATH', path)
  },

  async login({ commit, dispatch }, { email, password }) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.login({ email, password })

      const nextStageData = response.data

      commit('SET_LOGIN_STAGE_STATE', {
        cognitoUsername: nextStageData.username,
        sessionID: nextStageData.sessionID,
        currentAuthChallenge: nextStageData.challengeName,
        email
      })

      if (nextStageData.authenticationResult) {
        dispatch('setAuthData', {
          authData: nextStageData.authenticationResult
        })
      }
    } catch (error) {
      console.log(error)
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.response.data.title,
        errorCode: error.response.data.errorCode
      })
    }
  },

  async submitMFATypeSelected({ state, commit }, mfaType) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.respondToAuthChallenge({
        challengeName: state.loginDetails.currentAuthChallenge,
        challengeResponseAnswer: mfaType,
        sessionID: state.loginDetails.sessionID,
        username: state.loginDetails.cognitoUsername
      })

      const nextStageData = response.data

      commit('SET_LOGIN_STAGE_STATE', {
        sessionID: nextStageData.sessionID,
        currentAuthChallenge: mfaType
      })
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.title,
        errorCode: error.errorCode
      })
    }
  },

  async submitMFACode({ state, commit, dispatch }, mfaCode) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.respondToAuthChallenge({
        challengeName: state.loginDetails.currentAuthChallenge,
        challengeResponseAnswer: mfaCode,
        sessionID: state.loginDetails.sessionID,
        username: state.loginDetails.cognitoUsername
      })

      const responseData = response.data

      if (responseData.authenticationResult) {
        dispatch('setAuthData', { authData: responseData.authenticationResult })
      }
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.response.data.title,
        errorCode: error.response.data.errorCode
      })
    }
  },

  async refreshAccessToken({ state, commit }) {
    try {
      const response = await AuthAPI.refreshAuthDataUsingRefreshToken({
        refreshToken: state.authenticatedUserDetails.refreshToken,
        cognitoUsername: state.authenticatedUserDetails.cognitoUsername
      })

      const authenticationResult = response.data

      if (authenticationResult) {
        commit('SET_AUTH_TOKENS', authenticationResult)

        localStorage.setItem(
          LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
          JSON.stringify(state.authenticatedUserDetails)
        )
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async fetchUsersAccountDetails({ commit , dispatch}) {
    try {
      const response = await CustomerServiceAPI.getUserCurrentAccountDetails()
      const result = response.data

      let account = result.account

      account.type = result.type

      if (result) {
        commit('SET_ACCOUNT_DETAILS', account)
        await dispatch('fetchCurrentAccountRequirements')
      }

    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async fetchUserAccounts({ commit }) {
    const response = await CustomerServiceAPI.getUserAccounts()
    const result = response.data

    commit('SET_ALL_USER_ACCOUNTS', result)
  },

  async associateSoftwareMFA({ commit, dispatch, state }) {
    try {
      const response = await AuthAPI.associateSoftwareMFA({
        accessToken: state.authenticatedUserDetails.accessToken
      })
      commit('SET_MFA_KEY', response.data.secretKey)
      return response.data.secretKey
    } catch(error) {
      // TODO: once we update backend code to send some specific error
      // code in error.response.data.code, update the below condition
      if (error.response.status === 400) {
        await dispatch('refreshAccessToken')
        return dispatch('associateSoftwareMFA')
      }
      throw error
    }
  },

  async verifySoftwareMFA({ state }, softwareToken) {
    const response = await AuthAPI.verifySoftwareMFA({
      accessToken: state.authenticatedUserDetails.accessToken,
      softwareToken
    })
    return response.data
  },

  logout({ commit }) {
    // TODO: Add and integrate global signout API
    commit('RESET_AUTHENTICATED_USER_DATA')
    localStorage.clear()
  },

  async setPermanentPassword({ commit, dispatch, state }, newPassword) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.respondToAuthChallenge({
        challengeName: state.loginDetails.currentAuthChallenge,
        challengeResponseAnswer: newPassword,
        sessionID: state.loginDetails.sessionID,
        username: state.loginDetails.cognitoUsername
      })

      const responseData = response.data

      commit('SET_LOGIN_STAGE_STATE', {
        sessionID: responseData.sessionID,
        currentAuthChallenge: responseData.challengeName
      })

      if (responseData.authenticationResult) {
        dispatch('setAuthData', { authData: responseData.authenticationResult })
      }
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.response.data.title,
        errorCode: error.response.data.errorCode
      })
    }
  },

  async forgotPassword(context, email) {
    return AuthAPI.forgotPassword({ email })
  },

  async setNewPassword(context, { email, newPassword, confirmationCode }) {
    return AuthAPI.setNewPassword({ email, newPassword, confirmationCode })
  },

  async getLoggedInUserDetails({ commit, state }) {
    try {
      const response = await CustomerServiceAPI.getLoggedInUserDetails()

      const userDetails = response.data

      commit('SET_USER_DETAILS', userDetails)

      commit('SET_AUTHENTICATED_USER_DATA', {
        cognitoUsername: userDetails.id,
        email: userDetails.email
      })

      _saveAuthenticatedUserDataToLocalStorage(state.authenticatedUserDetails)
    } catch (error) {
      console.error(error)
    }
  },

  setAuthData({ commit, state }, { authData }) {
    commit('SET_AUTH_TOKENS', authData)

    commit('SET_AUTHENTICATED_USER_DATA', {
      cognitoUsername: state.loginDetails?.cognitoUsername,
      email: state.loginDetails?.email
    })

    _saveAuthenticatedUserDataToLocalStorage(state.authenticatedUserDetails)

    commit('SET_LOGIN_STAGE_STATE', {
      currentAuthChallenge: MFA_STAGES.LOGIN_SUCCESS
    })
  },

  resetMFAKey({ commit }) {
    commit('RESET_MFA_KEY')
  },

  resetLoginStageState({ commit }) {
    commit('RESET_LOGIN_STAGE_STATE')
    commit('reset', null, { root: true })
  },

  setAccountOnboardingStatus({ commit, dispatch }, onboardingStatus) {
    commit('SET_ACCOUNT_ONBOARDING_STATUS', onboardingStatus)

    dispatch('fetchUsersAccountDetails')
  },

  async fetchCurrentAccountRequirements({ commit }) {
    const response = await CustomerServiceAPI.getCurrentAccountRequirements()
      
    commit('SET_CURRENT_ACCOUNT_REQUIREMENTS', response.data)  
  },

  async switchCurrentAccount({ dispatch }, accountId) {
    await dispatch('refreshAccessToken')

    const response = await CustomerServiceAPI.switchUserCurrentAccount(accountId)
      
    return response
  },
}

const mutations = {
  RESET_LOGIN_ERROR(state) {
    state.loginError = {
      errorMessage: '',
      errorCode: null
    }
  },

  SET_LOGIN_ERROR(state, { errorCode, errorMessage }) {
    state.loginError = {
      errorMessage,
      errorCode
    }
  },

  RESET_LOGIN_STAGE_STATE(state) {
    state.loginDetails = {
      cognitoUsername: '',
      sessionID: '',
      currentAuthChallenge: MFA_STAGES.ENTER_USER_CREDS
    }
  },

  SET_REDIRECT_TO_AFTER_LOGIN_PATH(state, path) {
    state.redirectToAfterLoginPath = path
  },

  SET_LOGIN_STAGE_STATE(state, data) {
    state.loginDetails = {
      ...state.loginDetails,
      ...data
    }
  },

  SET_AUTH_TOKENS(state, authTokenData) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      accessToken: authTokenData.accessToken,
      refreshToken:
        authTokenData.refreshToken ||
        state.authenticatedUserDetails.refreshToken,
      idToken: authTokenData.idToken
    }
  },

  RESET_AUTHENTICATED_USER_DATA(state) {
    state.authenticatedUserDetails = {
      ...initialAuthData
    }
  },

  SET_AUTHENTICATED_USER_DATA(state, { email, cognitoUsername }) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      email,
      cognitoUsername
    }
  },

  SET_ACCOUNT_DETAILS(state, accountDetails) {
    state.currentAccountDetails = {
      ...accountDetails
    }
  },

  SET_CURRENT_ACCOUNT_REQUIREMENTS(state, requirements) {
    state.currentAccountDetails = {
      ...state.currentAccountDetails,
      accountRequirements: requirements
    }
  },

  SET_ACCOUNT_ONBOARDING_STATUS(state, onboardingStatus) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      accountDetails: {
        ...state.authenticatedUserDetails.accountDetails,
        onboardingStatus
      }
    }
  },

  SET_ALL_USER_ACCOUNTS(state, accounts) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      accounts: {
        ...accounts
      }
    }
  },

  SET_MFA_KEY(state, secretKey) {
    state.MFASecretKey = secretKey
  },
  RESET_MFA_KEY(state) {
    state.MFASecretKey = ''
  },
  SET_USER_DETAILS(state, userDetails) {
    state.userDetails = userDetails
  },
}

export {
  LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
  MFA_STAGES,
  PASSWORD_REGEX,
  PASSWORD_RESET_STAGES,
  SELLER_ONBOARDING_STATUS,
  BUYER_ONBOARDING_STATUS,
  ACCOUNT_REQUIREMENTS,
  dashboardNotificationForAccountRequirementsMessages
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
