import { extend, localize } from 'vee-validate'
import {
  required,
  email,
  min_value,
  max_value,
  image,
  ext,
  size,
  confirmed,
  regex,
  length,
  digits,
  numeric,
  min
} from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'

const ZIP_CODE_REGEX = /^(\d{1,5})([-\s]\d*)?$/

function parseFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      let errorMessage = ''
      const zipCodes = e.target.result.split(/[\n\s\t,]+/)
          
      let hasError = false
      let zipCodesValidated = []
      for (var i = 0; i < zipCodes.length; i++) {
        if (zipCodes[i]){
          if (zipCodes[i].match(ZIP_CODE_REGEX)) {
            zipCodesValidated.push(zipCodes[i])
          }
          else{
            errorMessage = `${zipCodes[i]} is not supported as a ZIP code. Use 5-digit ZIP codes only.`
            // showErrorMessage(errorMessage)
            hasError = true
            break
          } 
        }
      }
  
      if (!hasError) {
        resolve()
      } else {
        reject(errorMessage)
      }
    }
  
    reader.readAsText(file)
  })
      
}

extend('required', {
  ...required,
  message: field => `${field} cannot be left blank`
})

extend('email', email)

extend('digits', digits)

extend('numeric', numeric)

extend('min', {
  ...min,
  message: (field, extra) => {
    return `${field} must be at least of length ${extra.length}`
  }
})

extend('regex', regex)

extend('confirmed', confirmed)

extend('length', {
  ...length,
  message: (field, extra) => {
    return `${field} length should be ${extra.length}`
  }
})

extend('image', {
  ...image,
  message: () => `Must be an image`
})

extend('ext', {
  ...ext,
  message: (field, extra) =>
    `Only following file extensions are allowed: ${Object.entries(extra)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => {
        return !Number.isNaN(Number(key))
      })
      // eslint-disable-next-line no-unused-vars
      .map(([key, value]) => value)
      .join(', ')}`
})

extend('size', {
  ...size,
  message: (field, extra) =>
    `Size cannot be greater than ${(extra.size / 1024.0).toFixed(2)} MB`
})

extend('zipcode', {
  async validate(file) {
    try {
      await parseFile(file)

      return true
    } catch(e) {
      return e
    }
  },
  // message: `{serverMessage}`
})

extend('min_value', {
  ...min_value,
  message: (field, extra) => `${field} cannot be less than ${extra.min}`
})

extend('min_amount', {
  validate(value, [min]) {
    min = Number(min)
    const numericValue = value ? Number(value.toString().replace('$', '').replace(/,/g, '')) : 0

    return numericValue >= min
  },
  paramNames: ['min'],
  message: (field, extra) => `${field} cannot be less than $${extra[0]}`
})

extend('amount_range', {
  validate(value, [min, max]) {
    min = Number(min)
    max = Number(max)
    const numericValue = value ? Number(value.toString().replace('$', '').replace(/,/g, '')) : 0
    return numericValue >= min && numericValue <= max
  },
  paramNames: ['min', 'max'],
  message: (field, extra) => {
    if (Number(extra[1]) === Number.MAX_SAFE_INTEGER) {
      return `${field} must be greater than $${extra[0] - 1}`
    }

    return `${field} must be in range: $${extra[0]} - $${extra[1]}`
  }
})


extend('max_value', {
  ...max_value,
  message: (field, extra) => `${field} cannot be greater than ${extra.max}`
})

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-zA-Z0-9-]+)*\.[a-zA-Z-]{2,}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      )
    }

    return false
  },
  message: 'This is not a valid website'
})

extend('phone', {
  message(fieldName) {
    return `${fieldName} is not a valid phone number`
  },
  validate(value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() })
    })
  }
})

extend('us_phone', {
  message(fieldName) {
    return `${fieldName} is not a valid US phone number`
  },
  validate(value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() && phone.getCountryCode() === 1 })
    })
  }
})

extend('mobile', {
  message(fieldName) {
    return `${fieldName} is not a valid mobile phone number`
  },
  validate(value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value)
      resolve({ valid: phone.isMobile() })
    })
  }
})

localize({
  en: {
    fields: {
      confirmPassword: {
        confirmed: 'Passwords do not match'
      },

      newPassword: {
        regex:
          'Password must have minimum length 8, must at least have an uppercase letter, a lowercase letter, a special character and a number'
      }
    }
  }
})

extend('account_number', {
  message(fieldName) {
    return `${fieldName} account number can only contain numbers`
  },
  validate(value) {
    const regexForNumericAccountNumber = /^\d{5,}$/ // 5 or more digits
    const regexForMaskedaccountNumber = /^X+\d{4}$/ // any number of X followed by 4 digits
    try {
      return {
        valid: regexForMaskedaccountNumber.test(value) || regexForNumericAccountNumber.test(value)
      }
    } catch (error) {
      return {
        valid: false
      }
    }
  }
})