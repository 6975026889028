import axios from 'axios'
import router from '@/router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import store from '@/store'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// interceptor to catch errors
const errorInterceptor = error => {
  if (!error.response) {
    return Promise.reject(error)
  }

  if (error.response.status === 401) {
    router.push('/login')
  }

  return Promise.reject(error)
}

function requestInterceptor(request) {
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (isAuthenticated) {
    request.headers['Authorization'] = `Bearer ${store.getters['auth/accessToken']}`
  }

  return request
}

async function refreshAuthLogic(failedRequest) {
  try {
    await store.dispatch('auth/refreshAccessToken')
    failedRequest.response.headers['Authorization'] = `Bearer ${store.getters['auth/accessToken']}`
  } catch (error) {
    console.error(error)

    await store.dispatch('auth/logout')

    router.push({
      name: 'Login'
    })

    throw error
  }
}


// Interceptor for responses
const responseInterceptor = response => {
  return response
}

createAuthRefreshInterceptor(httpClient, refreshAuthLogic)
httpClient.interceptors.request.use(requestInterceptor, errorInterceptor)
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient
