import { mapActions } from "vuex"
import { resetCrispSession } from "@/utils/crispChatUtils"

export default {
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
  
    logoutAndRedirect() {
      try {
        this.logout()
        resetCrispSession()
      } catch (error) {
      //
      } finally {
        this.$router.push({ name: 'Login' })
      }
    },

  },
}