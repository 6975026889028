<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Traffic Sources</span>
    </template>

    <template v-slot:content>
      <div>
        <BaseButtonGroup
          id="buyer-create-campaign__traffic-sources__call-type"
          :horizontal="true"
          label="Call Type"
          :selected-option="callType"
          data-cy="button-group-call-type"
          :click-handler="updateCallType"
          internal-platform-input
          :options="['Inbound', 'Outbound']"
          has-tooltip
        />
        <!-- <BaseButtonGroup
          v-if="callType === 'Outbound'"
          id="buyer-create-campaign__traffic-sources__requirements"
          :horizontal="true"
          :selected-option="requirements"
          :click-handler="updateRequirements"
          data-cy="button-group-requirements"
          label="Requirements"
          internal-platform-input
          :options="[
            { text: 'None', value: 'None' },
            { text: 'Jornaya', value: 'Jornaya' },
            { text: 'Trusted Form', value: 'TrustedForm' },
            { text: 'Either', value: 'Either' },
          ]"
          has-tooltip
        /> -->

        <validation-provider
          name="Traffic Sources"
          rules="required"
        >
          <b-form-group
            id="buyer-create-campaign__traffic-sources__allowed-sources"
            slot-scope="{ errors }"
            label="Allowed Sources"
            label-for="input-traffic-sources"
            label-class="custom-form-input-label--internal pr-4"
            label-cols="3"
            content-cols="12"
            content-cols-sm="5"
            content-cols-xl="4"
            label-align="right"
          >
            <template
              slot="label"
            >
              <div>
                Allowed Sources
                <span
                  v-b-tooltip.hover.right
                  :title="fetchTooltip('buyer-create-campaign__traffic-sources__allowed-sources')"
                >

                  <font-awesome-icon
                    class="ml-1 mt-1"
                    :icon="['fas', 'question-circle']"
                  />
                </span>
              </div>
            </template>

            <v-multiselect-listbox
              id="input-traffic-sources"
              v-model="allowedSources"
              name="Traffic Sources"
              data-cy="multiselect-traffic-sources"
              class="msl-multi-select--full-width"
              :options="trafficSourcesFiltered"
              :reduce-display-property="option => option && option.name"
              :reduce-value-property="option => option && option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No source selected"
              :show-select-all-buttons="true"
            />
            <div class="text-danger mt-1">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </validation-provider>

        <BaseFormToggle
          id="buyer-create-campaign__traffic-sources__allow-toll-free-callers"
          v-model="allowTollFreeCallers"
          label="Allow Toll Free Callers"
          data-cy="toggle-allow-toll-free-callers"
          internal-platform-input
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseFormToggle
          id="buyer-create-campaign__traffic-sources__allow-duplicate-callers"
          v-model="allowDuplicateCallers"
          label="Allow Duplicate Callers"
          data-cy="toggle-allow-duplicate-callers"
          internal-platform-input
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseFormToggle
          id="buyer-create-campaign__traffic-sources__allow-third-parties"
          v-model="allowThirdParties"
          label="Allow Third Parties"
          data-cy="toggle-allow-third-parties"
          internal-platform-input
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'TrafficSources',

  mixins: [formInputMixin],

  props: {
    trafficSources: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    trafficSourcesFiltered() {
      if (this.callType) {
        return this.trafficSources.filter(s => s.trafficSourceType === this.callType)
      } else {
        return this.trafficSources
      }
    },
    callType() {
      return this.buyerCampaign.trafficSources.callType
    },
    requirements() {
      return this.buyerCampaign.trafficSources.requirements
    },
    allowThirdParties: {
      get() {
        return this.buyerCampaign.trafficSources.allowThirdParties
      },
      set(value) {
        const trafficSources = { ...this.buyerCampaign.trafficSources }
        trafficSources.allowThirdParties = value
        this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
      }
    },
    allowTollFreeCallers: {
      get() {
        return this.buyerCampaign.trafficSources.allowTollFreeCallers
      },
      set(value) {
        const trafficSources = { ...this.buyerCampaign.trafficSources }
        trafficSources.allowTollFreeCallers = value
        this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
      }
    },
    allowDuplicateCallers: {
      get() {
        return this.buyerCampaign.trafficSources.allowDuplicateCallers
      },
      set(value) {
        const trafficSources = { ...this.buyerCampaign.trafficSources }
        trafficSources.allowDuplicateCallers = value
        this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
      }
    },
    allowedSources: {
      get() {
        return this.buyerCampaign.trafficSources.allowedSources
      },
      set(value) {
        const trafficSources = { ...this.buyerCampaign.trafficSources }
        trafficSources.allowedSources = value
        this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
      }
    }
  },
  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES']),

    updateCallType(option) {
      const trafficSources = { ...this.buyerCampaign.trafficSources }
      trafficSources.callType = option
      trafficSources.allowedSources = []
      this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
    },
    updateRequirements(option) {
      const trafficSources = { ...this.buyerCampaign.trafficSources }
      trafficSources.requirements = option
      this.UPDATE_BUYER_CAMPAIGN_TRAFFIC_SOURCES(trafficSources)
    }
  }
}
</script>

<style lang="scss" scoped></style>
