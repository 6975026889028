<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
    :vid="$attrs.vid"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :rows="$attrs.rows"
      :description="$attrs.description"
      :invalid-feedback="validationContext.errors[0]"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        'pr-4': true
      }"
      :label-cols-sm="horizontal ? 3 : null"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <slot name="preInput" />
      <template
        v-if="hasTooltip"
        slot="label"
      >
        <div>
          {{ $attrs.label }}
          <span
            v-b-tooltip.hover.right
            :title="fetchTooltip($attrs.id)"
          >

            <font-awesome-icon
              class="ml-1 mt-1"
              :icon="['fas', 'question-circle']"
            />
          </span>
        </div>
      </template>


      <b-form-textarea
        :id="$attrs.id"
        v-model="state"
        :number="$attrs.number"
        :state="getValidationState(validationContext)"
        v-bind="$attrs"
        :debounce="debounce"
        :class="{ 'custom-form-input--sm': small, 'custom-form-input': !small, [customInputClass]: true }"
      />

      <slot name="postInput" />

      <slot name="helpText" />
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormTextArea',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: ''
    },

    small: {
      type: Boolean,
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400
    },

    rules: {
      type: [String, Object],
      default: ''
    },

    customInputClass: {
      type: String,
      default: ''
    },

    hasTooltip: {
      type: Boolean,
      default: false
    }

  },

  data() {
    return {
      syncTimeout: null
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.lazy === true) {
          return
        }

        clearTimeout(this.syncTimeout)

        this.syncTimeout = setTimeout(
          () => {
            this.$emit('input', value)
          },
          this.lazy !== false ? this.lazy : 0
        )
      }
    },
  }
}
</script>

<style></style>
