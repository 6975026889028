import httpClient from './httpClient'

const END_POINT = '/creatives'

const CreativesAPI = {
  async uploadSimple({accountId, request, progressCallback}) {
    const url = `${END_POINT}/seller/${accountId}/upload-simple`
    const formData = new FormData()
    formData.append('File', request.file)
    formData.append('FileType', request.fileType)
    return await httpClient.put(url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: e => {
          let progress = (e.loaded / e.total) * 100
          progressCallback({progress: progress, index: request.index})
        },
      })    
  },

  async create({ accountId, request }) {
    const url = `${END_POINT}/seller/${accountId}`    
    return await httpClient.post(url, request)
  },

  async getSellerCreatives({ accountId }) {
    const url = `${END_POINT}/seller/${accountId}`    
    return await httpClient.get(url)
  },

  async getSellerCreative(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}`    
    return await httpClient.get(url)
  },

  async getCreativeReview(payload) {
    const url = `${END_POINT}/buyer/${payload.accountId}/review/${payload.id}`    
    return await httpClient.get(url)
  },

  async getCreativeReviews({ accountId }) {
    const url = `${END_POINT}/buyer/${accountId}`    
    return await httpClient.get(url)
  },

  async reviewBuyerCreative( {accountId, request} ) {
    const url = `${END_POINT}/buyer/${accountId}/review`    
    return await httpClient.post(url, request)
  },

  async enable(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}/enable` 
    return await httpClient.patch(url)
  },

  async disable(payload) {
    const url = `${END_POINT}/seller/${payload.accountId}/creative/${payload.creativeId}/disable`    
    return await httpClient.patch(url)
  }
}

export default CreativesAPI
