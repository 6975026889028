<template>
  <div class="d-flex flex-column mr-3">
    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Payment Information</span>
      </template>

      <template v-slot:content>
        <BankInfoForm
          update-info
          show-resign-alert
          @success="onSuccess"
        />
      </template>
    </Panel>
  </div>
</template>

<script>

import BankInfoForm from './BankInfoForm.vue'

export default {
  name: 'BankInfo',

  title: 'Bank Info',

  components: {
    BankInfoForm
  },

  methods: {
    onSuccess() {
      this.$router.go(0)
    }
  },

}
</script>

<style lang="scss" scoped></style>
