import isEqual from 'lodash.isequal'

export const defaultOpenTime = { hour: 9, minute: 0 }
export const defaultCloseTime = { hour: 21, minute: 0 }

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getTimeFromSettingObject(time) {
  return `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}:00`
}

export function getSettingObjectFromTimeString(time) {
  let splitString = time.split(':')

  return {
    hour: splitString[0],
    minute: splitString[1]
  }
}

export function transformHoursOfOperationsFromStore(hoursOfOperation) {
  return hoursOfOperation.map(dayOperation => {
    return {
      isClosed: !!dayOperation.isClosed,
      inverted: !!dayOperation.inverted,
      openTime: getTimeFromSettingObject(dayOperation.openTime),
      closeTime: getTimeFromSettingObject(dayOperation.closeTime),
      showAddBreakForm: false,
      breaks: [...dayOperation.breaks]
    }
  })
}

export function transformHoursOfOperationsFromUI(hoursOfOperation) {
  return hoursOfOperation.map(dayOperation => {
    return {
      isClosed: dayOperation.isClosed,
      inverted: dayOperation.inverted,
      openTime: getSettingObjectFromTimeString(dayOperation.openTime),
      closeTime: getSettingObjectFromTimeString(dayOperation.closeTime),
      breaks: [...dayOperation.breaks]
    }
  })
}

export function getDefaultHoursOfOperation(
  openTime = defaultOpenTime, 
  closeTime = defaultCloseTime,
  isClosed = false,
  inverted = false,
  breaks = []) {
  const hoursOfOperation = []
  
  daysOfWeek.forEach(() => {
    hoursOfOperation.push({
      isClosed,
      inverted,
      openTime,
      closeTime,
      breaks: [...breaks]
    })
  })

  return hoursOfOperation
}

export function isBasic(hoursOfOperation) {
  let basic = true

  let compareDay = hoursOfOperation[0]

  hoursOfOperation.forEach(function(day) {
    if (!isEqual(compareDay, day)) {
      basic = false
    }
  })
  
  return basic
}