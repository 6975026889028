<template>
  <li
    class="sidebar__nav-menu-list-item"
    @click="$emit('click')"
  >
    <BaseLink
      v-bind="dynamicProps"
      :href="href"
      class="nav-link sidebar__nav-menu-link"
    >
      <font-awesome-icon
        class="fa-fw mr-2 sidebar__nav-menu-icon"
        :icon="menuIcon"
      />
      <span class="sidebar__nav-menu-text">
        {{ menuText }}
      </span>
    </BaseLink>
  </li>
</template>

<script>
export default {
  name: 'SidebarNavItem',

  props: {
    route: {
      type: [Object, String],
      default() {
        return ''
      }
    },

    menuText: {
      type: String,
      default: ''
    },

    href: {
      type: String,
      default: ''
    },

    menuIcon: {
      type: Array,
      default() {
        return []
      }
    },
  },

  computed: {
    dynamicProps() {
      return this.route ? { to: this.route } : {}
    }
  },
}
</script>

<style>

</style>