<template>
  <b-form-group
    :label="label"
    :label-class="{
      'custom-form-input-label--internal': internalPlatformInput,
      'custom-form-input-label': !internalPlatformInput,
      [labelClass]: labelClass,
      'pr-4': true
    }"
    :label-cols="horizontal ? '3' : null"
    :content-cols="horizontal ? width : null"
    :label-align="horizontal ? 'right' : ''"
  >
    <b-button
      :type="type"
      :class="{ 'text-nowrap': nowrap, [buttonClass]: buttonClass, 'custom-button--flat': customButton }"
      :variant="variant"
      :size="size"
      :disabled="isDisabled"
      @click="$emit('click')"
    >
      <b-spinner
        v-if="loading"
      />
      
      <span v-show="!loading">
        <slot />
      </span>
    </b-button>
  </b-form-group>
</template>

<script>
export default {
  name: 'BaseButtonLoader',
  props: {
    internalPlatformInput: {
      type: Boolean,
      default: false
    },

    customButton: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'button'
    },

    label: {
      type: String,
      default: ''
    },

    labelClass: {
      type: String,
      default: ''
    },

    horizontal: {
      type: Boolean,
      default: false
    },

    variant: {
      type: String,
      default: 'default'
    },

    width: {
      type: Number,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },

    nowrap: {
      type: Boolean,
      default: false
    },

    buttonClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || this.loading
    }
  }
}
</script>

<style>


</style>