import httpClient from './httpClient'

const END_POINT = '/registration/signup'

const RegistrationAPI = {
  submitBuyerRequest(buyerRequestDetails) {
    const url = `${END_POINT}/submit-request/buyer`

    return httpClient.post(url, buyerRequestDetails)
  },
  submitSellerRequest(sellerRequestDetails) {
    const url = `${END_POINT}/submit-request/seller`
    return httpClient.post(url, sellerRequestDetails)
  }
}

export default RegistrationAPI