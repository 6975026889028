<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Offers</span>
    </template>

    <template v-slot:content>
      <b-table
        :fields="fields"
        data-cy="table-seller-dashboard-offers"
        :items="offers"
        thead-class="text-nowrap"
        tbody-tr-class="text-nowrap"
      >
        <template #cell(spent)="row">
          <div class="text-nowrap">
            {{ `$${row.item.spent.toLocaleString()}` }}
          </div>
        </template>


        <template #cell(calls)="row">
          <div class="text-nowrap">
            {{ row.item.calls.toLocaleString() }}
          </div>
        </template>

        <template #cell(billable)="row">
          <div class="text-nowrap">
            {{ row.item.billable.toLocaleString() }}
          </div>
        </template>

        <template #cell(pings)="row">
          <div class="text-nowrap">
            {{ row.item.pings.toLocaleString() }}
          </div>
        </template>

        <template #cell(conversionPercentage)="row">
          <div class="text-nowrap">
            {{ `${row.item.conversionPercentage.toFixed(2)} %` }}
          </div>
        </template>

        <template #cell(qualityScore)="row">
          <div class="text-nowrap">
            {{ `${row.item.qualityScore.toFixed(2)} / 10` }}
          </div>
        </template>

        <template #cell(winRatio)="row">
          <div class="text-nowrap">
            {{ `${row.item.winRatio.toFixed(2)} %` }}
          </div>
        </template>

        <template #cell(avgBid)="row">
          <div class="text-nowrap">
            {{ `$${row.item.avgBid.toLocaleString()}` }}
          </div>
        </template>

        <template #cell(revenue)="row">
          <div class="text-nowrap">
            {{ `$${row.item.revenue.toLocaleString()}` }}
          </div>
        </template>

        <template #cell(rpc)="row">
          <div class="text-nowrap">
            {{ `$${(row.item.revenue / row.item.calls).toFixed(2).toLocaleString()}` }}
          </div>
        </template>


        <template #cell(status)="row">
          <div class="text-nowrap">
            <CampaignStatusButton
              :campaign-id="row.item.id"
              :status="row.item.status"
              :pause-campaign="pauseSellerCampaign"
              :resume-campaign="resumeSellerCampaign"
            />
          </div>
        </template>
      </b-table>
    </template>
  </Panel>
</template>

<script>
import CampaignStatusButton from '@/components/CampaignStatusButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'SellerDashboardOffers',
  components: {
    CampaignStatusButton,
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'type', label: 'Type' },
        { key: 'pings', label: 'Pings' },
        { key: 'calls', label: 'Calls' },
        { key: 'billable', label: 'Billable' },
        { key: 'conversionPercentage', label: 'Conv. %' },
        { key: 'winRatio', label: 'Win Ratio' },
        { key: 'avgBid', label: 'Average Bid' },
        { key: 'qualityScore', label: 'Quality Score' },
        { key: 'revenue', label: 'Revenue' },
        { key: 'rpc', label: 'RPC' },
        {
          key: 'status',
          label: 'Status',
          tdAttr: (value) => {
            return {
              'data-cy': 'status-badge',
              'data-cy-status': value
            }
          }
        },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },
  methods: {
    ...mapActions('campaign', ['pauseSellerCampaign', 'resumeSellerCampaign'])
  }
}
</script>

<style lang="scss" scoped></style>
