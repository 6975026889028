import httpClient from './httpClient'

const END_POINT = '/auth'

const AuthAPI = {

  login(data) {
    return httpClient.post(`${END_POINT}/token`, data)
  },

  respondToAuthChallenge({
    challengeName,
    challengeResponseAnswer,
    sessionID,
    username
  }) {
    return httpClient.post(`${END_POINT}/challenge-response`, {
      challengeName,
      challengeResponseAnswer,
      sessionID,
      username
    })
  },

  refreshAuthDataUsingRefreshToken({ refreshToken, cognitoUsername }) {
    return httpClient.post(`${END_POINT}/token/refresh`, {
      refreshToken,
      username: cognitoUsername
    })
  },

  associateSoftwareMFA({ accessToken }) {
    return httpClient.post(`${END_POINT}/associate-software-mfa`, {
      parameterName: 'accessToken',
      parameterValue: accessToken
    })
  },

  verifySoftwareMFA({ accessToken, softwareToken }) {
    return httpClient.post(`${END_POINT}/verify-software-mfa`, {
      parameterName: 'accessToken',
      parameterValue: accessToken,
      softwareToken
    })
  },

  forgotPassword({ email }) {
    return httpClient.post(`${END_POINT}/forgot-password`, {
      email
    })
  },

  setNewPassword({ email, confirmationCode, newPassword }) {
    return httpClient.post(`${END_POINT}/reset-password`, {
      email,
      confirmationCode,
      newPassword
    })
  }

}

export default AuthAPI
