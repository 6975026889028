<template>
  <div class="d-flex">
    <div class="campaign-bid-input___symbols campaign-bid-input___dollar-sign">
      <font-awesome-icon
        class="campaign-bid-input___symbols___dollar_icon"
        :icon="['fas', 'dollar-sign']"
      />
    </div>
    <b-form-group class="width-95 my-0 py-0 campaign-bid-input___current-value">
      <b-form-input
        v-model="currentValue"
        max="9999.99"
        type="number"
        min="0.01"
        step="0.01"
        :formatter="formatter"
        class="custom-form-input--sm campaign-bid-input___current-value___input"
        @input="updateBidOnInput"
      />
    </b-form-group>
    <div
      v-long-press="500"
      style="cursor: pointer;"
      class="campaign-bid-input___symbols campaign-bid-input___increase-bid"
      @long-press-start="onLongPressStartIncrement"
      @long-press-stop="onLongPressStopIncrement"
      @click.prevent="updateBid(currentValue + 0.01)"
    >
      <font-awesome-icon
        class="campaign-bid-input___symbols___change_icon"
        :icon="['fas', 'angle-up']"
      />
    </div>
    <div
      v-long-press="500"
      style="cursor: pointer;"
      class="campaign-bid-input___symbols campaign-bid-input___decrease-bid mr-1"
      @long-press-start="onLongPressStartDecrement"
      @long-press-stop="onLongPressStopDecrement"
      @click.prevent="updateBid(currentValue - 0.01)"
    >
      <font-awesome-icon
        class="campaign-bid-input___symbols___change_icon"
        :icon="['fas', 'angle-down']"
      />
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { showErrorMessage } from '@/notification-utils'
import LongPress from 'vue-directive-long-press'

export default {
  name: 'CampaignBidInput',
  directives: {
    'long-press': LongPress
  },
  props: {
    campaignId: {
      type: String,
      default: null
    },
    initialValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      disabled: false,
      currentValue: 0,
      interval: null
    }
  },
  created() {
    this.currentValue = this.initialValue
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignBid']),
    async updateBidOnInput(value) {
      value = Number(value)
      this.currentValue = value
      try {
        await this.updateCampaignBid({ campaignId: this.campaignId, bidValue: this.currentValue })
      } catch (error) {
        showErrorMessage('Failed to update campaign bid')
      }
    },
    formatter(value) {
      const oldValue = this.currentValue
      if (value.indexOf('.') >= 0) {
        let [wholeNumber, decimal] = value.split('.')
        decimal = decimal.slice(0, 2)
        return Number(value) > 9999.99 ? oldValue : `${wholeNumber}.${decimal}`
      } else {
        return Number(value) > 9999.99 ? oldValue : value
      }
    },
    async updateBid(value) {
      value = Number(value.toFixed(2))
      if (value >= 9999.99) return
      const oldValue = this.currentValue
      this.currentValue = value
      try {
        await this.updateCampaignBid({ campaignId: this.campaignId, bidValue: value })
      } catch (error) {
        showErrorMessage('Failed to update campaign bid')
        this.currentValue = oldValue
      }
    },
    onLongPressStartIncrement () {
      this.interval = setInterval(async () => {
        if (this.currentValue >= 9999.98) return
        this.currentValue = Number((this.currentValue + 0.01).toFixed(2))
      }, 100)
    },
    async onLongPressStopIncrement () {
      clearInterval(this.interval)
      await this.updateBid(this.currentValue)
    },
    onLongPressStartDecrement () {
      this.interval = setInterval(async () => {
        this.currentValue = Number((this.currentValue - 0.01).toFixed(2))
      }, 100)
    },
    async onLongPressStopDecrement () {
      clearInterval(this.interval)
      await this.updateBid(this.currentValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-bid-input___symbols {
  width: 20px;
  height: 33px;
  border: 1px solid var(--component-custom-input-border-color);
  color: white;
}

.campaign-bid-input___dollar-sign {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.campaign-bid-input___decrease-bid {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}


.campaign-bid-input___symbols___dollar_icon {
  margin-top: 0.5rem;
  margin-left: 0.33rem;
}

.campaign-bid-input___symbols___change_icon {
  margin-top: 0.5rem;
  margin-left: 0.25rem;
  &:hover {
    outline: none;
    border-color: $login-font;
    cursor: pointer;
  }
}



.width-95 {
  width: 95px;
}

.campaign-bid-input___current-value {
  height: 33px;
}

.campaign-bid-input___current-value___input {
  height: 33px;
  border-radius: unset;
}


</style>
