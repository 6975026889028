import { mapActions, mapGetters, mapMutations, mapState } from "vuex"

import formatCurrency from '@/utils/formatCurrency'

const AUTO_RELOAD_INTERVAL = 2 * 60 * 1000 // 2 mins interval

export default {
  data() {
    return {
      calls: [],
      pageSize: 25,
      isLoading: false,
      totalItems: 0,
      currentPage: 1,
      isExportLoading: false
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accountId'
    ]),

    ...mapState('callLogs', [
      'dateRange',
      'timeZone',
      'autoRefresh'
    ])
  },

  watch: {
    autoRefresh (newValue) {
      if (newValue) {
        this.autoReloadTimer = setInterval(async () => {
          await Promise.all([
            this.reloadData(),
          ])
        }, AUTO_RELOAD_INTERVAL)
      } else {
        this.clearAutoReloadInterval()
      }
    },
  },

  created () {
    this.autoReloadTimer = null

    this.getCallLogsTimeZone()
  },

  destroyed () {
    this.clearAutoReloadInterval()
  },

  methods: {
    ...mapActions('callLogs', ['setCallLogsTimeZone', 'getCallLogsTimeZone']),

    ...mapMutations('callLogs', ['TOGGLE_AUTO_REFRESH', 'SET_DATE_RANGE']),

    formatCurrency(value) {
      return formatCurrency(value, undefined, undefined, true)
    },

    async reloadData() {
      this.$refs.callLogsTable.refresh()
    },

    resetTable() {
      this.currentPage = 1
      this.totalItems = 0
    },

    clearAutoReloadInterval() {
      if (this.autoReloadTimer) {
        clearInterval(this.autoReloadTimer)
        this.autoReloadTimer = null
      }
    },

    async handleTimeZoneUpdate(value) {
      this.setCallLogsTimeZone(value)

      this.resetTable()

      await this.reloadData()
    },

    async handleDateRangeUpdate([from, to]) {
      this.SET_DATE_RANGE([from, to])
      this.resetTable()

      await this.reloadData()
    },
  }, 
}