<template>
  <Panel
    loader-text="Loading Payment Methods"
    :loading="isLoading"
  >
    <template v-slot:header>
      <span data-cy="heading">Payment Methods</span>
    </template>

    <template v-slot:content>
      <b-table
        data-cy="table-payment-methods"
        :tbody-tr-attr="{ 'data-cy': 'table-row' }"
        :items="paymentMethods"
        :fields="fields"
      >
        <template #cell(default)="row">
          <div v-if="row.item.default">
            <font-awesome-icon
              class="text-warning"
              :icon="['fas', 'star']"
            />
          </div>
          <div
            v-else
            class="cursor-pointer"
          >
            <font-awesome-icon
              v-if="statusNotDeclinedOrExpired(row.item._Status)"
              :icon="['fas', 'star']"
              @click="setAsDefault(row.item.id)"
            />
          </div>
        </template>
        <template #cell(enabled)="row">
          <div v-if="row.item.enabled">
            <font-awesome-icon
              class="text-success"
              :icon="['fas', 'check']"
            />
          </div>
          <div v-else>
            <font-awesome-icon
              class="text-danger"
              :icon="['fas', 'times']"
            />
          </div>
        </template>

        <template #cell(cardType)="row">
          <img
            :alt="row.item.cardType"
            :src="`/img/${row.item.cardType}.png`"
            width="40px"
          >
        </template>

        <template #cell(_Status)="row">
          <div
            :class="{
              'text-success': row.value === 'Approved',
              'text-danger': row.value !== 'Approved'
            }"
          >
            {{ startCase(row.value) }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-row
            class="bd-highlight mb-2"           
          >
            <b-button
              v-if="!row.item.default || paymentMethods.length === 1"           
              :data-cy-id="row.item.id"                   
              data-cy="button-remove"
              class="mr-3"
              variant="danger"              
              size="sm"
              @click="showConfirmRemoveModal(row.item)"
            >
              Remove card
            </b-button>
            <b-button
              v-if="row.item._Status === 'Unverified'"
              :to="{
                name: 'UploadKYCDocs',
                params: { paymentMethodId: row.item.id }
              }"
              :data-cy-id="row.item.id"
              data-cy="button-verify"              
              variant="primary"
              size="sm"
            >
              Verify
            </b-button>
          </b-row>
        </template>
      </b-table>

      <b-modal
        id="remove-credit-card-modal"
        ref="remove-credit-card-modal"
        header-class="custom-modal"
        body-class="custom-modal"
        footer-class="custom-modal"
        title="Remove a Credit Card"     
        button-size="sm"        
        @close="hideConfirmRemoveModal"   
      >
        <p class="mb-0">        
          Are you sure you want to remove credit card <strong>{{ paymentMethodToRemove.name }}</strong>?
        </p>

        <template #modal-footer>        
          <ButtonLoader
            type="button"
            variant="danger"
            size="sm"
            :loading="isRemoveButtonLoading"
            @click="removeCreditCard"            
          >
            Remove
          </ButtonLoader>
          
          <b-button
            type="button"
            variant="secondary"
            size="sm"          
            @click="hideConfirmRemoveModal"
          >
            Cancel
          </b-button>
        </template>
      </b-modal>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'PaymentMethods',
  
  title: 'Payment Methods',

  data() {
    return {
      isLoading: true,
      isRemoveButtonLoading: false,
      fields: Object.freeze([
        {
          key: 'default',
          sortable: true,
          tdAttr: {
            'data-cy': 'table-column-default'
          }
        },
        {
          key: 'enabled',
          sortable: true,
          tdAttr: {
            'data-cy': 'table-column-enabled'
          }
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'maskedCC'
        },
        {
          key: '_Status',
          sortable: true,
          tdAttr: {
            'data-cy': 'table-column-status'
          }
        },
        {
          key: 'cardType',
          sortable: true
        },
        {
          key: 'actions',
          tdAttr: {
            'data-cy': 'table-column-actions'
          }
        }
      ]),
      paymentMethodToRemove: {
        name: null,
        id: null
      }
    }
  },
  computed: {
    ...mapState('billing', ['paymentMethods']),
    ...mapGetters('auth', ['accountDetails'])
  },
  async created() {
    try {
      this.isLoading = true
      await this.fetchPaymentMethods({ accountId: this.accountDetails.id })
    } catch (error) {
      showErrorMessage(error.response?.data?.title)
    } finally {
      this.isLoading = false
    }
    
  },
  methods: {
    ...mapActions('billing', [
      'fetchPaymentMethods',
      'setPaymentMethodAsDefault',
      'removePaymentMethod'
    ]),
    async setAsDefault(paymentMethodId) {
      await this.setPaymentMethodAsDefault({
        accountId: this.accountDetails.id,
        paymentMethodId
      })
      await this.fetchPaymentMethods({ accountId: this.accountDetails.id })
    },
    statusNotDeclinedOrExpired(status) {
      return ['Approved', 'Unverified', 'ApprovalPending'].includes(status)
    },

    showConfirmRemoveModal(item) {
      this.$bvModal.show('remove-credit-card-modal')
      this.paymentMethodToRemove = item
    },

    hideConfirmRemoveModal() {
      this.$bvModal.hide('remove-credit-card-modal')
      this.paymentMethodToRemove = {}
    },

    async removeCreditCard() {
      this.isRemoveButtonLoading = true

      try {
        await this.removePaymentMethod({
          accountId: this.accountDetails.id,
          paymentMethodId: this.paymentMethodToRemove.id
        })

        showSuccessMessage('Successfully removed credit card ' + this.paymentMethodToRemove.name)
        await this.fetchPaymentMethods({ accountId: this.accountDetails.id })

        this.hideConfirmRemoveModal()
        this.paymentMethodToRemove = {}
      }
      catch (error) {
        showErrorMessage(error.response?.data)
      }
      finally {
        this.isRemoveButtonLoading = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
