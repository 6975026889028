<template>
  <div>
    <Panel>
      <template v-slot:header>
        <span data-cy="heading">Account Summary</span>
      </template>

      <template v-slot:content>
        <div class="w-50">
          <b-card-group deck>
            <b-card 
              bg-variant="success" 
              text-variant="white" 
              class="text-center"
            >
              <template #header>
                <span style="color: #00ff61;">
                  <font-awesome-icon 
                    v-b-tooltip.hover 
                    title="Your total account balance"
                    :icon="['fas', 'dollar-sign']" 
                  />
                </span> Available Balance
              </template>
              <b-card-text> {{ formatCurrency(accountSummary.cashBalance) }} </b-card-text>
            </b-card>
            <b-card 
              v-if="!isBuyerAccount" 
              bg-variant="primary" 
              text-variant="white" 
              class="text-center"
            >
              <template #header>
                <span style="color: #00cdff;">
                  <font-awesome-icon 
                    v-b-tooltip.hover 
                    title="Your estimated future payouts"
                    :icon="['fas', 'hand-holding-usd']" 
                  />
                </span> Pending Payments
              </template>
              <b-card-text> {{ formatCurrency(sellerPendingBalance) }} </b-card-text>
            </b-card>
          </b-card-group>
        </div>
      </template>
    </Panel>

    <CreditCardCharges 
      v-if="isBuyerAccount && accountDetails.billingConfiguration.prePay" 
    />

    <Invoices v-if="!isBuyerAccount || !accountDetails.billingConfiguration.prePay" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'
import CreditCardCharges from '../buyer/CreditCardCharges.vue'
import Invoices from '../billing/Invoices.vue'

export default {
  name: 'MyAccount',

  components: {
    CreditCardCharges,
    Invoices,
  },

  data() {

    return {
      loading: false,
      details: {}
    }
  },
  computed: {
    ...mapState('billing', ['accountSummary']),
    ...mapGetters('auth', ['isBuyerAccount', 'accountDetails']),
    ...mapGetters('billing', ['sellerPendingBalance']),
  },
  async created() {
    await this.getAccountBillingSummary()
  },
  methods: {
    ...mapActions('billing', ['getAccountBillingSummary']),
    formatCurrency,

  }
}
</script>

<style lang="scss" scoped>
</style>
