<template>
  <Panel>
    <template v-slot:header>
      Upload KYC Docs
    </template>

    <template v-slot:content>
      <div class="d-flex w-50 justify-content-center">
        <KYCForm
          :selected-card="paymentMethodId"
          :disable-select="true"
          :is-loading="isLoading"
          @submit="onSubmit"
          @success="onSuccess"
          @error="onError"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import KYCForm from '@/components/onboarding/KYCForm.vue'

export default {
  name: 'AddCreditCard',
  components: {
    KYCForm,
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    paymentMethodId() {
      return this.$route.params.paymentMethodId
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true
    },
    onError() {
      this.isLoading = false
    },
    async onSuccess() {
      this.isLoading = false
      this.$router.push({ name: 'PaymentMethods' })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
