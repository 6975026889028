<template>
  <div>
    <b-row>
      <b-col cols="12">
        <BankInfoAlert :show="showResignAlert" />
      </b-col>

      <b-col
        cols="12"
        sm="12"
        :lg="!fullLayout ? 6 : 12"
      >
        <validation-observer
          ref="formValidation"
          v-slot="{ handleSubmit }"
        >
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit(onSubmit)"
          >
            <BaseFormInput
              id="beneficiaryName"
              v-model="beneficiaryName"
              data-cy="input-account-name"
              internal-platform-input
              :small="true"
              placeholder="Enter Beneficiary Name"
              name="Beneficiary Name"
              label="Beneficiary Name"
              type="text"
              required
            />
            <BaseFormInput
              id="address1"
              v-model="address1"
              data-cy="input-address1"
              internal-platform-input
              :small="true"
              placeholder="Enter Address"
              name="Beneficiary Address"
              label="Beneficiary Address"
              type="text"
              required
            />
            <BaseFormInput
              id="address2"
              v-model="address2"
              data-cy="input-address2"
              internal-platform-input
              :small="true"
              placeholder="Enter Apartment, suite, unit number"
              name="Address 2 (Optional)"
              label="Address 2 (Optional)"
              type="text"
            />
            <b-form-row>
              <b-col
                sm
              >
                <BaseFormInput
                  id="city"
                  v-model="city"
                  data-cy="input-city"
                  internal-platform-input
                  :small="true"
                  placeholder="Enter City"
                  name="City"
                  label="City"
                  type="text"
                  required
                />
              </b-col>
              <b-col
                sm="3"
              >
                <BaseFormSelect
                  id="state"
                  v-model="state"
                  internal-platform-input
                  :options="usStatesWithPlaceholder"
                  label="State"
                  small
                  required
                />
              </b-col>
              <b-col
                sm="3"
              >
                <BaseFormInput
                  id="zip"
                  v-model="zip"
                  data-cy="input-zip"
                  internal-platform-input
                  :small="true"
                  placeholder="Enter Zipcode"
                  name="Zipcode"
                  label="Zipcode"
                  type="number"
                  input-class="text-left"
                  required
                  rules="length:5"
                />
              </b-col>
            </b-form-row>
            <BaseFormInput
              id="contactPerson"
              v-model="contactPerson"
              data-cy="input-contact-person"
              internal-platform-input
              :small="true"
              placeholder="Enter Beneficiary Contact Name"
              name="Beneficiary Contact Name"
              label="Beneficiary Contact Name"
              type="text"
              required
            />
        
            <BaseFormInput
              id="email"
              v-model="email"
              internal-platform-input
              small
              name="Email"
              label="Beneficiary Contact Email"
              placeholder="Enter Beneficiary Contact Email"
              type="email"
              rules="email"
              required
            />
            <BaseFormInput
              id="cellPhone"
              v-model="cellPhone"
              internal-platform-input
              small
              name="Phone"
              label="Beneficiary Contact Phone"
              placeholder="Enter Beneficiary Contact Phone"
              type="tel"
              rules="length:10"
              required
            />
        
            <BaseFormInput
              id="ein"
              v-model="ein"
              data-cy="input-ein"
              internal-platform-input
              small
              placeholder="XX-XXXXXXX"
              name="EIN"
              label="Beneficiary EIN"
              type="text"
              required
              width="5"
              :rules="{ regex: EIN_REGEX }"
              @input="handleEinChange"
            >
              <template v-slot:helpText>
                <b-form-text>
                  <em>EIN must be of the format XX-XXXXXXX</em>
                </b-form-text>
              </template>
            </BaseFormInput>
            <BaseFormInput
              id="bankName"
              v-model="bankName"
              data-cy="input-bank-name"
              internal-platform-input
              :small="true"
              placeholder="Enter Bank Name"
              name="Bank Name"
              label="Bank Name"
              type="text"
              required
            />

            <BaseFormInput
              v-if="!updateInfo || !isAccountNumberRedacted"
              id="accountNumber"
              v-model="accountNumber"
              data-cy="input-account-number"
              internal-platform-input
              :small="true"
              placeholder="Enter Account Number"
              name="Account Number"
              label="Account Number"
              width="5"
              input-class="text-left"
              type="number"
              rules="min:5"
              required
            />

            <BaseFormInput
              v-if="updateInfo && isAccountNumberRedacted"
              id="accountNumber"
              v-model="bankInfo.maskedAccountNumber"
              data-cy="input-account-number"
              internal-platform-input
              :small="true"
              name="Account Number"
              label="Account Number"
              width="5"
              type="text"
              disabled
            />

            <BaseButtonLoader
              v-if="updateInfo && isAccountNumberRedacted"
              color="primary"
              :loading="isLoadingAccountNumber"
              size="sm"
              @click="getUnmaskedAccountNumber"
            >
              Edit Account Number
            </BaseButtonLoader>
            <BaseFormInput
              id="routingNumber"
              v-model="routingNumber"
              input-class="text-left"
              data-cy="input-routing-number"
              internal-platform-input
              :small="true"
              placeholder="Enter Routing Number"
              name="Routing Number"
              label="Routing Number"
              type="number"
              rules="length:9"
              width="5"
              required
            />
            
            <div class="d-flex mt-4">
              <BaseButtonLoader
                id="submit-button"
                :loading="isLoading"
                button-class="custom-button--white-hover-grad pulse"
                class="mb-0"
                type="submit"
              >
                Submit
              </BaseButtonLoader>

              <BaseButtonLoader
                v-if="updateInfo && onboarding"
                button-class="custom-button--white-hover-grad pulse ml-3"
                type="button"
                @click="$emit('cancel')"
              >
                Go Back to Signing
              </BaseButtonLoader>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>


    <b-modal
      id="update-bank-info-modal"
      ref="update-bank-info-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Update"
      ok-title="Update"
      button-size="sm"
    >
      <p class="mb-0">
        Are you sure you want to update the bank information?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('update-bank-info-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="saveBankInfoForm"
        >
          Update
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import { mapState, mapActions, mapGetters } from 'vuex'
import set from 'lodash.set'

import BankInfoAlert from './BankInfoAlert.vue'
import { EIN_REGEX } from '@/store/modules/billing.store'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'BankInfoForm',

  components: {
    BankInfoAlert,
  },

  props: {
    updateInfo: {
      type: Boolean,
      default: false
    },

    fullLayout: {
      type: Boolean,
      default: false
    },

    showResignAlert: {
      type: Boolean,
      default: false
    },

    onboarding: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      isLoadingAccountNumber: false,
      EIN_REGEX: Object.freeze(EIN_REGEX)
    }
  },

  computed: {
    ...mapState('common-entities', [
      'US_STATES'
    ]),

    ...mapState('billing', ['bankInfo']),

    ...mapState('auth', [
      'userDetails'
    ]),

    ...mapGetters('auth', [
      'accountDetails'
    ]),

    isAccountNumberRedacted() {
      return !this.bankInfo.accountNumber
    },  

    usStatesWithPlaceholder() {
      let stateOptions = this.US_STATES.map(state => {
        return {
          value: state.abbreviation,
          text: state.abbreviation
        }
      })

      stateOptions.unshift({
        value: '',
        text: 'Select state'
      })

      return stateOptions
    },

    beneficiaryName: {
      get() {
        return this.bankInfo.beneficiaryName
      },
      set(value) {
        this.updatePropBankInfo('beneficiaryName', value)
      }
    },

    contactPerson: {
      get() {
        return this.bankInfo.contactPerson
      },
      set(value) {
        this.updatePropBankInfo('contactPerson', value)
      }
    },

    address1: {
      get() {
        return this.bankInfo.address.address1
      },
      set(value) {
        let tempAddress = {
          ...this.bankInfo.address,
          address1: value
        }
        
        this.updatePropBankInfo('address', tempAddress)
      }
    },

    address2: {
      get() {
        return this.bankInfo.address.address2
      },
      set(value) {
        let tempAddress = {
          ...this.bankInfo.address,
          address2: value
        }
        
        this.updatePropBankInfo('address', tempAddress)
      }
    },

    city: {
      get() {
        return this.bankInfo.address.city
      },
      set(value) {
        let tempAddress = {
          ...this.bankInfo.address,
          city: value
        }
        
        this.updatePropBankInfo('address', tempAddress)
      }
    },

    state: {
      get() {
        return this.bankInfo.address.state
      },
      set(value) {
        let tempAddress = {
          ...this.bankInfo.address,
          state: value
        }
        
        this.updatePropBankInfo('address', tempAddress)
      }
    },

    zip: {
      get() {
        return this.bankInfo.address.zip
      },
      set(value) {
        let tempAddress = {
          ...this.bankInfo.address,
          zip: value
        }
        
        this.updatePropBankInfo('address', tempAddress)
      }
    },

    cellPhone: {
      get() {
        return this.bankInfo.cellPhone
      },
      set(value) {
        this.updatePropBankInfo('cellPhone', value)
      }
    },

    email: {
      get() {
        return this.bankInfo.email
      },
      set(value) {
        this.updatePropBankInfo('email', value)
      }
    },

    ein: {
      get() {
        return this.bankInfo.ein
      },
      set(value) {
        this.updatePropBankInfo('ein', value)
      }
    },

    bankName: {
      get() {
        return this.bankInfo.bankName
      },
      set(value) {
        this.updatePropBankInfo('bankName', value)
      }
    },

    accountNumber: {
      get() {
        return this.bankInfo.accountNumber
      },
      set(value) {
        this.updatePropBankInfo('accountNumber', value)
      }
    },

    routingNumber: {
      get() {
        return this.bankInfo.routingNumber
      },
      set(value) {
        this.updatePropBankInfo('routingNumber', value)
      }
    },

    isFormInvalid() {
      return this.$refs.formValidation.invalid
    }
  },

  watch: {
    accountDetails: {
      immediate: true,
      handler() {
        if (this.bankInfo.address.city == null) {
          this.fillFormFromAccountDetails()
        }
      }
    },

    userDetails: {
      immediate: true,
      handler() {
        if (this.bankInfo.contactPerson == null) {
          this.fillFormFromUserDetails()
        }
      }
    }
  },

  async created() {
    await this.getBankInfo(this.accountDetails.id)
  },

  methods: {
    ...mapActions('billing', ['getBankInfo', 'setBankInfo', 'saveBankInfo', 'getUnmaskedBankInfo']),
    ...mapActions('auth', [
      'fetchUsersAccountDetails'
    ]),

    async getUnmaskedAccountNumber() {
      try {
        this.isLoadingAccountNumber = true
        await this.getUnmaskedBankInfo(this.accountDetails.id)
      } catch (error) {
        showErrorMessage('Unable to fetch unmasked account details')
      } finally {
        this.isLoadingAccountNumber = false
      }
    },

    fillFormFromAccountDetails() {
      const address = {
        city: this.accountDetails?.companyAddress.city,
        state: this.checkValidUSState(this.accountDetails?.companyAddress.state) ? this.accountDetails?.companyAddress.state : undefined,
        zip: this.accountDetails?.companyAddress.zip,
        address1: this.accountDetails?.companyAddress.addressLine1,
        address2: this.accountDetails?.companyAddress.addressLine2
      }

      this.updatePropBankInfo('address', address)
      this.updatePropBankInfo('beneficiaryName', this.accountDetails?.name)

      this.fillCellPhone()
    },

    fillFormFromUserDetails() {
      this.updatePropBankInfo('contactPerson', `${this.userDetails.name} ${this.userDetails.lastName}`)
      this.updatePropBankInfo('email', this.userDetails.email)
    },

    fillCellPhone() {
      try {
        let phone = new PhoneNumber(this.accountDetails?.representative?.cellPhone)
        this.updatePropBankInfo('cellPhone', phone.a.number.significant)
      } catch (error) {
        //
      }
    },

    checkValidUSState(state) {
      return this.US_STATES.some(s => s.abbreviation === state)
    },

    async onSubmit() {
      if (this.updateInfo) {
        this.$bvModal.show('update-bank-info-modal')
      } else {
        await this.saveBankInfoForm()
      }
    },

    async saveBankInfoForm() {
      try {
        this.isLoading = true

        await this.saveBankInfo({ bankInfo: this.bankInfo, mode: this.updateInfo ? 'edit' : 'create' })

        await this.fetchUsersAccountDetails()

        showSuccessMessage('Bank info saved successfully')

        this.$emit('success')
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
        
        this.$emit('error')
      } finally {
        this.isLoading = false
      }
    },

    updatePropBankInfo(prop, value) {
      const tempBankInfo = {
        ...this.bankInfo
      }

      set(tempBankInfo, prop, value)

      this.setBankInfo(tempBankInfo)
    },

    handleEinChange(value) {
      if (value.length === 2) {
        this.ein = value + '-'
      }
    }
  }
}
</script>

<style>

</style>