import httpClient from './httpClient'

const END_POINT = '/customerresources'
const ACCOUNT_USERS_END_POINT = 'users/account-users'

const CustomerServicesAPI = {
  addPaymentMethod({ accountId, details }) {
    details.accountId = accountId
    return httpClient.post(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods`,
      details
    )
  },
  getLoggedInUserDetails() {
    return httpClient.get(`${END_POINT}/user`)
  },
  getUserAccounts() {
    return httpClient.get(`${END_POINT}/user-account`)
  },
  getUserCurrentAccountDetails() {
    return httpClient.get(`${END_POINT}/user-account/current`)
  },

  async getAccountUsers({ accountId }) {
    return await httpClient.get(`${ACCOUNT_USERS_END_POINT}/${accountId}`)
  },

  async createAccountUser({ accountId, userData }) {
    return await httpClient.put(`${ACCOUNT_USERS_END_POINT}/${accountId}`, {
      ...userData,
      email: userData.email?.toLowerCase()
    })
  },

  async removeAccountUser({ accountId, userId }) {
    return await httpClient.delete(`${ACCOUNT_USERS_END_POINT}/${accountId}/${userId}`)
  },

  async switchUserCurrentAccount(accountId) {
    return await httpClient.put(`${END_POINT}/user-account/current/${accountId}`)
  },

  getAccountPaymentMethods({ accountId }) {
    return httpClient.get(`${END_POINT}/billing/accounts/${accountId}/payment-methods`)
  },
  submitKYCDocs({
    accountId,
    paymentMethodId,
    ccFront,
    ccBack,
    photoId,
    billingConfigId,
    initialDeposit
  }) {
    const formData = new FormData()
    formData.append('CCFront', ccFront)
    formData.append('CCBack', ccBack)
    formData.append('PhotoId', photoId)
    formData.append('BillingConfigId', billingConfigId)
    formData.append('InitialDeposit', initialDeposit)
    return httpClient.post(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods/${paymentMethodId}/submit-kyc`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  setPaymentMethodAsDefault({ accountId, paymentMethodId }) {
    return httpClient.put(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods/${paymentMethodId}/make-default`
    )
  },

  removePaymentMethod({ accountId, paymentMethodId }) {
    return httpClient.delete(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods/${paymentMethodId}`
    )
  },

  enablePaymentMethod({ accountId, paymentMethodId }) {
    return httpClient.put(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods/${paymentMethodId}/enable`
    )
  },

  disablePaymentMethod({ accountId, paymentMethodId }) {
    return httpClient.put(
      `${END_POINT}/billing/accounts/${accountId}/payment-methods/${paymentMethodId}/disable`
    )
  },

  getBuyerAccountBalance(accountId) {
    return httpClient.get(
      `${END_POINT}/user-account/buyer/${accountId}/balance`
    )
  },

  getAccountBillingSummary(accountId) {
    return httpClient.get(
      `${END_POINT}/user-account/${accountId}/summary`
    )
  },

  getCurrentAccountRequirements() {
    return httpClient.get(
      `${END_POINT}/user-account/requirements`
    )
  },

  async updateAutoRechargeSettings({
    accountId,
    billingConfigId,
    autoRecharge,
    rechargeAmount,
    rechargeBalance
  }) {
    return await httpClient.post(
      `${END_POINT}/user-account/buyer/${accountId}/update-auto-recharge`,
      {
        billingConfigId,
        autoRecharge,
        rechargeAmount,
        rechargeBalance
      }
    )
  },

  updateAccountTimezone(accountId, timezone) {
    return httpClient.put(`${END_POINT}/user-account/${accountId}/timezone`, timezone)
  },
}

export default CustomerServicesAPI
