<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :description="$attrs.description"
      :label-for="$attrs.id"
      label-class="custom-form-input-label"
    >
      <b-form-radio-group
        :id="$attrs.id"
        v-model="state"
        :data-cy="$attrs['data-cy']"
        v-bind="$attrs"
        class="custom-form-radio"
        :state="getValidationState(validationContext)"
        :options="options"
        :stacked="stacked"
        @change="onSelect"
      />

      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        Please select one
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormRadio',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: '',
    },

    options: {
      type: Array,
      default() {
        return []
      },
    },

    stacked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      state: this.value || undefined,
    }
  },

  methods: {
    onSelect(value) {
      this.state = value
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style>
</style>