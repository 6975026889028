<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
    :vid="$attrs.vid"
    :custom-messages="customMessages"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :description="$attrs.description"
      :invalid-feedback="validationContext.errors[0]"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        [labelClass]: labelClass,
        'pr-4': true
      }"
      :class="formGroupClass"
      :label-cols-sm="horizontal ? 3 : null"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :content-cols-xl="widthXl || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <template
        v-if="hasTooltip"
        slot="label"
      >
        <div>
          {{ $attrs.label }}
          <span
            v-b-tooltip.hover.right
            :title="fetchTooltip($attrs.id)"
          >

            <font-awesome-icon
              class="ml-1 mt-1"
              :icon="['fas', 'question-circle']"
            />
          </span>
        </div>
      </template>

      <b-form-input
        v-if="!plaintext"
        :id="$attrs.id"
        v-model="state"
        :number="$attrs.number"
        :type="$attrs.type"
        :state="getValidationState(validationContext)"
        v-bind="$attrs"
        :class="{ 'custom-form-input--sm': small, 'custom-form-input': !small,
                  [inputClass]: inputClass }"
        :debounce="debounce"
        @change="$emit('change', value)"
        @blur="$emit('blur')"
      />
      <div
        v-if="plaintext"
        class="form-control-plaintext"
      >
        {{ state }}
      </div>
      <slot name="helpText" />

      <slot name="invalidFeedback" />
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormInput',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: ''
    },

    small: {
      type: Boolean,
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400
    },

    formGroupClass: {
      type: String,
      default: ''
    },

    hasTooltip: {
      type: Boolean,
      default: false
    },

    customMessages: {
      type: Object,
      default() {
        return {}
      }
    }

  },

  data() {
    return {
      syncTimeout: null
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.lazy === true) {
          return
        }

        clearTimeout(this.syncTimeout)

        this.syncTimeout = setTimeout(
          () => {
            this.$emit('input', value)
          },
          this.lazy !== false ? this.lazy : 0
        )
      }
    },

  }
}
</script>

<style></style>
