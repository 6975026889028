<template functional>
  <div class="d-flex align-items-center">
    <div
      class="table-loader mr-2"
    />
    <span class="table__status-text">{{ props.loaderText }}</span>
  </div> 
</template>

<script>
export default {
  name: 'Loader',

  props: {
    loaderText: {
      type: String,
      default: 'Loading'
    }
  },
}
</script>

<style>

</style>