const TestAPI = {
  create({ details }) {
    return Promise.resolve(details)
  },
  list() {
    return Promise.resolve([])
  },
  getLoggedInUserDetails() {
    return Promise.resolve({
      email: 'mandeep.s.gulati+buyer000016@gmail.com',
      account: {
        accountCurrency: {
          statusCode: 0,
          status: 'Active',
          liveDate: '0001-01-01T00:00:00+00:00',
          creationDate: '0001-01-01T00:00:00+00:00',
          expirationDate: null,
          pausedDate: null,
          isExpired: false,
          isPaused: false,
          description: null,
          id: 'USD',
          name: 'US Dollar',
          subId: 'us-dollar'
        },
        companyAddress: null,
        companyPhone: null,
        companyWebsite: null,
        representative: null,
        callTechnology: null,
        authorizeRingbaToContact: false,
        onboardingStatusCode: 0,
        onboardingStatus: 'Pending',
        onboardingType: 0,
        onboardingAs: 'Buyer',
        buyerDetails: null,
        sellerDetails: null,
        billingConfiguration: null,
        id: 'ACCb46033eb8a97437e8a0a031547e83815',
        name: null,
        subId: null
      },
      groups: null,
      statusCode: 1,
      status: 'Active',
      id: 'dca1f253-d8f1-427c-b36c-7166c5160665',
      name: 'Mandeep Singh',
      subId: 'mandeep-singh'
    })
  }
}

export default TestAPI
