<template>
  <b-alert
    :show="show"
    variant="warning"
  >
    <div v-if="!isProgrammaticBuyerCampaign">
      <h5>
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="mr-2"
        />
        Please note
      </h5>
      The buyer campaign will be reviewed when any of the fields under the following sections have been updated:
      <ul>
        <li>
          Destination
        </li>
        <li>
          Traffic Sources
        </li>
        <li>
          Audience
        </li>
      </ul>
      <strong><u>Traffic will be paused on this buyer campaign until it is approved, after any of the above is changed.</u></strong>
    </div>

    <div v-else>
      <h6 class="mb-0">
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="mr-2"
        />
        Please reach out to support make changes in a custom Programmatic buyer campaign.
      </h6>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'UpdateCampaignAlert',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    isProgrammaticBuyerCampaign: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="">
  
</style>