<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :invalid-feedback="validationContext.errors[0]"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        'custom-form-file-upload': true,
        'pr-4': true
      }"
      :label-cols-sm="horizontal ? 3 : 0"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <b-form-file
        :id="$attrs.id"
        v-model="state"
        :accept="accept"
        size="sm"
        :state="getValidationState(validationContext)"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <slot name="helpText" />
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormInput',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: File,
      default: '',
    },

    accept: {
      type: String,
      default: null
    },

    lazy: {
      type: [Boolean, Number],
      default: 400,
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    icon: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      syncTimeout: null,
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.lazy === true) {
          return
        }

        clearTimeout(this.syncTimeout)

        this.syncTimeout = setTimeout(
          () => {
            this.$emit('input', value)
          },
          this.lazy !== false ? this.lazy : 0
        )
      }
    }
  },
}
</script>

<style lang="scss">
</style>