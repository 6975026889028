<template>
  <div class="d-flex">
    <b-popover
      :show="!disabled"
      :target="`popover-target-${campaign.id}`"
    >
      <template #title />
      <div>
        <validation-observer
          ref="formValidation"
          v-slot="{ handleSubmit, invalid }"
        >
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit(updateBid)"
          >
            <validation-provider
              name="input-bid-amount"
              rules="required"
            >
              <BaseFormInput
                id="buyer-channel-bidding__bid-amount"
                v-model="currentValue"
                :small="true"
                name="Amount"
                data-cy="input-bid-amount"
                internal-platform-input
                placeholder="enter bid amount"
                label="Bid Amount"
                type="number"
                step="0.01"
                min="0"
                max="9999.99"
                rules="max_value:9999.99|min_value:0.01"
                required
              />
              <b-row
                class="mt-2 d-flex justify-content-between"
                style="margin-left: 0.06rem; margin-right:-7px;"
              >
                <BaseButtonLoader
                  data-cy="button-create-campaign"
                  variant="secondary"
                  @click="togglePopover"
                >
                  Cancel
                </BaseButtonLoader>
                <BaseButtonLoader
                  data-cy="button-create-campaign"
                  :disabled="invalid"
                  :loading="loading"
                  variant="primary"
                  class="ml-2 mr-2"
                  @click="updateBid"
                >
                  Publish
                </BaseButtonLoader>
              </b-row>
            </validation-provider>
          </b-form>
        </validation-observer>
      </div>
    </b-popover>
    <a
      :id="`popover-target-${campaign.id}`"
      href
      @click.prevent="togglePopover"
    >
      {{ `$${campaign.maxBid.toFixed(2).toLocaleString()}` }}
    </a>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'CampaignBidInputPopover',
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      disabled: true,
      loading: false,
      currentValue: 0,
      interval: null
    }
  },
  created() {
    this.currentValue = this.campaign.maxBid
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignBid']),
    togglePopover() {
      this.disabled = !this.disabled
    },
    async updateBid() {
      try {
        this.loading = true
        await this.updateCampaignBid({ campaignId: this.campaign.id, bidValue: Number(this.currentValue) })
        this.disabled = true
        showSuccessMessage("Updated bid amount")
      } catch (error) {
        showErrorMessage('Failed to update campaign bid')
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.campaign-bid-input___symbols {
  width: 20px;
  height: 33px;
  border: 1px solid var(--component-custom-input-border-color);
  color: white;
}

.campaign-bid-input___dollar-sign {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.campaign-bid-input___decrease-bid {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}


.campaign-bid-input___symbols___dollar_icon {
  margin-top: 0.5rem;
  margin-left: 0.33rem;
}

.campaign-bid-input___symbols___change_icon {
  margin-top: 0.5rem;
  margin-left: 0.25rem;
  &:hover {
    outline: none;
    border-color: $login-font;
    cursor: pointer;
  }
}



.width-95 {
  width: 95px;
}

.campaign-bid-input___current-value {
  height: 33px;
}

.campaign-bid-input___current-value___input {
  height: 33px;
  border-radius: unset;
}


</style>
