<template>
  <div class="d-flex flex-column mr-3">
    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Auto Insurance Inbound</span>
      </template>
      <template v-slot:content>
        <b-table
          :fields="fields"
          data-cy="table-buyer-channel-bidding"
          :items="buyerCampaignChannels"
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(calls)="row">
            <div class="text-nowrap">
              {{ row.item.calls.toLocaleString() }}
            </div>
          </template>

          <template #cell(billable)="row">
            <div class="text-nowrap">
              {{ row.item.billable.toLocaleString() }}
            </div>
          </template>

          <template #cell(winRatio)="row">
            <div class="text-nowrap">
              {{ `${row.item.winRatio.toFixed(2)} %` }}
            </div>
          </template>

          <template #cell(spent)="row">
            <div class="text-nowrap">
              {{ `$${row.item.spent.toLocaleString()}` }}
            </div>
          </template>
          <template #cell(avgBid)="row">
            <div class="text-nowrap">
              {{ `$${row.item.avgBid.toLocaleString()}` }}
            </div>
          </template>

          <template #cell(conversionPercentage)="row">
            <div class="text-nowrap">
              {{ `${row.item.conversionPercentage.toFixed(2)} %` }}
            </div>
          </template>

          <template #cell(qualityScore)="row">
            <div class="text-nowrap">
              {{ `${row.item.qualityScore.toFixed(2)} / 10` }}
            </div>
          </template>


          <template #cell(bid)="row">
            <CampaignBidInputPopover
              :campaign="row.item"
            />
          </template>

          <template #cell(status)="row">
            <div class="text-nowrap">
              <CampaignStatusButton
                :campaign-id="row.item.id"
                :status="row.item.status"
                :pause-campaign="pauseCampaign"
                :resume-campaign="resumeCampaign"
              />
            </div>
          </template>
        </b-table>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dateFormatMixin from '@/mixins/dateFormatMixin'
import CampaignStatusButton from '../CampaignStatusButton.vue'
import CampaignBidInputPopover from '@/components/buyer/dashboard/CampaignBidInputPopover.vue'

export default {
  name: 'BuyerChannelBidding',
  components: {
    CampaignStatusButton,
    CampaignBidInputPopover
  },
  mixins: [dateFormatMixin],
  data() {
    return {
      selectedCampaign: null,
      fields: [
        {
          key: 'status',
          label: 'Status',
          tdAttr: (value) => {
            return {
              'data-cy': 'status-badge',
              'data-cy-status': value
            }
          },
          sortable: true
        },
        { key: 'source', label: 'Source', sortable: true },
        { key: 'channel', label: 'Channel', sortable: true },
        { key: 'calls', label: 'Calls', tdClass: 'text-right', sortable: true },
        { key: 'billable', label: 'Billable', tdClass: 'text-right', sortable: true },
        { key: 'spent', label: 'Spent', tdClass: 'text-right', sortable: true },
        { key: 'conversionPercentage', label: 'Conv. %', tdClass: 'text-right', sortable: true },
        { key: 'qualityScore', label: 'Quality Score', tdClass: 'text-right', sortable: true },
        { key: 'winRatio', label: 'Win Ratio', tdClass: 'text-right', sortable: true },
        { key: 'avgBid', label: 'Average Bid', tdClass: 'text-right', sortable: true },
        { key: 'bid', label: 'Bid', sortable: true },
        { key: 'bidLevel', label: 'Bid Level', sortable: true },
        { key: 'actions', label: 'Actions' }
      ]
    }
  },
  computed: {
    ...mapState('dashboard', ['buyerCampaignChannels']),
  },

  async created() {
    await this.getBuyerCampaignChannels({
      campaignId: this.$route.params.campaignId
    })
  },

  methods: {
    ...mapActions('dashboard', ['getBuyerCampaignChannels']),
    ...mapActions('campaign', ['pauseCampaign', 'resumeCampaign']),
  }
}
</script>

<style lang="scss" scoped></style>
