import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import VuexReset from '@ianwalter/vuex-reset'
import createCache from 'vuex-cache'

import registration from './modules/registration.store'
import commonEntities from './modules/common-entities.store'
import auth from './modules/auth.store'
import billing from './modules/billing.store'
import campaign from './modules/campaign.store'
import dashboard from './modules/dashboard.store'
import account from './modules/account.store'
import country from './modules/country.store'
import sellerOffer from './modules/sellerOffer.store'
import callLogs from './modules/call-logs.store'
import creative from './modules/creative.store'
import globalSettings from './modules/global-settings.store'

import test from './modules/test.store'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

const state = {
  isSidebarExpanded: true,

  isDashboardNotificationVisible: false,

  dashboardNotificationMessageDetails: {
    text: '',
    linkText: '',
    routeName: '',
    hideOnClick: true
  }
}

const actions = {
  toggleSidebar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },

  showDashboardNotification({ commit }, messageDetails) {
    commit('SET_DASHBOARD_NOTIFICATION_VISIBILITY', { show: true, messageDetails })
  },

  hideDashboardNotification({ commit }) {
    commit('SET_DASHBOARD_NOTIFICATION_VISIBILITY', { show: false, messageDetails: '' })
  }
}

const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.isSidebarExpanded = !state.isSidebarExpanded
  },

  SET_DASHBOARD_NOTIFICATION_VISIBILITY(state, { show, messageDetails }) {
    state.isDashboardNotificationVisible = show
    state.dashboardNotificationMessageDetails = messageDetails
  },

  reset() {
    return {}
  }
}


const getters = {}

export default new Vuex.Store({
  modules: {
    registration,
    'common-entities': commonEntities,
    auth,
    billing,
    campaign,
    test,
    dashboard,
    account,
    country,
    sellerOffer,
    callLogs,
    creative,
    globalSettings
  },
  strict: debug,
  plugins: debug ? [createLogger(), VuexReset(), createCache({
    timeout: 3000
  })] : [VuexReset(), createCache({
    timeout: 3000
  })],
  state,
  mutations,
  actions,
  getters
})
