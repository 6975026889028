import httpClient from './httpClient'

const END_POINT = '/registration/resources'

const CommonEntitiesAPI = {
  async getCountries() {
    const url = `${END_POINT}/countries`
    const response =  await httpClient.get(url)
    return response
  },
  async getCategories() {
    const url = `${END_POINT}/categories`
    const response =  await httpClient.get(url)
    return response
  },
  async getTrafficSources() {
    const url = `${END_POINT}/traffic-sources`
    const response =  await httpClient.get(url)
    return response
  }
}

export default CommonEntitiesAPI
