<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Insights</span>
    </template>

    <template v-slot:content>
      <b-table
        data-cy="table-buyer-dashboard-insights"
        thead-class="d-none"
        :fields="fields"
        :items="insightsTableData"
      >
        <template #cell(identifier)="row">
          <div class="text-nowrap">
            {{ row.item.identifier }}
          </div>
        </template>
      </b-table>
    </template>
  </Panel>
</template>

<script>
export default {
  name: 'DashboardInsights',
  props: {
    insights: {
      type: Object,
      default: () => {}
    },
    accountType: {
      validator: function(value) {
        // The value must match one of these strings
        return ['buyer', 'seller'].indexOf(value) !== -1
      },
      default: 'buyer'
    }
  },
  data() {
    return {
      fields: [
        { key: 'identifier', label: 'Insights' },
        { key: 'value', label: '' }
      ]
    }
  },
  computed: {
    insightsTableData() {
      if (this.accountType === 'buyer') {
        return [
          {
            identifier: 'Spend',
            value: `$${this.insights.spend.toLocaleString()}`
          },
          { identifier: 'Calls', value: this.insights.calls.toLocaleString() },
          {
            identifier: 'Billable',
            value: this.insights.billable.toLocaleString()
          },
          {
            identifier: 'Conv. %',
            value: `${this.insights.conversionPercentage.toFixed(2)} %`
          },
          {
            identifier: 'Quality Score',
            value: `${this.insights.qualityScore.toFixed(2)} / 10`
          }
        ]
      } else if (this.accountType === 'seller') {
        return [
          {
            identifier: 'Revenue',
            value: `$${this.insights.revenue.toLocaleString()}`
          },
          { identifier: 'Calls', value: this.insights.calls.toLocaleString() },
          {
            identifier: 'Billable',
            value: this.insights.billable.toLocaleString()
          },
          {
            identifier: 'Conv. %',
            value: `${this.insights.conversionPercentage.toFixed(2)} %`
          },
          {
            identifier: 'Quality Score',
            value: `${this.insights.qualityScore.toFixed(2)} / 10`
          }
        ]
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
