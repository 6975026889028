<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Schedule & Capacity</span>
    </template>

    <template v-slot:content>
      <TargetScheduling
        :target-schedule="buyerCampaign.routing.schedule"
        @update="updateSchedule"
      />

      <TargetCapacity
        :target-schedule="buyerCampaign.routing.schedule"
        @update="updateSchedule"
      />
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import TargetScheduling from '../Target/TargetScheduling.vue'
import TargetCapacity from '../Target/TargetCapacity.vue'
export default {
  name: 'DestinationSchedulingCapacity',

  components: {
    TargetScheduling,
    TargetCapacity,
  },

  computed: {
    ...mapState('campaign', ['buyerCampaign']),
  },

  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_ROUTING']),

    updateSchedule(schedule) {
      this.UPDATE_BUYER_CAMPAIGN_ROUTING({
        ...this.buyerCampaign.routing,
        schedule
      })
    }
  },
}
</script>

<style>

</style>