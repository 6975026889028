<template>
  <div
    class="d-flex flex-column justify-content-center flex-grow-1 py-5 onboarding"
  >
    <div class="py-2">
      <b-container
        v-if="!showESignForm && !successfullySignedDoc"
        class="registration"
      >
        <div>
          <transition
            name="component-fade"
            mode="out-in"
          >
            <keep-alive>
              <component
                :is="stepComponent"
                :is-loading="isLoading"
                button-label="Next"
                @submit="onSubmit"
                @success="onSuccess"
                @error="onError"
                @back="onBack"
              />
            </keep-alive>
          </transition>
        </div>
      </b-container>

      <b-container v-if="showESignForm">
        <BuyerESignOnboarding
          :sign-pending="signPending"
          class="mt-3"
        />
      </b-container>

      <b-container
        v-if="successfullySignedDoc"
        class="d-flex align-items-center justify-content-center"
      >
        <h4
          v-if="!showSupportMessage"
          class="mb-0"
        >
          Please wait while your document is being submitted.
        </h4>

        <b-spinner
          v-if="!showSupportMessage"
          style="width: 1.2rem; height: 1.2rem;"
          class="ml-2"
        />

        <h4
          v-if="showSupportMessage"
          class="mb-0"
        >
          Please reach to support, your documents are under review.
        </h4>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'

import CreditCardForm from '@/components/onboarding/CreditCardForm.vue'
import KYCForm from '@/components/onboarding/KYCForm.vue'
import BuyerESignOnboarding from '@/components/esign/BuyerESignOnboarding'
import { BUYER_ONBOARDING_STATUS } from '@/store/modules/auth.store'
import esignOnboardingMixin from '@/mixins/esign-onboarding-mixin'


export default {
  name: 'OnboardingBuyer',

  title: 'Ringba X: Onboarding',

  components: {
    CreditCardForm,
    KYCForm,
    BuyerESignOnboarding,
  },

  mixins: [esignOnboardingMixin],

  props: {
    documentStatus: {
      type: String,
      default: ''
    },

    folderId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoading: false,
      step: 1,
      finalStep: 2,
      onboardingCompleteStatus: BUYER_ONBOARDING_STATUS.COMPLETED
    }
  },

  computed: {
    stepComponent() {
      const onboardingStatus = this.accountDetails?.onboardingStatus

      const statusToComponentMap = {
        Pending: BuyerESignOnboarding,
      }
      return statusToComponentMap[onboardingStatus]
    },

    signPending() {
      return this.accountDetails.onboardingStatus === BUYER_ONBOARDING_STATUS.PENDING_DOCUMENT_SIGNING
    },

    showESignForm() {
      return !this.successfullySignedDoc && (this.accountDetails.onboardingStatus === BUYER_ONBOARDING_STATUS.PENDING || this.signPending)
    },
  },
  methods: {
    ...mapActions('auth', ['logout', 'fetchUsersAccountDetails']),

    ...mapActions('account', {
      getPendingDocumentBundleStatusForAccount: 'getPendingDocumentBundleStatusForBuyer'
    }),
    
    async logoutUser() {
      await this.logout()
    },
    onSubmit() {
      this.isLoading = true
    },
    onError(error) {
      this.isLoading = false
      Vue.notify({
        group: 'default',
        title: error,
        type: 'error',
        duration: 3000
      })
    },
    async onSuccess() {
      await this.fetchUsersAccountDetails()
      this.isLoading = false
      if (this.accountDetails.onboardingStatus === 'Pending') {
        window.location.reload()
      } else {
        this.$router.push({
          name: 'Dashboard'
        })
      }
    },
    onBack() {
      if (this.step > 1) {
        this.step -= 1
      }
    }
  },
}
</script>

<style lang="scss">
</style>
