<template>
  <div class="d-flex flex-column mr-3">
    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Payment History</span>
      </template>
      <template v-slot:content>
        <b-table
          :fields="fields"
          :items="accountPayments"
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
        >
          <template #cell(status)="row">
            <b-badge
              tag="div"
              class="w-100 py-1"
              :variant="getBadgeVariant(row.item.paymentStatusId)"
            >
              {{ formatPaymentStatus(row.item.paymentStatusId) }}
            </b-badge>
          </template>

          <template #cell(period)="row">
            {{ `${dateFormat(row.item.billingCycleStartDate, 'MMM DD, YYYY')} - ${formatDateInclusive(row.item.billingCycleEndDate)}` }}
          </template>

          <template #cell(dueDate)="row">
            {{ formatDateInclusive(row.item.dateDue) }}
          </template>

          <template #cell(invoiceId)="row">
            <div class="text-nowrap">
              <b-button 
                v-if="row.item.invoiceId"
                variant="outline-info"
                size="sm"
                @click="getDownloadLink(row.item.invoiceId)"
              >
                {{ row.item.invoiceId }}
              </b-button>
              <span
                v-if="!row.item.invoiceId"
              >-</span>
            </div>
          </template>

          <template #cell(amount)="row">
            <div class="text-nowrap">
              {{ `${formatCurrency(row.item.amountDue)}` }}
            </div>
          </template>

          <template #cell(paymentDescription)="row">
            <div class="text-nowrap">
              {{ row.item.paymentDescription ? row.item.paymentDescription : '-' }}
            </div>
          </template>

          <template #cell(paymentDate)="row">
            {{ row.item.paymentDate ? dateFormat(row.item.paymentDate, 'MMM DD, YYYY') : '-' }}
          </template>
        </b-table>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formatCurrency from '@/utils/formatCurrency'
import dateFormatMixin from '@/mixins/dateFormatMixin'

const statusMapping = {
  Failed: 'Failed',
  Outstanding: 'Outstanding',
  Paid: 'Paid',
  Overdue: 'Overdue',
  Cancelled: 'Cancelled',
  NotApplicable: 'Not Applicable'
}

const statusToVariantMap = {
  Failed: 'danger',
  Outstanding: 'secondary',
  Paid: 'success',
  Overdue: 'warning',
  Cancelled: 'primary',
  NotApplicable: 'danger'
}

export default {
  name: 'Invoices',
  components: {},
  mixins: [dateFormatMixin],
  data() {
    return {
      fields: [
        { key: 'status', label: 'Status' },
        { key: 'period', label: 'Period' },
        { key: 'dueDate', label: 'Due' },
        { key: 'amount', label: 'Amount' },
        { key: 'paymentDate', label: 'Paid' },
        { key: 'paymentDescription', label: 'Description' },
        { key: 'invoiceId', label: 'Invoice #' }
      ]
    }
  },

  computed: {
    ...mapState('billing', [
      'accountPayments'
    ])
  },

  async created() {
    await this.getAccountPayments()
  },

  methods: {
    ...mapActions('dashboard', [
      'generateInvoiceDownloadLink'
    ]),

    ...mapActions('billing', [
      'getAccountPayments',
    ]),

    async getDownloadLink(invoiceId) {
      const downloadURL = await this.generateInvoiceDownloadLink({ invoiceId })
      window.open(downloadURL)
    },

    getBadgeVariant(status) {
      return statusToVariantMap[status]
    },

    formatPaymentStatus(status) {
      return statusMapping[status]
    },

    formatCurrency,

  }
}
</script>

<style lang="scss" scoped></style>
