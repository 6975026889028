<template>
  <div>
    <b-badge
      v-for="(breakOp, $idx) in normalizedBreaks"
      :key="$idx"
      class="font-weight-normal mt-1"
      :class="{'ml-2': $idx !== 0}"
    >
      <strong>{{ formatTime(breakOp.startTime) }}</strong>
      <i
        class="mx-1"
      >
        for</i>

      <strong>
        {{ breakOp.lengthInMin }} mins
      </strong>

      <b-link
        href="#"
        class="ml-2"
        @click="$emit('remove', normalizedBreaks, $idx)"
      >
        <font-awesome-icon
          :icon="['fas', 'times']"
        />
      </b-link>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'TargetBreaks',

  props: {
    breaks: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    normalizedBreaks() {
      let sortedBreaks = [...this.breaks]

      return sortedBreaks.sort(function (timeA, timeB) {
        if (timeA.startTime.hour - timeB.startTime.hour === 0) {
          return timeA.startTime.minute - timeB.startTime.minute
        }

        return timeA.startTime.hour - timeB.startTime.hour
      })
    },
  },

  methods: {
    formatTime(time) {
      let isAM = true
      let { hour } = time

      if (hour >= 12) {
        isAM = false

        hour = hour > 12 ? (hour - 12).toString().padStart(2, '0') : 12
      }

      return `${hour}:${time.minute?.toString().padStart(2, '0')} ${isAM ? 'AM' : 'PM'}`
    }
  },
}
</script>

<style>

</style>