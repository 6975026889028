<template>
  <b-button
    :type="type"
    :class="{ 'text-nowrap': nowrap, [buttonClass]: buttonClass, 'custom-button--flat': customButton }"
    :variant="variant"
    :size="size"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <b-spinner
      v-if="loading"
      :class="{'mr-1': showTextWithLoader}"
    />
      
    <span v-show="!loading || showTextWithLoader">
      <slot />
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonLoader',

  props: {
    customButton: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'button'
    },

    label: {
      type: String,
      default: ''
    },

    labelClass: {
      type: String,
      default: ''
    },

    horizontal: {
      type: Boolean,
      default: false
    },

    variant: {
      type: String,
      default: 'default'
    },

    width: {
      type: Number,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },

    nowrap: {
      type: Boolean,
      default: false
    },

    buttonClass: {
      type: String,
      default: ''
    },

    showTextWithLoader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || this.loading
    }
  }
}
</script>

<style>


</style>