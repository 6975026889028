import httpClient from './httpClient'
import { getDateRangeInTimeZone } from '@/utils/dateTimeUtils'

const END_POINT = '/reporting/seller'

const SellerCallLogsAPI = {
  async getSellerCallLogs(accountId, { from, to, timeZone, pageSize, pageNumber }) {
    const url = `${END_POINT}/${accountId}/calls`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime,
      timeZone,
      pageSize,
      pageNumber
    })
  },

  async getSellerTotalCalls(accountId, { from, to, timeZone }) {
    const url = `${END_POINT}/${accountId}/total`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime
    })
  },

  async exportSellerCalls(accountId, { from, to, timeZone }) {
    const url = `${END_POINT}/${accountId}/export`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime,
      timeZone
    })
  },
}

export default SellerCallLogsAPI