<template>
  <div class="layout-public">
    <div class="layout-public__main d-flex flex-column">
      <LayoutPublicHeader />

      <div class="layout-public__main-section d-flex flex-column justify-content-center flex-grow-1 py-5">
        <slot />
      </div>
    </div>

    <LayoutPublicFooter />
  </div>
</template>

<script>
import LayoutPublicHeader from './LayoutPublicHeader.vue'
import LayoutPublicFooter from './LayoutPublicFooter.vue'

export default {
  name: 'LayoutPublic',
  components: {
    LayoutPublicHeader,
    LayoutPublicFooter
  }
}
</script>

<style lang="scss" scoped>
.layout-public {
  min-height: 100vh;
  background: var(--component-layout-public-bg);
  flex-direction: column;
  display: flex;
  flex: 1 0 auto;
}

.layout-public__main {
  flex: 1 0 auto;
}
</style>