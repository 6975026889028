<template>
  <div class="d-flex flex-column mr-3">
    <DashboardFilters
      :from="defaultStartDate"
      :to="defaultEndDate"
      :auto-refresh="sellerAutoRefresh"
      :toggle-auto-refresh="TOGGLE_SELLER_AUTO_REFRESH"
      @dateRangeUpdated="reloadData"
    />
    <div>
      <b-row>
        <b-col md="3">
          <DashboardInsights
            v-if="Object.keys(sellerDashboardInsights).length > 0"
            :insights="sellerDashboardInsights"
            account-type="seller"
          />
        </b-col>
        <b-col md="9">
          <DashboardGraph
            v-if="sellerDashboardGraphData.length > 0"
            :chart-data="chartData"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <SellerDashboardOffers
        :offers="sellerDashboardOffers"
      />
    </div>
  </div>
</template>

<script>
import DashboardFilters from '@/components/DashboardFilters.vue'
import DashboardInsights from '@/components/DashboardInsights.vue'
import DashboardGraph from '@/components/DashboardGraph.vue'
import SellerDashboardOffers from './SellerDashboardOffers.vue'
import { mapState, mapActions, mapMutations } from 'vuex'

const AUTO_RELOAD_INTERVAL = 120 * 1000 // 2 mins interval

let autoReloadTimer

export default {
  name: 'BuyerDashboard',

  title: 'Dashboard',

  components: {
    DashboardFilters,
    DashboardInsights,
    DashboardGraph,
    SellerDashboardOffers
  },
  
  computed: {
    ...mapState('dashboard', [
      'sellerDashboardGraphData',
      'sellerDashboardInsights',
      'sellerDashboardOffers',
      'sellerAutoRefresh'
    ]),
    defaultStartDate() {
      const now = Date.now()
      return new Date(now - 7 * 24 * 60 * 60 * 1000)
    },
    defaultEndDate() {
      return new Date()
    },
    chartData() {
      const colors = ['#a1eb34', '#eb8f34', '#34ebe5', '#eb34e8', '#343deb']
      const datasets = this.sellerDashboardGraphData.map(offer => {
        return {
          type: 'line',
          tension: 0.1,
          borderWidth: 1.5,
          borderColor: colors.pop(),
          label: offer.name,
          fill: false,
          data: offer.data.map(entry => {
            return {
              x: entry.timestamp,
              y: entry.value
            }
          })
        }
      })
      return {
        datasets
      }
    }
  },
  watch: {
    sellerAutoRefresh (newValue) {
      if (newValue) {
        autoReloadTimer = setInterval(async () => {
          await Promise.all([
            this.getSellerDashboardData({
              from: this.defaultStartDate,
              to: this.defaultEndDate
            }),
            this.getSellerOffers()
          ])
        }, AUTO_RELOAD_INTERVAL)
      } else {
        this.clearAutoReloadInterval()
      }
    }
  },
  async created() {
    await Promise.all([
      this.getSellerDashboardData({
        from: this.defaultStartDate,
        to: this.defaultEndDate
      }),
      this.getSellerOffers()
    ])
  },
  methods: {
    ...mapActions('dashboard', ['getSellerDashboardData', 'getSellerOffers']),
    ...mapMutations('dashboard', ['TOGGLE_SELLER_AUTO_REFRESH']),
    reloadData({ from, to }) {
      return this.getSellerDashboardData({ from, to })
    },
    clearAutoReloadInterval() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
        autoReloadTimer = null
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
