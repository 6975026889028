<template>
  <b-modal
    id="application-details-modal"
    ref="application-details-modal"
    size="lg"
    header-class="custom-modal"
    body-class="custom-modal"
    footer-class="custom-modal"
    :hide-footer="true"
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
  >
    <template #modal-title>
      <b-container>
        <b-row align-h="end">
          <b-col>
            Application Details: {{ registration.sellerOffer.name }} 
          </b-col>
          <b-col cols="2">
            <b-badge 
              :variant="getBadgeVariant(registration.status)"
              pill 
              right-align
            >
              {{ registration.status }}
            </b-badge>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <div class="px-4">
      <BaseFormInput
        id="seller-offer__created-at"
        v-model="createdAt"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Applied At"
        type="text"
        width-lg="4"
        width-xl="3"
        has-tooltip
        plaintext
        input-class="px-0"
      />
      <hr>

      <BaseFormTextArea
        v-if="registration.reason"
        v-model="registration.reason"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Rejection reason"
        type="text"
        width-lg="4"
        width-xl="3"
        rows="5"
        plaintext
        disabled
        input-class="px-0"
        placeholder="The reason for the rejection"
      />

      
      <BaseFormTextArea
        id="seller-offer__description"
        v-model="registration.sellerOffer.description"
        :horizontal="true"
        :small="true"
        internal-platform-input
        label="Description"
        type="text"
        width-lg="4"
        width-xl="3"
        rows="5"
        disabled
        has-tooltip
        plaintext
        input-class="px-0"
      />
      <BaseFormSelect
        id="seller-offer__category"
        v-model="categoryId"
        :horizontal="true"
        internal-platform-input
        :small="true"
        name="Category"
        label="Category"
        text-field="name"
        value-field="id"
        :options="categoryList"
        required
        :disabled="true"
        width="5"
        width-lg="4"
        width-xl="3"
        has-tooltip
      />
      <BaseFormSelect
        id="seller-offer__traffic-source-type"
        v-model="allowedTrafficSourceType"
        :horizontal="true"
        internal-platform-input
        :small="true"
        name="Traffic Source Type"
        label="Traffic Source Type"
        :options="allowedTrafficSourceTypeList"
        required
        :disabled="true"
        width="5"
        width-lg="4"
        width-xl="3"
        has-tooltip
      />


      <div class="mt-4">
        Traffic Sources
        <span
          v-b-tooltip.hover.right
          :title="'The types of promotional methods allowed for this offer'"
        >
          <font-awesome-icon
            class="ml-1 mt-1"
            :icon="['fas', 'question-circle']"
          />
        </span>
      </div>
      <b-row>
        <b-col 
          lg="6"
          class="my-1"
        >
          <b-table 
            striped 
            hover 
            :items="allowedTrafficSources" 
            :fields="allowedTrafficSourcesFields"
          >
            <template #cell(name)="row">
              <div class="text-nowrap">
                {{ row.item.name }}
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col 
          lg="6" 
          class="my-1"
        >
          <b-table 
            striped 
            hover 
            :items="selectedTrafficSources" 
            :fields="[{ key: 'name', label: 'Selected Traffic Sources' }]"
          >
            <template #cell(name)="row">
              <div class="text-nowrap">
                {{ row.item.name }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <BaseButtonGroup
        internal-platform-input
        :selected-option="registration.thirdPartyTraffic"
        label="Will any of your traffic come from third party affiliates?"
        :options="['Yes', 'No', 'Some']"
      />

      <BaseButtonGroup
        internal-platform-input
        :selected-option="registration.isAffiliateTrafficApproved"
        label="Was affiliate traffic allowed?"
        :options="[
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ]"
      />

      <BaseFormTextArea
        id="landingPageURLs"
        v-model="registration.landingPageUrls"
        internal-platform-input
        :small="true"
        rows="4"
        data-cy="input-landing-page-urls"
        name="input-landing-page-urls"
        label="Please provide URLs for landing pages if applicable:"
        placeholder="None"
        plaintext
        disabled
      />

      <BaseFormTextArea
        id="promotionalMethodsDetails"
        v-model="registration.promotionalMethodsDetails"
        internal-platform-input
        :small="true"
        rows="4"
        label="Please provide specific details regarding your promotional methods:"
        placeholder="None"
        plaintext
        disabled
      />
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SellerApplicationDetailsModal',
  props: {
    registration: {
      type: Object,
      default: () => {
        return {
          status: null,
          sellerOffer: {
            id: null,
            name: null,
            description: null,
            status: null,
            createdAt: null,
            createdBy: null,
            category: {
              id: null,
              name: null,
            },
            allowedTrafficSourceType: null,
          },
        }
      },
    },
  },
  data() {
    return {
    }
  },
  computed:{
    categoryList() {
      const categoryList = [this.registration.sellerOffer.category]
      return categoryList
    },

    categoryId: {
      get() {
        return this.registration.sellerOffer.category?.id
      }
    },

    allowedTrafficSourceTypeList() {
      const categoryList = [this.registration.sellerOffer.allowedTrafficSourceType]
      return categoryList
    },

    allowedTrafficSourceType: {
      get() {
        return this.registration.sellerOffer.allowedTrafficSourceType
      }
    },
    
    allowedTrafficSourcesFields: {
      get() {
        if (this.registration.approvedTrafficSources?.length > 0) {
          return [{ key: 'name', label: 'Approved Traffic Sources' }]
        }
        return [
          { key: 'name', label: 'Allowed Traffic Sources' }
        ]
      }
    },

    allowedTrafficSources: {
      get() {
        if (this.registration.approvedTrafficSources?.length > 0) {
          return this.registration.approvedTrafficSources
        }
        return this.registration.sellerOffer.allowedTrafficSources
      }
    },

    selectedTrafficSources: {
      get() {
        return this.registration.selectedTrafficSources || []
      }
    },
    createdAt() {
      return moment(this.registration.createdAt).format('MMM DD, YYYY')
    },
  },
  methods:{
    getBadgeVariant(status) {
      const statusToVariantMap = {
        Pending: 'secondary',
        Approved: 'success',
        Rejected: 'danger',
        NotEligible: 'danger',
        Withdrawn: 'warning',
        ManuallyBlocked: 'dark'
      }
      return statusToVariantMap[status]
    },
  }
}
</script>

<style lang="scss">
</style>
