<template>
  <div>
    <BaseFormInput
      id="buyer-create-campaign__destination__request-settings-url"
      v-model="url"
      internal-platform-input
      :horizontal="true"
      :small="true"
      name="URL"
      placeholder="Request URL"
      label="URL"
      type="text"
      required
      width="5"
      width-lg="4"
      width-xl="3"
      rules="url"
      has-tooltip
    />

    <BaseFormSelect
      id="buyer-create-campaign__destination__request-settings-http-method"
      v-model="method"
      :options="httpMethodList"
      label="HTTP Method"
      internal-platform-input
      horizontal
      small
      required
      width="3"
      width-lg="1"
      has-tooltip
    />

    <BaseFormSelect
      v-if="canRequestHaveBody"
      id="buyer-create-campaign__destination__request-settings-content-type"
      v-model="contentType"
      :options="CONTENT_TYPES"
      label="Content Type"
      internal-platform-input
      horizontal
      small
      required
      width="3"
      width-xl="3"
      has-tooltip
    />

    <BaseFormTextArea
      v-if="canRequestHaveBody"
      id="buyer-create-campaign__destination__request-settings-body"
      v-model="body"
      internal-platform-input
      name="Body"
      label="Body"
      horizontal
      required
      width="5"
      width-lg="4"
      rows="6"
      custom-input-class="text-monospace font-weight-light"
      has-tooltip
    />

    <BaseFormDualInputList
      id="buyer-create-campaign__destination__request-settings-headers"
      v-model="headers"
      internal-platform-input
      horizontal
      width="8"
      width-lg="6"
      label="Headers"
      add-text="Add Headers"
      has-tooltip
    />
  </div>
</template>

<script>
const HTTP_METHOD_OPTIONS = [{
  text: 'GET',
  value: 'get'
},
{
  text: 'POST',
  value: 'post'
},
{
  text: 'PUT',
  value: 'put'
},
{
  text: 'PATCH',
  value: 'patch'
},
{
  text: 'DELETE',
  value: 'delete'
}]

const CONTENT_TYPES = [
  { text: 'application/json', value: 'application/json' },
  { text: 'application/xml', value: 'application/xml' },
  { text: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded' },
  { text: 'text/plain', value: 'text/plain' },
]

export default {
  name: 'ProgrammaticTargetRequestSettings',

  props: {
    programmaticTarget: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      HTTP_METHOD_OPTIONS: Object.freeze(HTTP_METHOD_OPTIONS),
      CONTENT_TYPES: Object.freeze(CONTENT_TYPES),
    }
  },

  computed: {
    httpMethodList() {
      const httpMethodList = [...this.HTTP_METHOD_OPTIONS]
      httpMethodList.unshift({ text: 'Please select', value: null })
      return httpMethodList
    },

    canRequestHaveBody() {
      return ['post', 'patch', 'put'].includes(this.method)
    },

    url: {
      get() {
        return this.programmaticTarget.url
      },

      set(value) {
        this.$emit('update', {...this.programmaticTarget, url: value})
      },
    },

    method: {
      get() {
        return this.programmaticTarget.method
      },

      set(value) {
        this.$emit('update', {...this.programmaticTarget, method: value})
      }
    },

    contentType: {
      get() {
        return this.programmaticTarget.contentType
      },

      set(value) {
        this.$emit('update', {...this.programmaticTarget, contentType: value})
      }
    },

    body: {
      get() {
        return this.programmaticTarget.body
      },

      set(value) {
        this.$emit('update', {...this.programmaticTarget, body: value})
      }
    },

    headers: {
      get() {
        return this.programmaticTarget.headers
      },

      set(value) {
        this.$emit('update', {...this.programmaticTarget, headers: value})
      }
    }
  },
}
</script>

<style>

</style>