import httpClient from './httpClient'

const DashboardAPI = {
  async getBuyerDashboardData({ from, to, timezone }) {
    return Promise.resolve({
      data: {
        insights: {
          spend: 1232.01,
          calls: 1021,
          billable: 963,
          conversionPercentage: 82,
          qualityScore: 8
        },
        graphData: getBuyerDashboardGraphData({ from, to, timezone })
      }
    })
  },
  async getBuyerCampaigns() {
    return Promise.resolve({
      data: [
        {
          id: 'CMP12345',
          name: 'Auto Insurance - Inbound',
          calls: 10,
          billable: 8,
          spent: 8345.0,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 22.63,
          bidLevel: '',
          status: 'Paused'
        },
        {
          id: 'CMP678910',
          name: 'Auto Insurance - Inbound',
          calls: 10,
          billable: 8,
          spent: 80.005,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 220089.63,
          bidLevel: '',
          status: 'Active'
        }
      ]
    })
  },

  async getSellerDashboardData({ from, to, timezone }) {
    return Promise.resolve({
      data: {
        insights: {
          revenue: 1232.01,
          calls: 1021,
          billable: 963,
          conversionPercentage: 82,
          qualityScore: 8
        },
        graphData: getSellerDashboardGraphData({ from, to, timezone })
      }
    })
  },

  async getSellerOffers() {
    return Promise.resolve({
      data: [
        {
          id: 'CMP12345',
          name: 'Auto Insurance',
          type: 'Inbound',
          pings: 9324,
          calls: 10,
          billable: 8,
          revenue: 8345.0,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 22.63,
          status: 'Paused'
        },
        {
          id: 'CMP678910',
          name: 'Auto Insurance',
          type: 'Outbound',
          pings: 1526,
          calls: 10,
          billable: 8,
          revenue: 80.005,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 22.63,
          status: 'Active'
        }
      ]
    })
  },

  async getBuyerCampaignChannels({ campaignId }) {
    console.log(campaignId)
    return Promise.resolve({
      data: [
        {
          id: 'CA12213',
          source: 'green-tiger',
          channel: 'Paid Search',
          calls: 10,
          billable: 8,
          spent: 8345.0,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 22.63,
          maxBid: 30.00,
          bidLevel: '',
          status: 'Paused'
        },
        {
          id: 'CA12145',
          source: 'jade-aligator',
          channel: 'Direct Mail',
          calls: 10,
          billable: 8,
          spent: 80.005,
          conversionPercentage: 80,
          qualityScore: 8,
          winRatio: 18,
          avgBid: 212.63,
          maxBid: 400.00,
          bidLevel: '',
          status: 'Active'
        }
      ]
    })
  },
  async getSellerAvailableOffers() {
    return {
      data: [
        {
          name: 'Auto Insurance 1',
          status: 'Approved',
          category: 'Auto Insurance',
          type: 'Inbound',
          qualityScore: 7,
          liveConcurrency: 1,
          liveConcurrencyCap: 10,
          dailyConcurrency: 18,
          dailyConcurrencyCap: 50,
          weeklyConcurrency: 125,
          weeklyConcurrencyCap: 500,
          monthlyConcurrency: 511,
          monthlyConcurrencyCap: 1000,
          rateLimit: 10,
          bidTTLInSeconds: 15
        },
        {
          name: 'Auto Insurance 2',
          status: 'Pending',
          category: 'Auto Insurance',
          type: 'Outbound',
          qualityScore: null,
          liveConcurrency: null,
          liveConcurrencyCap: null,
          dailyConcurrency: null,
          dailyConcurrencyCap: null,
          weeklyConcurrency: null,
          weeklyConcurrencyCap: null,
          monthlyConcurrency: null,
          monthlyConcurrencyCap: null,
          rateLimit: null,
          bidTTLInSeconds: null
        },
        {
          name: 'Auto Insurance 3',
          status: 'Rejected',
          rejectionReason: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          category: 'Auto Insurance',
          type: 'Transfer',
          qualityScore: null,
          liveConcurrency: null,
          liveConcurrencyCap: null,
          dailyConcurrency: null,
          dailyConcurrencyCap: null,
          weeklyConcurrency: null,
          weeklyConcurrencyCap: null,
          monthlyConcurrency: null,
          monthlyConcurrencyCap: null,
          rateLimit: null,
          bidTTLInSeconds: null
        },
        {
          name: 'Auto Insurance 4',
          status: 'Available',
          category: 'Auto Insurance',
          type: 'Inbound',
          qualityScore: null,
          liveConcurrency: null,
          liveConcurrencyCap: null,
          dailyConcurrency: null,
          dailyConcurrencyCap: null,
          weeklyConcurrency: null,
          weeklyConcurrencyCap: null,
          monthlyConcurrency: null,
          monthlyConcurrencyCap: null,
          rateLimit: null,
          bidTTLInSeconds: null
        },
        {
          name: 'Auto Insurance 5',
          status: 'Available',
          category: 'Auto Insurance',
          type: 'Outbound',
          qualityScore: null,
          liveConcurrency: null,
          liveConcurrencyCap: null,
          dailyConcurrency: null,
          dailyConcurrencyCap: null,
          weeklyConcurrency: null,
          weeklyConcurrencyCap: null,
          monthlyConcurrency: null,
          monthlyConcurrencyCap: null,
          rateLimit: null,
          bidTTLInSeconds: null
        },
        {
          name: 'Auto Insurance 6',
          status: 'Available',
          category: 'Auto Insurance',
          type: 'Transfer',
          qualityScore: null,
          liveConcurrency: null,
          liveConcurrencyCap: null,
          dailyConcurrency: null,
          dailyConcurrencyCap: null,
          weeklyConcurrency: null,
          weeklyConcurrencyCap: null,
          monthlyConcurrency: null,
          monthlyConcurrencyCap: null,
          rateLimit: null,
          bidTTLInSeconds: null
        }
      ]
    }
  },

  async generateInvoiceDownloadLink({ accountId, invoiceId }) {
    const url = `/customerresources/user-account/invoice/${accountId}/${invoiceId}`
    return httpClient.get(url)
  }
}

export default DashboardAPI

function getSellerDashboardGraphData({ from, to, timezone }) {
  return getBuyerDashboardGraphData({ from, to, timezone })
}

function getBuyerDashboardGraphData({ from, to, timezone }) {
  console.log('timezone', timezone)
  const startTimeEpoch = from.valueOf()
  const endTimeEpoch = to.valueOf()
  const numberOfCampaigns = 5
  const campaignNames = [
    'Auto Sales Inbound',
    'Auto Sales Outbound',
    'Auto Insurance Inbound',
    'Auto Insurance Outbound',
    'Legal'
  ]
  const dataPoints = 10
  const campaigns = []
  for (let i = 0; i < numberOfCampaigns; i++) {
    const campaignData = generateCampaignData({
      startTimeEpoch,
      endTimeEpoch,
      dataPoints
    })
    campaigns.push({
      name: campaignNames.pop(),
      data: campaignData
    })
  }
  return campaigns
}

function generateCampaignData({ startTimeEpoch, endTimeEpoch, dataPoints }) {
  const campaignData = []
  for (let i = 0; i < dataPoints; i++) {
    const entry = {
      timestamp: new Date(randomInRange(startTimeEpoch, endTimeEpoch)),
      value: randomInRange(0, 1000)
    }
    campaignData.push(entry)
  }
  return campaignData.sort((a, b) => {
    return a.timestamp < b.timestamp ? -1 : 1
  })
}

function randomInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
