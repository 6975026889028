<template>
  <b-navbar fixed="top">
    <b-navbar-brand
      class="header__logo mr-5 pl-2"
      href="#"
    >
      <RingbaXLogoDark />
    </b-navbar-brand>

    <button
      v-if="!hideHamburger"
      class="btn btn-link bg-transparent header__sidebar-toggle d-none d-sm-block"
      type="button"
      @click="toggleSidebar"
    >
      <font-awesome-icon :icon="['fas', 'bars']" />
    </button>

    <AccountSummaryHeader v-if="!hideSummary" />

    <b-navbar-nav class="ml-auto align-items-center">
      <b-nav-item
        v-if="showSupportChat"
        class="mr-3"
        @click="toggleSupportChat"
      >
        <div class="d-flex flex-column align-items-center header__nav-item-with-alert">
          <font-awesome-icon
            :icon="['fa', 'comments']"
            class="header__nav-item-icon"
          />
          <span class="header__nav-item-text">Support</span>

          <span
            v-if="unreadChatCount"
            class="header__nav-item-alert-icon"
          >
            {{ unreadChatCount > 10 ? '+10' : unreadChatCount }}
          </span>
        </div>
      </b-nav-item>

      <b-nav-item
        v-if="showLogout"
        class="mr-3"
        @click="logoutAndRedirect"
      >
        Logout
      </b-nav-item>

      <b-nav-item-dropdown
        right
        @hide="handleOnSwitchHide"
      >
        <template #button-content>
          {{ userDetails.name }}
        </template>

        <div class="px-3 mb-2">
          Switch Account
        </div>

        <b-dropdown-item
          v-for="account in allUserAccounts"
          :key="account.id"
          href="#"
          class="account-item"
          @click="switchAccount(account.id)"
        >
          <font-awesome-icon
            v-if="!isLoading || account.id !== newAccountId"
            :class="account.id === accountId ? 'visible' : 'invisible'"
            :icon="['fas', 'check']"
            class="mr-2"
          />
          
          <b-spinner
            v-if="isLoading && account.id === newAccountId"
            class="mr-2"
          />
          <span :class="{ 'font-weight-bold' : account.id === accountId }">{{ account.name }} </span>
          <i class="font-weight-light"><small>({{ account.type }})</small></i>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import RingbaXLogoDark from '@/assets/img/ringbax-logo-dark-theme.svg'
import AccountSummaryHeader from '@/components/billing/AccountSummaryHeader.vue'
import crispChatMixin from '@/mixins/crisp-chat.mixin'
import logoutMixin from '@/mixins/logout.mixin'
import { showErrorMessage } from '@/notification-utils'

export default {
  name: 'TheHeader',

  components: {
    RingbaXLogoDark,
    AccountSummaryHeader
  },

  mixins: [crispChatMixin, logoutMixin],

  props: {
    showLogout: {
      type: Boolean,
      default: false
    },

    hideSummary: {
      type: Boolean,
      default: false
    },

    hideHamburger: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      newAccountId: ''
    }
  },

  computed: {
    ...mapState('auth', [
      'userDetails'
    ]),

    ...mapGetters('auth', [
      'allUserAccounts',
      'accountId'
    ]),
  },

  async created () {
    await this.fetchUserAccounts()
  },

  methods: {
    ...mapActions(['toggleSidebar']),
    
    ...mapActions('auth', [
      'fetchUserAccounts',
      'switchCurrentAccount'
    ]),

    async switchAccount(accountId) {
      if (accountId === this.accountId) {
        return
      }
      
      try {
        this.isLoading = true

        this.newAccountId = accountId

        await this.switchCurrentAccount(accountId)

        window.location.reload()
      } catch {
        showErrorMessage('Unable to switch account, please contact support.')
      } finally {
        this.isLoading = false
        this.newAccountId = ''
      }
    },

    handleOnSwitchHide(ev) {
      if (this.isLoading) {
        ev.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header__logo {
  width: 125px;
}

.header__sidebar-toggle {
  color: var(--component-sidebar__toggle-font-color);
}

.header__nav-item-text {
  font-size: 0.8rem;
}

.header__nav-item-icon {
  font-size: 1rem;
}

.header__nav-item-with-alert {
  position: relative;
}

.header__nav-item-alert-icon {
  position: absolute;
  right: 8px;
  top: -2px;
  font-size: 7px;
  color: #fff;
  font-weight: 200;
  text-align: center;
  line-height: 14px;
  width: 15px;
  height: 14px;
  background: red;
  border-radius: 50%;
}

.account-item {
  font-size: 0.85rem;
}
</style>
