import moment from 'moment'
export default {
  methods: {
    dateFormat(date, formatString) {
      return moment(date).format(formatString)
    },

    formatDateInclusive(date) {
      let result = moment.parseZone(date).subtract(1, 's')
      return result.format('MMM DD, YYYY')
    },

    millisecondsToDuration(milliseconds) {
      return moment.utc(milliseconds).format('HH:mm:ss')
    },

    secondsToDuration(seconds) {
      if (seconds === '' || seconds == null) {
        return ''
      }
      
      return moment.utc(seconds * 1000).format('HH:mm:ss')
    }
  }
}
