import CustomerServiceAPI from '@/api/customer-services.api'
import BankInfoAPI from '@/api/bank-info.api'
import BillingAPI from '@/api/billing.api'

const EIN_REGEX = /\d{2}[-]\d{7}\b/ // eslint-disable-line

const state = {
  paymentMethods: [],
  bankInfo: {
    address: {
      state: null
    }
  },
  accountPayments: [],
  accountBalance: {},
  accountSummary: {}
}

const getters = {
  unverifiedPaymentMethods(state) {
    return state.paymentMethods.filter(pm => pm._Status === 'Unverified')
  },

  accountBillingConfiguration(state, getters, rootState, rootGetters) {
    return rootGetters['auth/accountDetails']?.billingConfiguration
  },

  buyerMinimumCreditCardChargeAmount(state, getters) {
    return getters['accountBillingConfiguration'].minimumCreditCardChargeAmount
  },

  buyerMaximumCreditCardChargeAmount(state, getters) {
    return getters['accountBillingConfiguration'].maximumCreditCardChargeAmount
  },

  sellerPendingBalance(state) {
    const result = state.accountSummary.pendingBalance + state.accountSummary.holdingBalance
    return result || 0
  }
}

const actions = {
  async addPaymentMethod(context, { accountId, details }) {
    const response = await CustomerServiceAPI.addPaymentMethod({
      accountId,
      details
    })
    return response.data
  },

  async fetchPaymentMethods({ commit }, { accountId }) {
    const response = await CustomerServiceAPI.getAccountPaymentMethods({
      accountId
    })

    commit('SET_ACCOUNT_PAYMENT_METHODS', response.data)

    return response.data
  },

  async setPaymentMethodAsDefault(context, { accountId, paymentMethodId }) {
    const response = await CustomerServiceAPI.setPaymentMethodAsDefault({
      accountId,
      paymentMethodId
    })
    return response.data
  },

  async removePaymentMethod(context, { accountId, paymentMethodId }) {
    const response = await CustomerServiceAPI.removePaymentMethod({
      accountId,
      paymentMethodId
    })   

    return response.data
  },


  async updateAutoRechargeSettings(
    context,
    { accountId, billingConfigId, autoRecharge, rechargeAmount, rechargeBalance }
  ) {
    const response = await CustomerServiceAPI.updateAutoRechargeSettings({
      accountId,
      billingConfigId,
      autoRecharge,
      rechargeAmount,
      rechargeBalance
    })
    return response.data
  },

  async submitKYCDocs(
    context,
    {
      accountId,
      paymentMethodId,
      ccFront,
      ccBack,
      photoId,
      billingConfigId,
      initialDeposit
    }
  ) {
    const response = await CustomerServiceAPI.submitKYCDocs({
      accountId,
      paymentMethodId,
      ccFront,
      ccBack,
      photoId,
      billingConfigId,
      initialDeposit
    })
    return response.data
  },

  async getBankInfo({ commit }, accountId) {
    const response = await BankInfoAPI.getBankInfo(accountId)

    if (response.data) {
      commit('SET_BANK_INFO', response.data)
    }

    return response.data
  },

  async getUnmaskedBankInfo({ commit }, accountId) {
    const response = await BankInfoAPI.getUnmaskedBankInfo(accountId)

    if (response.data) {
      commit('SET_BANK_INFO', response.data)
    }
    
    return response.data
  },

  setBankInfo({ commit }, bankInfo) {
    commit('SET_BANK_INFO', bankInfo)
  },

  async saveBankInfo({ rootGetters }, { bankInfo, mode = 'create' }) {
    let apiToCall
    if (mode === 'create') {
      apiToCall = BankInfoAPI.saveBankInfo
    } else {
      if (Number.isNaN(Number(bankInfo.accountNumber))) {
        // skip account number from API call if it
        // has XXXXX as value
        delete bankInfo.accountNumber
      }

      apiToCall = BankInfoAPI.updateBankInfo
    }

    const response = await apiToCall(
      rootGetters['auth/accountId'],
      bankInfo
    )

    return response.data
  },

  async getBuyerAccountBalance({ commit, rootGetters, dispatch }) {
    const response = await CustomerServiceAPI.getBuyerAccountBalance(
      rootGetters['auth/accountDetails']?.id
    )

    commit('SET_ACCOUNT_BALANCE', response.data)

    if (response.data.isBalanceLow) {
      dispatch('showDashboardNotification', {
        text: 'Your balance is too low to buy calls. ',
        linkText: 'Click here to add funds',
        routeName: 'AddFunds',
        hideOnClick: false
      }, { root:true })
    }

    if (response.data.isDailyBudgetReached) {
      dispatch('showDashboardNotification', {
        text: 'Your daily budget is exceeded. ',
        linkText: 'Click here to change account daily budget. ',
        routeName: 'BuyerAccountBudget',
        hideOnClick: false
      }, { root:true })
    }

    await dispatch('getAccountBillingSummary')

    return response.data
  },

  async getAccountBillingSummary({ commit, rootGetters }) {
    const response = await CustomerServiceAPI.getAccountBillingSummary(
      rootGetters['auth/accountDetails']?.id
    )

    commit('SET_ACCOUNT_SUMMARY', response.data)
    return response.data
  },

  async addFundsByCreditCardCharge(context, { amount, accountId, creditCardId }) {
    const response = await BillingAPI.addFundsByCreditCardCharge(accountId, amount, creditCardId)

    return response.data
  },
  
  async getAccountPayments({ rootGetters, commit }) {
    let accountId = rootGetters['auth/accountId']
    
    const response = await BillingAPI.getAccountPayments(accountId)
    commit('SET_ACCOUNT_PAYMENTS', response.data)
    return response.data
  },
}

const mutations = {
  SET_ACCOUNT_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },

  SET_BANK_INFO(state, bankInfo) {
    state.bankInfo = bankInfo
  },

  SET_ACCOUNT_BALANCE(state, accountBalance) {
    state.accountBalance = accountBalance
  },

  SET_ACCOUNT_SUMMARY(state, accountSummary) {
    state.accountSummary = accountSummary
  },

  SET_ACCOUNT_PAYMENTS(state, payments) {
    state.accountPayments = payments
  },
}

export { EIN_REGEX }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
