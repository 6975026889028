<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Audience</span>
    </template>

    <template v-slot:content>
      <div>
        <!-- <BaseFormToggle
          id="buyer-create-campaign__audience__line-type"
          v-model="lineTypeSelected"
          internal-platform-input
          data-cy="toggle-line-type"
          label="Line Type"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <validation-provider
          v-if="lineTypeSelected"
          name="input-line-types"
          rules="required"
        >
          <b-form-group
            label-for="input-line-types"
            label-class="custom-form-input-label--internal"
            label-cols="3"
            content-cols="5"
            label-align="right"
          >
            <v-multiselect-listbox
              id="input-line-types"
              v-model="lineTypes"
              data-cy="multiselect-line-types"
              class="msl-multi-select--full-width"
              :options="lineTypesOptions"
              selected-no-options-text="No Line Type selected"
              search-input-class="custom-form-input custom-form-input--sm"
              :show-select-all-buttons="true"
              selected-options-placeholder="Search selected"
            />
          </b-form-group>
        </validation-provider> -->

        <BaseFormToggle
          id="buyer-create-campaign__audience__search-location"
          v-model="searchLocation"
          internal-platform-input
          data-cy="toggle-search-location"
          label="Location"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseFormSelect
          v-if="searchLocation"
          id="buyer-create-campaign__audience__location-no-icp"
          v-model="noIcp"
          internal-platform-input
          :horizontal="true"         
          :small="true"
          data-cy="toggle-search-location"
          label="Use Location From"
          :options="icpOptions"
          has-tooltip
        />

        <BaseButtonGroup
          v-if="searchLocation"
          id="buyer-create-campaign__audience__location-filter-type"
          :horizontal="true"
          name="LocationFilterType"
          label="Location Type"
          :selected-option="locationFilterType"
          data-cy="button-group-call-type"
          :click-handler="updateLocationFilterType"
          internal-platform-input
          :options="['States', 'ZipCodes']"
          :disabled-options="getLocationFilterTypeDisabledOptions"
          has-tooltip
        />

        <validation-provider
          v-if="showLocationStates"
          name="States"
          rules="required"
        >
          <b-form-group
            slot-scope="{ errors }"
            label-for="input-locations"
            label-class="custom-form-input-label--internal"
            label-cols="3"
            content-cols="5"
            label-align="right"
          >
            <v-multiselect-listbox
              id="input-locations"
              v-model="selectedLocations"
              data-cy="multiselect-locations"
              class="msl-multi-select--full-width"
              :options="stateOptions"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.value"
              selected-no-options-text="No location selected"
              search-input-class="custom-form-input custom-form-input--sm"
              no-options-text="No states"
              :show-select-all-buttons="true"
              selected-options-placeholder="Search selected"
            />

            <div class="text-danger mt-1">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </validation-provider>        

        <BaseFileUpload
          v-if="showLocationZipCodes"
          id="buyer-create-campaign__audience__upload-zip-codes"
          v-model="zipCodesFile"
          internal-platform-input
          :horizontal="true"
          data-cy="file-input-zip-codes"
          name="ZIP codes file"
          accept="text/plain, text/csv"
          rules="size:1024|zipcode"
          :placeholder="getZipCodeFileName"         
          label="Upload zip codes list"
          icon="upload"
          has-tooltip
          width="3"          
          @input="parseZipCodes"          
        >
          <template v-slot:helpText>
            <b-form-text
              v-if="!showPreviouslyUploadedZipCodes"
            >
              <div>
                Upload a txt file containing list of zip codes                
              </div>
            </b-form-text>
            <b-form-text
              v-if="showPreviouslyUploadedZipCodes"
            >
              <div>
                File {{ buyerCampaign.audience.zipCodesFileName }} has {{ buyerCampaign.audience.zipCodesCount }} entries
              </div>
            </b-form-text>
          </template>
        </BaseFileUpload>       

        <BaseFormToggle            
          id="buyer-create-campaign__audience__gender"
          v-model="enableGender" 
          internal-platform-input
          data-cy="toggle-gender"
          label="Gender"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseButtonGroup
          v-if="enableGender"          
          id="buyer-create-campaign__audience__gender__input"
          :horizontal="true"
          name="Gender"
          :selected-option="gender"
          data-cy="button-group-call-type"
          :click-handler="updateGender"
          internal-platform-input
          :options="['All', 'Men', 'Women']"
        />

        <BaseFormToggle            
          id="buyer-create-campaign__audience__age"
          v-model="enableAge" 
          internal-platform-input
          data-cy="toggle-age"
          label="Age"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        >
          <template v-slot:helpText>
            <b-form-text>
              <div
                class="custom-form-toggle--help-text"
                style="margin-top: 10px;"
              >
                <font-awesome-icon
                  :icon="['fas', 'exclamation-triangle']"
                  class="mr-1"
                />
                <i>Enabling this filter may lower the volume of qualified calls due to data availability.
                </i>
              </div>
            </b-form-text>
          </template>
        </BaseFormToggle>

        <BaseRangeSelect
          v-if="enableAge"
          id="buyer-create-campaign__audience__age__input"
          v-model="ageRange"
          :horizontal="true"
          internal-platform-input
          :small="true"
          name="Age"
          data-cy="select-age"            
          input-class="fixed-width-100"
          :min-options="minAges"
          :max-options="maxAges"
          text-field="value"
          value-field="value"
          width="4"
          width-lg="3"           
        />

        <BaseFormToggle            
          id="buyer-create-campaign__audience__estimated-household-income"
          v-model="enableIncome" 
          internal-platform-input
          data-cy="toggle-estimated-household-income"
          label="Estimated Household Income"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseRangeSelect
          v-if="enableIncome"
          id="buyer-create-campaign__audience__estimated-household-income__input"
          v-model="estimatedHouseholdIncomeRangeInDollars"
          :horizontal="true"
          internal-platform-input
          :small="true"
          name="Estimated Household Income"
          data-cy="select-estimated-household-income"
          :min-options="minIncomeOptions"
          :max-options="maxIncomeOptions"
          input-class="fixed-width-130"
          text-field="value"
          value-field="value"
          width="4"
          width-lg="3"
        />

        <BaseFormToggle            
          id="buyer-create-campaign__audience__homeowner"
          v-model="enableHomeowner" 
          internal-platform-input
          data-cy="toggle-homeowner"
          label="Homeowner"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseButtonGroup
          v-if="enableHomeowner"          
          id="buyer-create-campaign__audience__homeowner__input"
          :horizontal="true"
          name="Homeowner"
          :selected-option="homeowner"
          data-cy="button-group-call-type"
          :click-handler="updateHomeowner"
          internal-platform-input
          :options="['Owner', 'Renter']"
        />

        <!-- <BaseFormToggle
          id="buyer-create-campaign__audience__telecom-carrier"
          v-model="wirelessCarrierSelected"
          label="Telecom Carrier"
          data-cy="toggle-wireless-carrier"
          internal-platform-input
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <validation-provider
          v-if="wirelessCarrierSelected"
          name="input-wireless-carriers"
          rules="required"
        >
          <b-form-group
            label-for="input-wireless-carriers"
            label-class="custom-form-input-label--internal"
            label-cols="3"
            content-cols="5"
            label-align="right"
          >
            <v-multiselect-listbox
              id="input-wireless-carriers"
              v-model="wirelessCarriers"
              data-cy="multiselect-wireless-carriers"
              class="msl-multi-select--full-width"
              :options="wirelessCarriersOptions"
              selected-no-options-text="No carrier selected"
              search-input-class="custom-form-input custom-form-input--sm"
              :show-select-all-buttons="true"
              selected-options-placeholder="Search selected"
            />
          </b-form-group>
        </validation-provider> -->

        <!-- <BaseFormToggle
          id="buyer-create-campaign__audience__audience-expansion"
          v-model="expandAudience"
          label="Audience Expansion"
          data-cy="toggle-audience-expansion"
          internal-platform-input
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        >
          <template v-slot:helpText>
            <b-form-text>
              <div class="custom-form-toggle--help-text">
                Reach people beyond your detailed targeting selections when it
                is likely to improve performance
              </div>
            </b-form-text>
          </template>
        </BaseFormToggle> -->
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Audience',

  data() {
    return {
      lineTypesOptions: ['Land Line', 'Pre-paid Wireless', 'VoIP', 'Wireless'],
      wirelessCarriersOptions: ['Verizon', 'AT&T', 'T-Mobile'],
      minAges: Array.from({length: 48}, (_, i) => (i + 18).toString()),
      minIncomeOptions: [
        '$0',
        '$20,000',
        '$30,000',
        '$40,000',
        '$50,000',
        '$75,000',
        '$100,000',
        '$125,000',
      ],
      maxIncomeOptions: [
        '$20,000',
        '$30,000',
        '$40,000',
        '$50,000',
        '$75,000',
        '$100,000',
        '$125,000+',
      ],      
      zipCodesFile: null
    }
  },  

  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    ...mapState('country', ['usStates']),
    
    stateOptions() {
      let stateOptions = []

      stateOptions = this.usStates.map(state => {
        return {
          value: state.abbreviation,
          label: state.name
        }
      })
      
      return stateOptions      
    },

    maxAges() {
      const arr = Array.from({length: 49}, (_, i) => (i + 17).toString())
      arr.push('65+')
      return arr
    },
    gender() {
      return this.buyerCampaign.audience.gender
    },
    lineTypeSelected: {
      get() {
        return this.buyerCampaign.audience.lineTypeSelected
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.lineTypeSelected = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },
    searchLocation: {
      get() {
        return this.buyerCampaign.audience.searchLocation
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.searchLocation = value

        if (audience.selectedLocations == null) {
          audience.selectedLocations = []
        }

        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },    
    wirelessCarrierSelected: {
      get() {
        return this.buyerCampaign.audience.wirelessCarrierSelected
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.wirelessCarrierSelected = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },

    ageRange: {
      get() {
        return this.buyerCampaign.audience.ageRange
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }

        audience.ageRange = {
          min: value.min,
          max: value.max,
        }

        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },

    estimatedHouseholdIncomeRangeInDollars: {
      get() {
        return this.buyerCampaign.audience.estimatedHouseholdIncomeRangeInDollars
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }

        audience.estimatedHouseholdIncomeRangeInDollars = {
          min: value.min,
          max: value.max,
        }

        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },

    expandAudience: {
      get() {
        return this.buyerCampaign.audience.expandAudience
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.expandAudience = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },
    lineTypes: {
      get() {
        return this.buyerCampaign.audience.lineTypes
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.lineTypes = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },
    selectedLocations: {
      get() {
        return this.buyerCampaign.audience.selectedLocations
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.selectedLocations = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },
    wirelessCarriers: {
      get() {
        return this.buyerCampaign.audience.wirelessCarriers
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.wirelessCarriers = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },
    homeowner() {
      return this.buyerCampaign.audience.homeowner
    },

    enableGender: {
      get() {
        return this.buyerCampaign.audience.gender != null
      },

      set(value) {
        if (value && this.buyerCampaign.audience.gender == null) {
          const audience = { ...this.buyerCampaign.audience }

          audience.gender = 'All'

          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        } else if (!value) {
          const audience = { ...this.buyerCampaign.audience }
          audience.gender = null
          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        }
      }
    },

    enableAge: {
      get() {
        return this.buyerCampaign.audience.ageRange != null
      },

      set(value) {
        if (value && this.buyerCampaign.audience.ageRange == null) {
          const audience = { ...this.buyerCampaign.audience }

          audience.ageRange = {
            min: this.minAges[0], 
            max: this.maxAges[this.maxAges.length - 1], 
            greaterThanEqualToMax: true
          }

          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        } else if (!value) {
          const audience = { ...this.buyerCampaign.audience }
          audience.ageRange = null
          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        }
      }
    },

    enableIncome: {
      get() {
        return this.buyerCampaign.audience.estimatedHouseholdIncomeRangeInDollars != null
      },

      set(value) {
        if (value && this.buyerCampaign.audience.estimatedHouseholdIncomeRangeInDollars == null) {
          const audience = { ...this.buyerCampaign.audience }

          audience.estimatedHouseholdIncomeRangeInDollars = {
            min: this.minIncomeOptions[0], 
            max: this.maxIncomeOptions[this.maxIncomeOptions.length - 1], 
            greaterThanEqualToMax: true
          }

          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        } else if (!value) {
          const audience = { ...this.buyerCampaign.audience }
          audience.estimatedHouseholdIncomeRangeInDollars = null
          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        }
      }
    },

    enableHomeowner: {
      get() {
        return this.buyerCampaign.audience.homeowner != null
      },

      set(value) {
        if (value && this.buyerCampaign.audience.homeowner == null) {
          const audience = { ...this.buyerCampaign.audience }
          audience.homeowner = 'Owner'
          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        } else if (!value) {
          const audience = { ...this.buyerCampaign.audience }
          audience.homeowner = null
          this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
        }
      }
    },

    noIcp: {
      get() {
        return this.buyerCampaign.audience.noIcp ? this.icpOptions[1] : this.icpOptions[0]
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }

        audience.noIcp = value === this.icpOptions[1]
        
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)

        if (audience.noIcp) {
          this.updateLocationFilterType('States')
        }
      }
    },

    icpOptions: {
      get() {
        return ['Instant Caller Profile (ICP)', 'Inbound Number Area Code']
      }
    },

    locationFilterType() {
      return this.buyerCampaign.audience.locationFilterType
    },

    showLocationStates() {
      return this.searchLocation && this.locationFilterType === 'States'
    },

    showLocationZipCodes() {
      return this.searchLocation && this.locationFilterType === 'ZipCodes'
    },

    showPreviouslyUploadedZipCodes() {
      return !!this.buyerCampaign.audience.zipCodesCount
    },

    getZipCodeFileName(){
      if (this.buyerCampaign.audience.zipCodesFileName) {
        return this.buyerCampaign.audience.zipCodesFileName
      }
      else{
        return "Upload file"
      }
    },

    getLocationFilterTypeDisabledOptions() {
      if (this.buyerCampaign.audience.noIcp) {
        return ['ZipCodes']
      }

      return []      
    },

  },

  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_AUDIENCE']),
    updateGender(option) {
      const audience = { ...this.buyerCampaign.audience }
      audience.gender = option
      this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
    },
    updateHomeowner(option) {
      const audience = { ...this.buyerCampaign.audience }
      audience.homeowner = option
      this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
    },
    updateLocationFilterType(option) {
      const audience = { ...this.buyerCampaign.audience }
      audience.locationFilterType = option
      this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
    },    
    
    // TODO: check why is this being called 3 times
    parseZipCodes() {
      const reader = new FileReader()

      reader.onload = e => {      
        const zipCodes = e.target.result.split(/[\n\s\t,]+/)
        const audience = { ...this.buyerCampaign.audience }
        audience.zipCodes = zipCodes
        audience.zipCodesFileName = this.zipCodesFile.name
        audience.zipCodesCount = zipCodes.length
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }

      reader.readAsText(this.zipCodesFile)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
