<template>
  <validation-provider
    v-slot="validationContext"
    :name="$attrs.name"
    :rules="validationRules"
    :vid="$attrs.vid"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :description="$attrs.description"
      :invalid-feedback="validationContext.errors[0]"
      :label-class="internalPlatformInput ? 'custom-form-input-label--internal' : 'custom-form-input-label'"
      :label-cols-sm="horizontal ? 3 : 0"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <b-form-timepicker
        v-model="state"
        locale="en"
        style="margin-top: 5px"
        hide-header
        no-close-button
      />
      <slot name="helpText" />
    </b-form-group>
  </validation-provider>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseTimePicker',

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '09:00:00'
    },

    lazy: {
      type: [Boolean, Number],
      default: 400
    },
  },

  data() {
    return {
      syncTimeout: null
    }
  },

  computed: {
    formattedTime() {
      return this.formatTime(this.value) 
    },

    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.lazy === true) {
          return
        }

        clearTimeout(this.syncTimeout)

        this.syncTimeout = setTimeout(
          () => {
            this.$emit('input', value)
          },
          this.lazy !== false ? this.lazy : 0
        )
      }
    },
  },

  methods: {
    formatTime(time) {
      let isAM = true
      let splitTime = time.split(':')
      splitTime.splice(2, 1)

      if (splitTime[0] >= 12) {
        isAM = false

        splitTime[0] = splitTime[0] > 12 ? splitTime[0] - 12 : 12
      }

      return `${splitTime.join(':')} ${isAM ? 'AM' : 'PM'}`
    }
  },
}
</script>

<style>

</style>