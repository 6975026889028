<template>
  <div>
    <b-button
      v-if="showAddButton"
      type="button"
      size="sm"
      @click="addInput"
    >
      {{ addText }}
    </b-button>    
    
    <div
      v-if="!showAddButton"
    >
      <div      
        v-for="(model, $idx) in list"
        :key="$idx"
        class="form-row mb-3"
      >
        <div class="col-5 col-sm-4">
          <validation-provider
            v-slot="validationContext"
            rules="required"
            :name="inputNameText"
          >
            <b-form-input
              :value="getKeyValue($idx, model.key)"
              type="text"
              :name="inputNameText"
              :state="getValidationState(validationContext)"
              v-bind="$attrs"
              class="custom-form-input--sm"
              :debounce="500"
              :readonly="isStaticList"
              @update="onKeyChange($idx, model.value, ...arguments)"
            />

            <div class="invalid-feedback">
              {{ validationContext.errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-5 col-sm-4">
          <validation-provider
            v-slot="validationContext"
            :name="inputValueText"
            rules="required"
          >
            <b-form-input
              :value="model.value"
              type="text"
              :name="inputValueText"
              :state="getValidationState(validationContext)"
              v-bind="$attrs"
              class="custom-form-input--sm"
              :debounce="500"
              @update="onValueChange($idx, model.key, ...arguments)"
            />

            <div class="invalid-feedback">
              {{ validationContext.errors[0] }}
            </div>
          </validation-provider>
        </div>
      

        <div class="align-self-start mt-1">
          <b-button
            v-if="!isStaticList"
            type="button"
            class="ml-2 bg-transparent border-0"
            @click="removeInput($idx)"
          >
            <font-awesome-icon
              :icon="['fas', 'minus-circle']"
            />
          </b-button>
          <b-button
            v-if="!isStaticList && $idx === (list.length - 1)"
            type="button"
            class="ml-2 bg-transparent border-0"
            @click="addInput"
          >
            <font-awesome-icon
              :icon="['fas', 'plus-circle']"
            />
          </b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DualInputList',

  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },

    addText: {
      type: String,
      default: 'Add'
    },

    inputNameText: {
      type: String,
      default: 'Header name'
    },

    inputValueText: {
      type: String,
      default: 'Header value'
    },

    isStaticList: {
      type: Boolean,
      default: false
    },

    hideList: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      addButtonClicked: false
    }
  },

  computed: {
    list: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    },
    
    showAddButton() {      
      return !this.list.length || (this.isStaticList && this.hideList && !this.addButtonClicked)
    }
  },

  methods: {
    onKeyChange(idx, value, key) {
      let copyList = [...this.list]

      copyList[idx] = {
        key,
        value
      }

      this.$emit('input', copyList)
    },

    onValueChange(idx, key, value) {
      let copyList = [...this.list]

      copyList[idx] = {
        key,
        value
      }

      this.$emit('input', copyList)
    },

    addInput() {
      this.addButtonClicked = true
      this.$emit('added')
      if (!this.isStaticList) {
        this.list = [...this.list, { key: '', value: '' }]
      }
    },

    removeInput(idx) {
      const tempList = [...this.list]

      tempList.splice(idx, 1)

      this.list = tempList
    },

    getValidationState({ validated, valid = null }) {
      return validated ? valid : null
    },

    getKeyValue(idx) {
      if (!this.isStaticList){
        return this.value[idx].key
      }

      return this.value[idx].description
    }
  },
}
</script>

<style>

</style>