<template>
  <div>
    <Panel>
      <template v-slot:header>
        <span>Manage Users</span>
      </template>

      <template v-slot:content>
        <b-modal
          id="add-user-modal"
          ref="add-user-modal"
          size="lg"
          :hide-footer="true"
          header-class="custom-modal"
          body-class="custom-modal"
          footer-class="custom-modal"
          title="Add User"
          button-size="sm"
        >
          <validation-observer
            ref="formValidation"
            v-slot="{ handleSubmit }"
          >
            <b-form
              novalidate
              @submit.stop.prevent="handleSubmit(submitForm)"
            >
              <BaseFormInput
                v-model.trim="firstName"
                :horizontal="true"
                :small="true"
                name="First Name"
                label="First Name"
                placeholder="Enter first name"
                type="text"
                required
                width="7"
              />
              <BaseFormInput
                v-model.trim="lastName"
                :horizontal="true"
                :small="true"
                name="Last Name"
                label="Last Name"
                placeholder="Enter last name"
                type="text"
                required
                width="7"
              />
              
              <BaseFormInput
                v-model.trim="email"
                :horizontal="true"
                :small="true"
                name="Email"
                label="Email"
                placeholder="Enter user email address"
                type="email"
                rules="email"
                vid="email"
                required
                width="7"
                lazy
                @blur="onBlurEmail"
              />

              <BaseFormInput
                v-model.trim="phoneNumber"
                :horizontal="true"
                :small="true"
                name="Phone Number"
                label="Phone Number"
                placeholder="Enter Phone Number"
                rules="phone"      
                type="tel"
                required
                width="7"
                :debounce="500"
              />

              <BaseFormInput
                v-model.trim="skype"
                :horizontal="true"
                :small="true"
                placeholder="Optional: Skype"
                name="Skype"
                label="Skype"
                type="text"
                width="7"
              />

              <BaseButtonLoader
                horizontal
                :loading="isLoading"
                class="mt-4"
                button-class="custom-button--white-hover-grad pulse"
                type="submit"
                content-cols="9"
              >
                Submit
              </BaseButtonLoader>
            </b-form>
          </validation-observer>
        </b-modal>
        <div class="text-left">
          <BaseButtonLoader
            :loading="isLoading"
            class="ml-2"
            type="button"
            :custom-button="true"
            size="sm"
            variant="primary"
            @click="$bvModal.show('add-user-modal')"
          >
            Add User
          </BaseButtonLoader>
        </div>
      </template>
    </Panel>
    <Panel :hide-header="true">
      <template v-slot:content>
        <AccountUsers :items="accountUsers" />
      </template>
    </Panel>
  </div> 
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dateFormatMixin from '@/mixins/dateFormatMixin'
import formInputMixin from '@/mixins/formInputMixin'
import AccountUsers from '@/components/account/AccountUsers.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'UserManagement',

  title: 'Manage Users',

  components: {
    AccountUsers,
  },
  
  mixins: [dateFormatMixin, formInputMixin],

  data() {
    return {
      isLoading: false,
      email: null, 
      firstName: null,
      lastName: null,
      phoneNumber: null,
      skype: null,
    }
  },

  computed: {
    ...mapState('account', [
      'accountUsers'
    ]),
  },

  async created () {
    await this.getAccountUsers()
  },

  methods: {

    ...mapActions('account', [
      'getAccountUsers',
      'createAccountUser'
    ]),

    onBlurEmail() {
      this.email = this.email?.toLowerCase() || ''
    },

    async submitForm() {
      try {
        this.isLoading = true

        const payload = { 
          email: this.email, 
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          skype: this.skype,
        }
        
        await this.createAccountUser(payload)

        showSuccessMessage("Successfuly added a user to the account")

        this.$bvModal.hide('add-user-modal')
        
        this.resetFormFields()
        
        await this.getAccountUsers()
      } catch (error) {
        showErrorMessage(`${error.response?.data?.title} ${JSON.stringify(error.response?.data?.errors) ?? ''}`)
      } finally {
        this.isLoading = false
      }
    },

    resetFormFields() {
      this.email = ''
      this.firstName = ''
      this.lastName = ''
      this.phoneNumber = ''
      this.skype = ''
    }
  },
}
</script>

<style>
</style>