<template>
  <div>
    <BaseFormInput
      id="buyer-create-campaign__destination__rtb-id"
      v-model.trim="rtbId"
      internal-platform-input
      :horizontal="true"
      :small="true"
      name="RTB Id"
      placeholder="RTB Id"
      label="RTB Id"
      type="text"
      required
      width="5"
      width-lg="3"
      :debounce="500"
      :rules="{ regex: RTB_ID_REGEX }"
      :custom-messages="{regex: 'Enter valid RTB Id'}"
      has-tooltip
    />

    <BaseFormDualInputList
      id="buyer-create-campaign__destination__tag-mappings"
      v-model="tagMappings"
      internal-platform-input
      horizontal
      width="8"
      width-lg="6"
      label="Request Body Data Mapping"
      add-text="Add data mappings"
      input-name-text="Data mapping name"
      input-value-text="Data mapping value"
      :is-static-list="true"
      :hide-list="hideDefaultMappings"
      has-tooltip
      @added="addButtonClicked"
    />
  </div>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'
import {  getRtbTagsWithDescription } from '@/store/modules/campaign.store'
import { RTB_ID_REGEX } from '@/store/modules/campaign.store'

export default {
  name: 'RTBDestinationForm',

  mixins: [formInputMixin],

  props: {
    targetDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      targetDetailsCopy: {},
      RTB_ID_REGEX: Object.freeze(RTB_ID_REGEX)
    }
  },

  computed: {
    rtbId: {
      get() {
        return this.targetDetailsCopy.ringbaRTB.rtbId
      },
      set(value) {
        this.targetDetailsCopy.ringbaRTB.rtbId = value
        this.$emit('update', this.targetDetailsCopy)
      }
    },

    tagMappings: {
      get() {
        const defaultRtbTags = getRtbTagsWithDescription()
        return this.targetDetailsCopy.ringbaRTB.tagMappings.map(tagMapping => {
          return {
            description: defaultRtbTags.tagDescriptionMapping.get(tagMapping.key),
            key: tagMapping.key,
            value: tagMapping.value
          }
        })       
      },

      set(value) {
        this.targetDetailsCopy.ringbaRTB.tagMappings = value
        this.$emit('update', {...this.targetDetailsCopy})
      }
    },

    hideDefaultMappings() {      
      return this.targetDetailsCopy.ringbaRTB.tagMappings == null 
         || this.targetDetailsCopy.ringbaRTB.tagMappings.length === 0         
    }
  },

  watch: {
    targetDetails: {
      immediate: true,
      handler(newValue) {
        let rtbTagMappings = newValue.ringbaRTB?.tagMappings
        if (rtbTagMappings == null || rtbTagMappings.length === 0) {        
          rtbTagMappings = []
        }      

        this.targetDetailsCopy = {
          ...newValue,
          ringbaRTB: {
            ...newValue.ringbaRTB,
            tagMappings: [...rtbTagMappings]
          }
        }        
      }
    }
  },

  methods: {
    addButtonClicked(){       
      const defaultRtbTags = getRtbTagsWithDescription()
      this.targetDetailsCopy.ringbaRTB.tagMappings = defaultRtbTags.rtbTags       
    }
  },
}

</script>

<style>

</style>