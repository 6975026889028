<template>
  <validation-provider
    :name="$attrs.name"
    :rules="validationRules"
  >
    <b-form-group
      :label="$attrs.label"
      :label-for="$attrs.id"
      :label-class="{
        'custom-form-input-label--internal': internalPlatformInput,
        'custom-form-input-label': !internalPlatformInput,
        'pr-4': true
      }"
      :label-cols-sm="horizontal ? '3' : null"
      label-cols="12"
      :content-cols="widthXs || 12"
      :content-cols-sm="inputWidth"
      :content-cols-lg="widthLg || null"
      :label-align-sm="horizontal ? 'right' : ''"
      label-align="left"
    >
      <slot name="preInput" />
      <template
        v-if="hasTooltip"
        slot="label"
      >
        <div>
          {{ $attrs.label }}
          <span
            v-b-tooltip.hover.right
            :title="fetchTooltip($attrs.id)"
          >
            <font-awesome-icon
              class="ml-1 mt-1"
              :icon="['fas', 'question-circle']"
            />

          </span>
        </div>
      </template>

      <DualInputList
        v-model="state"
        :add-text="addText"
        :is-static-list="isStaticList"
        :hide-list="hideList"
        :input-name-text="inputNameText"
        :input-value-text="inputValueText"
        @added="added"
      />
    </b-form-group>
  </validation-provider>
</template>

<script>
import DualInputList from '@/components/common/DualInputList.vue'
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormDualInputList',

  components: {
    DualInputList,
  },

  mixins: [formInputMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },

    lazy: {
      type: [Boolean, Number],
      default: 400
    },

    rules: {
      type: [String, Object],
      default: ''
    },

    addText: {
      type: String,
      default: 'Add'
    },

    inputNameText: {
      type: String,
      default: 'Header name'
    },

    inputValueText: {
      type: String,
      default: 'Header value'
    },

    hasTooltip: {
      type: Boolean,
      default: false
    },

    isStaticList: {
      type: Boolean,
      default: false
    },

    hideList: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      syncTimeout: null
    }
  },

  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.lazy === true) {
          return
        }

        clearTimeout(this.syncTimeout)

        this.syncTimeout = setTimeout(
          () => {
            this.$emit('input', value)
          },
          this.lazy !== false ? this.lazy : 0
        )
      }
    }   
  },

  methods: {
    added(){  
      this.$emit('added')
    }
  }
}
</script>

<style>

</style>