<template>
  <div>
    <b-form-row>
      <b-col md="12">
        <b-form-group
          label-cols-sm="3"
          label-cols="12"
          label-align-sm="right"
          label-align="left"
          :label="label"
          content-cols="12"
          content-cols-sm="9"
        >
          <div class="d-flex">
            <validation-provider
              v-slot="validationContext"
              :name="$attrs.name"
              :rules="validationRules"
            >
              <b-form-checkbox
                v-model="toggleState"
                switch
                button-variant="success"
                size="lg"
                :value="checkedValue"
                v-bind="$attrs"
                :unchecked-value="uncheckedValue"
                :state="getValidationState(validationContext)"
                :class="{ 'checked': Boolean(toggleState) }"
                v-on="$listeners"
              >
                <slot name="helpText" />
              </b-form-checkbox>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              :name="inputName"
              :rules="validationRulesInput"
            >
              <b-form-input
                v-if="toggleState"
                v-model="inputState"
                :type="inputType"
                class="custom-form-input--sm col-5"
                :class="`col-${inputWidth}`"
                :state="getValidationStateInput(validationContext)"
                :placeholder="inputPlaceholder"
              />

              <b-form-invalid-feedback id="input-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <slot
                v-if="toggleState"
                name="inputHelpText"
              />
              <slot name="extra" />
            </validation-provider>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'

export default {
  name: 'BaseFormToggleWithInput',
  mixins: [formInputMixin],
  inheritAttrs: false,

  props: {

    label: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number, Boolean, Object],
      required: true
    },

    inputValue: {
      type: [String, Number, Boolean, Object],
      default: null
    },

    inputType: {
      type: String,
      default: 'text'
    },

    inputPlaceholder: {
      type: String,
      default: 'text'
    },

    inputName: {
      type: String,
      default: 'text'
    },

    inputRequired: {
      type: Boolean,
      default: false
    },

    checkedValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },

    uncheckedValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },

    lazy: {
      type: [Boolean, Number],
      default: 400,
    },

    rules: {
      type: [String, Array],
      default: ''
    },

    requireChecked: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    toggleState: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('toggleChanged', value)
      },
    },

    inputState: {
      get() {
        return this.inputValue
      },
      set(value) {
        this.$emit('inputChanged', value)
      },
    },


    isCheckboxTickedRequirement() {
      return this.requireChecked ? !!this.toggleState : true
    },

    validationRulesInput() {
      if (typeof this.inputFieldRules === 'object') {
        return Object.assign(
          {
            required: this.inputRequired ? true : false,
          },
          this.rules
        )
      } else if (typeof this.inputFieldRules === 'string') {
        return `${this.inputFieldRules}|${this.inputRequired ? 'required' : ''}`
      } else {
        return this.inputRequired ? 'required' : ''
      }
    },

    isRequired() {
      return this.$attrs.required != null
    },


  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid && this.isCheckboxTickedRequirement) : null
    },

    getValidationStateInput({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

  },

}
</script>

<style lang="scss" scoped>

</style>