const countries = require('./countries')
const usStates = require('./usStates')
const canadaProvinces = require('./canadaProvinces')

const state = {
  countries,
  usStates,
  canadaProvinces,
}

export default {
  namespaced: true,
  state,
}
