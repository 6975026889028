<template>
  <b-modal
    id="apply-to-run-modal"
    ref="apply-to-run-modal"
    size="lg"
    header-class="custom-modal"
    body-class="custom-modal"
    footer-class="custom-modal"
    :hide-footer="true"
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
  >
    <template #modal-title>
      <b-container>
        <b-row align-h="end">
          <b-col>
            Apply to Run: {{ offer.name }} 
          </b-col>
          <b-col cols="2">
            <b-badge 
              :variant="getBadgeVariant(offer.status)"
              pill 
              right-align
            >
              {{ offer.status }}
            </b-badge>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <div class="px-4">
      <validation-observer
        ref="formValidation"
        v-slot="{ handleSubmit, invalid }"
      >
        <b-form
          novalidate
          @submit.stop.prevent="handleSubmit(submitForm)"
        >
          <BaseFormInput
            id="seller-offer__description"
            v-model="offer.description"
            :horizontal="true"
            :small="true"
            internal-platform-input
            label="Description"
            type="text"
            width-lg="8"
            width-xl="8"
            has-tooltip
            plaintext
            input-class="px-0"
          />

          <BaseFormSelect
            id="seller-offer__language"
            v-model="languageId"
            :horizontal="true"
            internal-platform-input
            :small="true"
            name="Language"
            label="Language"
            text-field="name"
            value-field="id"
            :options="languageList"
            required
            :disabled="true"
            width="5"
            width-lg="4"
            width-xl="3"
            has-tooltip
          />

          <BaseFormSelect
            id="seller-offer__category"
            v-model="categoryId"
            :horizontal="true"
            internal-platform-input
            :small="true"
            name="Category"
            label="Category"
            text-field="name"
            value-field="id"
            :options="categoryList"
            required
            :disabled="true"
            width="5"
            width-lg="4"
            width-xl="3"
            has-tooltip
          />

          <BaseFormSelect
            id="seller-offer__traffic-source-type"
            v-model="allowedTrafficSourceType"
            :horizontal="true"
            internal-platform-input
            :small="true"
            name="Traffic Source Type"
            label="Traffic Source Type"
            :options="allowedTrafficSourceTypeList"
            required
            :disabled="true"
            width="5"
            width-lg="4"
            width-xl="3"
            has-tooltip
          />

          <validation-provider
            name="input-traffic-sources"
            rules="required"
          >
            <b-form-group
              label="Traffic Sources"
              label-for="input-traffic-sources"
              label-class="custom-form-input-label--internal"
              :invalid-feedback="invalidFeedback"
              :state="state"
            >
              <template
                slot="label"
              >
                <div>
                  Traffic Sources
                  <span
                    v-b-tooltip.hover.right
                    :title="'The types of promotional methods allowed for this offer'"
                  >
                    <font-awesome-icon
                      class="ml-1 mt-1"
                      :icon="['fas', 'question-circle']"
                    />
                  </span>
                </div>
              </template>
              <v-multiselect-listbox
                id="input-traffic-sources"
                v-model="selectedTrafficSources"
                class="msl-multi-select--full-width"
                :options="allowedTrafficSources"
                :reduce-display-property="option => option.name"
                :reduce-value-property="option => option.id"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search traffic source"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No source selected"
                :show-select-all-buttons="true"
              />
            </b-form-group>
          </validation-provider>

          <BaseButtonGroup
            internal-platform-input
            :selected-option="thirdPartyTraffic"
            :click-handler="selectAnswerForThirdPartyTraffic"
            label="Will any of your traffic come from third party affiliates?"
            :options="['Yes', 'No', 'Some']"
          />

          <BaseFormTextArea
            id="landingPageURLs"
            v-model="landingPageURLs"
            internal-platform-input
            :small="true"
            rows="4"
            data-cy="input-landing-page-urls"
            name="input-landing-page-urls"
            label="Please provide URLs for landing pages if applicable:"
            placeholder=""
          />

          <b-form-group
            :invalid-feedback="promotionalMethodsDetailsInvalidFeedback"
            :state="promotionalMethodsDetailsState"
          >
            <BaseFormTextArea
              id="promotionalMethodsDetails"
              v-model="promotionalMethodsDetails"
              internal-platform-input
              :small="true"
              rows="4"
              label="Please provide specific details regarding your promotional methods:"
              placeholder=""
            />
          </b-form-group>

          <BaseFormCheckbox
            id="acceptedTermsAndConditions"
            v-model="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            required
            require-checked
          >
            I understand that misrepresentation of traffic sources or the
            failure to segment third party traffic will result in termination
            from Ringba X and may result in non-payment in accordance with the
            Ringba X Terms of Service
          </BaseFormCheckbox>

          <BaseButtonLoader
            class="mt-4"
            data-cy="button-create-campaign"
            internal-platform-input
            :disabled="invalid || !acceptedTermsAndConditions"
            variant="primary"
            :loading="loading"
            type="submit"
          >
            Apply
          </BaseButtonLoader>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SellerApplyToRunModal',
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedTrafficSources: [],
      thirdPartyTraffic: 'No',
      landingPageURLs: null,
      promotionalMethodsDetails: null,
      acceptedTermsAndConditions: false,
      loading: false
    }
  },
  computed:{
    state() {
      return this.selectedTrafficSources.length > 0
    },

    invalidFeedback() {
      if (this.selectedTrafficSources.length === 0) {
        return 'Please select at least one traffic source.'
      }
      return 'Please select traffic sources.'
    },

    promotionalMethodsDetailsState() {
      return this.promotionalMethodsDetails != null
    },

    promotionalMethodsDetailsInvalidFeedback() {
      return 'Please enter promotional methods details.'
    },
    
    categoryList() {
      const categoryList = [this.offer.category]
      return categoryList
    },

    languageList() {
      return [this.offer.language]
    },

    languageId: {
      get() {
        return this.offer.language.id
      }
    },
    
    categoryId: {
      get() {
        return this.offer.category.id
      }
    },

    allowedTrafficSourceTypeList() {
      const categoryList = [this.offer.allowedTrafficSourceType]
      return categoryList
    },

    allowedTrafficSourceType: {
      get() {
        return this.offer.allowedTrafficSourceType
      }
    },

    allowedTrafficSources: {
      get() {
        return this.offer.allowedTrafficSources
      }
    },
  },
  
  methods: {
    ...mapActions('sellerOffer', ['submitParticipationRequest']),

    selectAnswerForThirdPartyTraffic(option) {
      this.thirdPartyTraffic = option
    },
    
    getBadgeVariant(status) {
      switch (status) {
      case 'Disabled':
        return 'warning'
      default:
        return 'success'
      }
    },

    async submitForm() {
      if (!this.acceptedTermsAndConditions) {
        return
      }

      try {
        this.loading = true
        const payload = { 
          sellerOfferId: this.offer.id, 
          selectedTrafficSources: this.selectedTrafficSources,
          thirdPartyTraffic: this.thirdPartyTraffic,
          landingPageURLs: this.landingPageURLs,
          promotionalMethodsDetails: this.promotionalMethodsDetails
        }
        await this.submitParticipationRequest(payload)
        this.disabled = true
        showSuccessMessage("Successfuly submitted your application request")
        this.$emit('success')
        setTimeout(() => {
          this.$bvModal.hide('apply-to-run-modal')
        })
      } catch (error) {
        showErrorMessage(`${error.response?.data?.title} ${JSON.stringify(error.response?.data?.errors) ?? ''}`)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">

</style>
